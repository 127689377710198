import React from "react"
import Decimal from "decimal.js"

import NumberFormat from './numberFormatInput.jsx'
import {MC} from './MC.js'
import RangeSlider from './RangeSlider.js'

class Slider extends React.Component {

  valueInput = React.createRef()

  componentDidUpdate() {
    if (this.props.focused) {
      const formatter = MC.getFieldParamValue(this.props.data.param, '@formatType')
      const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.data.param, '@disableFormatOnEdit')
      if (!MC.isNull(formatter) && disableFormatOnEdit) { // texfield was probably replaced in DOM and must be refocused
        const input = this.valueInput.current
        if (input) {
          input.focus()
        }  
      }
    }  
  }

  handleChange = (value) => {
    this.putValue(value, false)
    this.revalidate()
  }

  handleEnd = () => {
    MC.handleEvent(this.props.data, 'changecommitted')
  }

  updateTextChange = (e) => {
    let min = new Number(MC.getFieldParamValue(this.props.data.param, '@min')).valueOf()
    let max = new Number(MC.getFieldParamValue(this.props.data.param, '@max')).valueOf()
    if (this.props.readOnly || min == max) {
      return
    }
    let value = e.target.value
    if (!MC.isNull(value)) {
      value = value.replace(/(?!-)[^0-9., ]/g, "")
      value = value.replace(/\s+/g, '')
      value = value.replace(",", ".")
      if (MC.getFieldParamBooleanValue(this.props.data.param, '@adjustOutOfRange')) {
        let min = MC.getFieldParamValue(this.props.data.param, '@min')
        if (MC.isNumeric(min) && (new Decimal(value)).lessThan(new Decimal(min))) {
          value = min
        }
        let max = MC.getFieldParamValue(this.props.data.param, '@max')
        if (MC.isNumeric(max) && (new Decimal(value)).greaterThan(new Decimal(max))) {
          value = max
        }
      }
      if ("integer" == this.props.data.basictype) {
        value = (new Decimal(value)).round().toFixed()
      }
    } else {
      value = null
    }
    this.putValue(value, true)
  }

  handleTextChange = (e) => {
    let formatter = MC.getFieldParamValue(this.props.data.param, '@formatType')
    const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.data.param, '@disableFormatOnEdit') // for numeric keyboard on mobile phone
    if (formatter == 'number' && (!disableFormatOnEdit || !this.props.focused)) {
      this.putValue(e.value, false)
    } else {
      this.putValue(e.target.value, false)
    }
  }

  doStep(up) {
    let step = MC.getFieldParamValue(this.props.data.param, '@step')
    let value = MC.getFieldParamValue(this.props.data.param, 'value')
    if (!MC.isNumeric(value)) {
      value = 0
    }
    if (!MC.isNumeric(step)) {
      step = 1
    }
    if (up) {
      value = Decimal.add(value, step)
      let max = MC.getFieldParamValue(this.props.data.param, '@max')
      if (MC.isNumeric(max) && value.greaterThan(max)) {
        value = new Decimal(max)
      }
    } else {
      value = Decimal.sub(value, step)
      let min = MC.getFieldParamValue(this.props.data.param, '@min')
      if (MC.isNumeric(min) && value.lessThan(min)) {
        value = new Decimal(min)
      }
    }
    this.putValue(value.toFixed(), true)
    this.revalidate()
  }

  revalidate() {
    if (this.props.data.flow && this.props.data.flow.getCfgParameter('fl:validationStyle') == 'blur') {
      this.props.data.flow.focusedOnFirst = true // prevent focus on first invalid
      this.props.widget.revalidate(true)
    } else {
      this.props.widget.revalidate()
    }
  }

  putValue(newValue, committed) {
    MC.putFieldParamValue(this.props.data.param, 'value', newValue)
    MC.handleEvent(this.props.data, 'change')
    if (committed) {
      MC.handleEvent(this.props.data, 'changecommitted')
    }
    this.forceUpdate()
  }

  render() {
    let min = new Number(MC.getFieldParamValue(this.props.data.param, '@min')).valueOf()
    let max = new Number(MC.getFieldParamValue(this.props.data.param, '@max')).valueOf()
    let step = MC.getFieldParamValue(this.props.data.param, '@step')
    if (MC.isNumeric(step)) {
      step = new Number(step).valueOf()
    } else {
      step = 1
    }
    let readOnly = this.props.readOnly || min == max
    let valueStyle = MC.getFieldParamValue(this.props.data.param, '@valueStyle')
    let valuePosition = MC.getFieldParamValue(this.props.data.param, '@valuePosition')
    if (['left', 'right', 'top', 'bottom'].indexOf(valuePosition) < 0) {
      valuePosition = 'right'
    }
    let asStepper = MC.getFieldParamBooleanValue(this.props.data.param, '@showAsStepper')
    let valueLabel = null
    let growValueCss = ""
    if (asStepper && ['left', 'right'].indexOf(valuePosition) > -1) {
      growValueCss = " flex-grow"
    }
    let value = MC.getFieldParamValue(this.props.data.param, 'value')
    if (['readonly', 'textbox'].indexOf(valueStyle) > -1) {
      let cls
      if (valueStyle === 'readonly') {
        cls = "value " + valuePosition + growValueCss
      } else if (valueStyle === 'textbox') {
        cls = "valueInput " + valuePosition + growValueCss
        if (this.props.focused) {
          cls += " focused"
        }
      }
      const formatter = MC.getFieldParamValue(this.props.data.param, '@formatType')
      const disableFormatOnEdit = MC.getFieldParamBooleanValue(this.props.data.param, '@disableFormatOnEdit') // for numeric keyboard on mobile phone
      if (formatter == 'number' && (!disableFormatOnEdit || !this.props.focused)) {
        const displayType = valueStyle === 'readonly' ? 'text' : 'input'
        let decimalScale = Number(MC.getFieldParamValue(this.props.data.param, '@decimalScale'))
        if (MC.isNull(decimalScale)) {
          decimalScale = 2
        }
        const prefix = MC.getFieldParamValue(this.props.data.param, '@prefix')
        const suffix = MC.getFieldParamValue(this.props.data.param, '@suffix')
        valueLabel = <div className={cls}><NumberFormat key="valueInput" value={value} onBlur={this.updateTextChange} decimalSeparator="," thousandSeparator=" " decimalScale={decimalScale} onValueChange={this.handleTextChange} 
                       prefix={prefix || ''} suffix={suffix || ''} displayType={displayType} inputRef={this.valueInput} readOnly={readOnly}/></div>
      } else {
        if (valueStyle === 'readonly') {
          valueLabel = <div className={cls}>{value}</div>
        } else if (valueStyle === 'textbox') {
          const field = this.props.data
          const type = ['integer', 'decimal'].indexOf(field.basictype) > -1 ? 'number' : 'text'
          const step = 'decimal' == field.basictype > -1 ? '0.01' : null
          valueLabel = <div className={cls}><input type={type} step={step} ref={this.valueInput} value={value} onBlur={this.updateTextChange} onChange={this.handleTextChange} readOnly={readOnly} key="valueInput"/></div>
        }
      }
    }
    let slider
    if (asStepper) {
      let plusCss = "right attached mnc button"
      let minusCss = "mnc left attached button"
      if (value >= max) {
        plusCss += " disabled"
      }
      if (value <= min) {
        minusCss += " disabled"
      }
      slider = (<div className="stepper-buttons">
                  <button className={minusCss} type="button" onClick={this.doStep.bind(this, false)} disabled={readOnly}>-</button>
                  <button className={plusCss} type="button" onClick={this.doStep.bind(this, true)} disabled={readOnly}>+</button>
                </div>)
    } else {
      let leftText = MC.getFieldParamValue(this.props.data.param, '@leftText')
      let rightText = MC.getFieldParamValue(this.props.data.param, '@rightText')
      let texts = {}
      if (!MC.isNull(leftText)) {
        texts[min] = leftText
      }
      if (!MC.isNull(rightText)) {
        texts[max] = rightText
      }
      let sliderCls = null
      if (['left', 'right'].indexOf(valuePosition) > -1) {
        sliderCls = "flex-grow"
      }
      let tooltip = MC.getFieldParamBooleanValue(this.props.data.param, '@valueTooltip')
      value = new Number(value).valueOf() 
      let reversed = MC.getFieldParamValue(this.props.data.param, '@reversed')
      slider = <div className={sliderCls}><RangeSlider onChange={this.handleChange} onEnd={this.handleEnd} reverse={reversed} min={min} max={max} step={step} tooltip={tooltip} value={value} labels={texts} readOnly={readOnly}/></div>
    }
    let conatinerCls = "slider"
    if (['left', 'right'].indexOf(valuePosition) > -1) {
      conatinerCls += " flex-row flex-no-wrap"
    }
    if (['left', 'top'].indexOf(valuePosition) > -1) {
      return (
        <div className={conatinerCls} data-widget-i-name={this.props.data.id}>
          {valueLabel}
          {slider}
        </div>
      )
    } else {
      return (
        <div className={conatinerCls} data-widget-i-name={this.props.data.id}>
          {slider}
          {valueLabel}
        </div>
      )
    }
  }

}

export {Slider}