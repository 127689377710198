import Decimal from "decimal.js"
import {DateTime} from "luxon"

import {MC} from './MC.js'
import {Value} from "./Value.js"
import {Duration} from "./Duration.js"
import {JdateFormat} from "./JdateFormat.js"

Decimal.set({precision: 36})

const FunctionMode = {NonScalar: "non-scalar", Scalar: "scalar", AdaptiveScalarFirstArg: "adaptive-scalar-first-arg", AdaptiveScalarArgsCount: "adaptive-scalar-args-count", AdaptiveArgs: 'adaptive-args'}

let Expression = function() {}

Expression.prototype.init = function(data, cData, opts) {
  this.data = data
  this.cData = cData
  this.opts = opts || {}
  this.trace = {}
  if (!this.opts.scope) {
    this.opts.scope = {vars: {}}
  }
  if (this.opts.returnVariableValue) {
    this.returnVariableValue = true
    delete this.opts.returnVariableValue // do not pass this setting into sub exprs
  }
  // COMPATIBILITY FLAG
  this.starfix = Value.isTrue(this.operatorAppCfgVal([Value.v('mini:pathSegmentStarFix', 'string')]))
}

Expression.prototype.getTrace = function() {
  if (MC.isEmptyObject(this.trace)) {
    return null
  } else {
    return this.trace;
  }
};

Expression.prototype.getTraceAsPaths = function() {
  let result = {};
  this.getSubtraceAsPaths(result, this.trace, this.trace.path);   
  return result;
};

Expression.prototype.getSubtraceAsPaths = function(result, trace, path) {
  if (!path) {
    path = "/"
  }
  if (trace.subpath && trace.subpath.length > 0) {
    for (let subTrace of trace.subpath) {
      this.getSubtraceAsPaths(result, subTrace, trace.path);   
    }
  } else {
    if (result[path]) {
      if (!Array.isArray(result[path])) {
        result[path] = [result[path]]; 
      }
      result[path].push(trace);
    } else {
      result[path] = trace;
    }
  }
};

Expression.prototype.stripDeepCollections = function(value, fromLevel) {
  if (Value.isCollection(value)) {
    if (fromLevel > 0) {
      fromLevel--
      for (var i=0; i<value.value.length; i++) {
        value.value[i] = this.stripDeepCollections(value.value[i], fromLevel)
      }
    } else {
      value = this.stripDeepCollections(Value.getFirstNotNull(value), fromLevel)
    }
  }
  return value
}

Expression.prototype.pushSubTrace = function(trace, asArgument = false) {
  if (this.opts.trace) {
    if (asArgument) {
      if (!Array.isArray(this.trace.args)) {
        this.trace.args = []
      }
      this.trace.args.push(trace)
    } else {
      if (!Array.isArray(this.trace.subpath)) {
        this.trace.subpath = []
      }
      this.trace.subpath.push(trace)
    }
  }
}  

Expression.prototype.evaluateVariables = function(exprs, opts) {
  let res = []
  for (let expr of exprs) {
    if (expr.operator && expr.operator.startsWith('$')) {
      // copy context for variables, all subvariables shoul be in new subcontext
      let opsTopas = Object.assign({}, opts)
      if (opsTopas.scope.vars) {
        opsTopas.scope = {vars: Object.assign({}, opsTopas.scope.vars)}
      }
      opsTopas.returnVariableValue = true
      let expression = new Expression()
      expression.init(expr, this.cData, opsTopas)
      let res = expression.evaluate()
      if (Value.isError(res)) {
        return res
      }
      opts.scope.vars[expr.operator] = res
      this.pushSubTrace(expression.getTrace(), this.data.operator != null)
    } else {
      res.push(expr)
    }
  }
  let sortedExprs = []
  for (let expr of res) {
    if (!expr.target) {
      sortedExprs.push(expr)
    }
  }
  for (let expr of res) {
    if (expr.target) {
      sortedExprs.push(expr)
    }
  }
  return sortedExprs
}

Expression.prototype.evaluate = function(path) {
  if (this.data.operator && this.opts.trace) { // must be before arguments from evaluate variables
    if (this.data.operator.startsWith('$')) {
      this.trace.variable = this.data.operator
    } else {
      this.trace.operator = this.data.operator
    }
  }  
  let expr = this.evaluateVariables(this.data.expr || [], this.opts)
  if (Value.isError(expr)) {
    if (this.data.operator) {
      Value.errorAdjustMessage(expr, `Error while evaluating function '${this.data.operator}'`)
    }
    Value.errorPathSet(expr, path || this.data.target)
    return expr
  }
  if (this.data.target) {
    if (!path) {
      path = this.data.target
    }
    if (this.opts.trace) {
      this.trace.path = path
    }
    if (this.data.expr) {
      let subpaths = {}
      for (let i=0; i<expr.length; i++) {
        // copy context for variables, all subvariables shoul be in new subcontext
        let opsTopas = Object.assign({}, this.opts)
        if (opsTopas.scope.vars) {
          opsTopas.scope = {vars: Object.assign({}, opsTopas.scope.vars)}
        }
        let expression = new Expression()
        expression.init(expr[i], this.cData, opsTopas)
        if (expr[i].target) {
          let nextPath = (path ? path + '/' : '') + expr[i].target
          let value
          try {
            value = expression.evaluate(nextPath)
            if (Value.isError(value)) {
              return value
            }
          } catch (e) {
            return Value.error(e.message, nextPath)
          }
          if (!Value.isNull(value) && MC.isNull(subpaths[nextPath])) {
            if (Value.isDataNode(value)) {
              Object.assign(subpaths, value.value)
            } else {
              subpaths[nextPath] = value
            }
          }
        } else {
          let value
          try {
            value = expression.evaluate()
            if (Value.isError(value)) {
              Value.errorPathSet(value, path)
              return value
            }
          } catch (e) {
            return Value.error(e.message, path)
          }
          if (!Value.isNull(value) && MC.isNull(subpaths[path])) {
            subpaths[path] = value
          }
        }
        this.pushSubTrace(expression.getTrace()) 
      }
      if (!MC.isNull(subpaths)) {
        for (let key in subpaths) {
          let expectedLevel = (key.match(/\*/g) || []).length
          subpaths[key] = this.stripDeepCollections(subpaths[key], expectedLevel)
        }
        return {type: 'anyType', value: subpaths} 
      } else {
        return Value.v(null, 'anyType')
      }
    } else {
      return Value.v(null)
    }
  } else if (this.data.operator) {
    if ('submittedBy' == this.data.operator) {
      if (Array.isArray(this.data.expr) && MC.isPlainObject(this.data.expr[0]) && !MC.isNull(this.data.expr[0].source)) {
        if (!this.data.expr[0].source.endsWith("'")) {
          this.data.expr[0].source = "'" + this.data.expr[0].source + "'"
        }
      }
    }
    let res = Value.v(null);
    if (this.data.operator.startsWith('$')) {
      if (expr && expr.length > 0) {
        for (var i=0; i<expr.length; i++) {
          let expression = new Expression()
          expression.init(expr[i], this.cData, this.opts)
          try {
            res = expression.evaluate()   
            if (Value.isError(res)) {
              return Value.error(`Error while evaluating variable '${this.data.operator}' \n${Value.getErrorMessage(res)}`)
            }        
          } catch (e) {
            return Value.error(`Error while evaluating variable '${this.data.operator}' \n${e.message}`)
          }
          if (!this.returnVariableValue) {
            this.opts.scope.vars[this.data.operator] = res
          }
          if (this.opts.trace) {
            this.trace.resultInVariable = MC.isNull(res) ? null : Value.toLiteral(res)
            this.pushSubTrace(expression.getTrace(), true)
          }  
          if (!Value.isNull(res))  {
            break
          }
        }
      }
      if (!this.returnVariableValue) {
        res = Value.v(null, 'variable')
      }
    } else if (['try', 'select', 'every', 'group', 'some', 'sort', 'filter', 'find', 'treeFind', 'map', 'reduce', 'treeReduce', 'for', 'quote', 'distinct'].indexOf(this.data.operator) > -1) {
      try {
        res = this.evaluateOperator(expr)
      } catch (e) {
        return Value.error(`Error while evaluating function '${this.data.operator}' \n${e.message}`)
      }
    } else {
      let argsToPass = [];
      if (expr && expr.length > 0) {
        let lazy = false
        let variableLoop = true
        for (let i=0; i<expr.length; i++) {
          if (!lazy) {
            try {
              // copy context for variables, all subvariables should be in new subcontext
              let opsTopas = Object.assign({}, this.opts)
              if (opsTopas.scope.vars) {
                opsTopas.scope = {vars: Object.assign({}, opsTopas.scope.vars)}
              }
              let expression = new Expression()
                expression.init(expr[i], this.cData, opsTopas)
              let res = expression.evaluate()
              if (Value.isError(res) && !Value.isErrorInside(res) && (['if', 'switch'].indexOf(this.data.operator) == -1 || variableLoop)) {
                if (Value.isError(res)) {
                  Value.errorAdjustMessage(res, `Error while evaluating argument ${i+1} of function '${this.data.operator}'`)
                }
                return res
              }
              if (res.type !== 'variable') {
                if (variableLoop && ['and', 'or', 'if', 'switch'].indexOf(this.data.operator) > -1) {
                  if (!Value.isCollection(res)) {
                    lazy = true
                  }
                }
                argsToPass.push(res)
                variableLoop = false
              }
              this.pushSubTrace(this.data.operator == '{}' ? expression.getTraceAsPaths() : expression.getTrace(), true)
            } catch (e) {
              return Value.error(`Error while evaluating argument ${i+1} of function '${this.data.operator}' \n${e.message}`)
            }
          } else {
            argsToPass.push(expr[i])
          }
        }
      }
      try {
        res = this.evaluateOperator(argsToPass)
      } catch (e) {
        return Value.error(`Error while evaluating function '${this.data.operator}' \n${e.message}`)
      }
    }
    if (this.opts.trace && !this.returnVariableValue) {
      this.trace.result = Value.toLiteral(res)
    }
    return res;
  } else if (this.data.source) {
    let res = this.evaluateSource()
    if (this.opts.trace) {
      this.trace.result = Value.toLiteral(res)
    }  
    return res
  } else {
    return Value.error('Expression must have function or source defined!')
  }
}

Expression.prototype.evaluateLazy = function(expr, opts, errPath, tracePath, traceI) {
  if (expr.type !== undefined && (expr.value !== undefined || expr.mess !== undefined)) { // argument is evaluated yet
    return expr
  }
  // copy context for variables, all subvariables shoul be in new subcontext
  let opsTopas = Object.assign({}, opts || this.opts)
  if (opsTopas.scope.vars) {
    opsTopas.scope = {vars: Object.assign({}, opsTopas.scope.vars)}
  }
  let expression = new Expression()
  expression.init(expr, this.cData, opsTopas)
  let res
  try {
    res = expression.evaluate()
    if (Value.isError(res)) {
      Value.errorPathSet(res, errPath)
      Value.errorAdjustMessage(res, `Error while evaluating argument of function '${this.data.operator}'`)
    }
  } catch (e) {
    res = Value.error(e.message, errPath)
  }
  if (this.opts.trace) {
    if (tracePath) {
      if (!this.trace[tracePath]) {
        this.trace[tracePath] = expression.getTrace()
      } else {
        if (!Array.isArray(this.trace[tracePath])) {
          this.trace[tracePath] = [this.trace[tracePath]]
        }
        this.trace[tracePath].push(expression.getTrace())
      }
    } else {
      if (!Array.isArray(this.trace.args)) {
        this.trace.args = []
      }
      if (traceI) {
        if (!Array.isArray(this.trace.args[traceI])) {
          this.trace.args[traceI] = []
        }
        this.trace.args[traceI].push(expression.getTrace())
      } else {
        this.trace.args.push(expression.getTrace())
      }
    }
  }
  return res
}  

Expression.prototype.setBase = function(relativeBase, relativeToBase) {
  if (relativeBase == null) {
    this.opts.base.shift();
    this.opts.position.shift();
    this.opts.positionValue.shift();
    this.opts.resultValue.shift();
  } else if (relativeBase.length == 0) {
    this.opts.base.unshift("");
    this.opts.position.unshift([]);
    this.opts.positionValue.unshift([]);
    this.opts.resultValue.unshift(Value.v(null))
  } else if (relativeToBase == -1) {
    this.opts.base.unshift(relativeBase);
    this.opts.position.unshift([]);
    this.opts.positionValue.unshift([]);
    this.opts.resultValue.unshift(Value.v(null));
  } else {
    var fromBase = this.opts.base.slice().reverse()[relativeToBase - 1];
    this.opts.base.unshift(this.relativize(fromBase, relativeBase));
    var fromPosition = this.opts.position.slice().reverse()[relativeToBase - 1];
    var shared = MC.collectionDepth(MC.commonAncestor(fromBase, relativeBase));
    var newPosition = fromPosition.slice(0, shared);
    this.opts.position.unshift(newPosition);
    this.opts.positionValue.unshift([]);
    this.opts.resultValue.unshift(Value.v(null));
  }
};

Expression.prototype.clearBase = function(opts) {
  opts.base = null
  opts.position = null
  opts.positionValue = null
  opts.resultValue = null
}  

Expression.prototype.bases = function() {
  return this.opts.base;
};

Expression.prototype.base = function() {
  return this.opts.base[0];
}

Expression.prototype.setPosition = function(position, value) {
  this.opts.position[0].push(position)
  this.opts.positionValue[0].push(value)
}

Expression.prototype.unsetPosition = function() {
  this.opts.position[0].pop()
  this.opts.positionValue[0].pop()
}  

Expression.prototype.pushResultValue = function(resultValue) {
  this.opts.resultValue.push(resultValue)
}

Expression.prototype.popResultValue = function() {
  this.opts.resultValue.pop()
}

Expression.prototype.peekResultValue = function() {
  return this.opts.resultValue[this.opts.resultValue.length-1]
}

Expression.prototype.resultValues = function() {
  return this.opts.resultValue
}

Expression.prototype.position = function() {
  return this.opts.position[0];
};

Expression.prototype.positions = function() {
  return this.opts.position;
};

Expression.prototype.positionValues = function() {
  return this.opts.positionValue;
};

Expression.prototype.enterBaseContext = function() {
  if (!Array.isArray(this.opts.base)) {
    this.opts.base = [];
  }
  if (!Array.isArray(this.opts.position)) {
    this.opts.position = [];
  }
  if (!Array.isArray(this.opts.positionValue)) {
    this.opts.positionValue = [];
  }
  if (!Array.isArray(this.opts.resultValue)) {
    this.opts.resultValue = []
  }
  var newBase = null;
  var relativeToBase = -1;
  if (Array.isArray(this.data.expr) && MC.isPlainObject(this.data.expr[0])) {
    if (!MC.isNull(this.data.expr[0].source)) {
      newBase = this.data.expr[0].source
      const first = newBase.indexOf('/') > -1 ? newBase.substr(0, newBase.indexOf('/')) : newBase
      if (first == "." || first == ".." || first.match(/^\$v[0-9]+$/)) {
        if (newBase.startsWith("$v")) {
          const i = newBase.indexOf("/")
          relativeToBase = parseInt(i == -1 ? newBase.substring(2) : newBase.substring(2, i))
          newBase = i == -1 ? "." : newBase.substring(i + 1)
        } else {
          relativeToBase = this.bases().length
        }
      }
    } else if (!MC.isNull(this.data.expr[0].operator) && Array.isArray(this.data.expr[0].expr)) {
      var functionName = this.data.expr[0].operator;
      var fa1e = this.data.expr[0].expr[0];
      if (!MC.isNull(fa1e.source)) {
        var v1s = fa1e.source;
        if (v1s.startsWith("'")) {
          v1s = v1s.substring(1, v1s.length - 1);
        }
        if (functionName == 'path') {
          newBase = v1s;
        } else if (functionName == 'relative') {
          newBase = v1s;
          if (newBase.startsWith("$v")) {
            var i = newBase.indexOf("/");
            relativeToBase = parseInt(i == -1 ? newBase.substring(2) : newBase.substring(2, i));
            newBase = i == -1 ? "." : newBase.substring(i + 1);
          } else {
            relativeToBase = this.bases().length;
          }
        }
      }
    }
  }
  if (newBase == null) {
    this.setBase("", -1);
  } else {
    this.setBase(newBase, relativeToBase);
  }
  return newBase;
};

Expression.prototype.leaveBaseContext = function() {
  this.setBase(null, 0);
};

Expression.prototype.evaluateSource = function(data = null, log = true) {
  let source = data == null ? this.data.source : data.source
  if (log && this.opts.trace) {
    this.trace.source = source
  }
  const first = source.indexOf('/') > -1 ? source.substr(0, source.indexOf('/')) : source
  if (first == "." || first == ".." || first.match(/^\$v[0-9]+$/)) {
    return this.operatorRelative([Value.v(source, 'string')])
  } else if (source.startsWith("$")) {
    if (source.indexOf('/') > -1) {
      let tokens = source.split("/")
      if (!this.opts.scope.vars.hasOwnProperty(tokens[0])) {
        return Value.error(`Undefined variable with name ${tokens[0]}!`)
      }
      let res = this.opts.scope.vars[tokens[0]]
      tokens.shift()
      return this.getValue(res, tokens, Value.isCollection(res))
    } else {
      if (!this.opts.scope.vars.hasOwnProperty(source)) {
        return Value.error(`Undefined variable with name ${source}!`)
      }
      return this.opts.scope.vars[source]
    }
  } else {
    let res = Value.parseLiteral(source, true)
    if (res) {
      return res 
    } else {
      let tokens = source.split("/")
      if (this.opts.inForm && this.opts.inForm == tokens[0]) {
        tokens.shift()
        return this.evaluateInForm(tokens)
      }
      let value = this.cData[tokens[0]]
      if (value === undefined) { 
        return Value.v(null)
      }
      if (tokens.length == 1) {
        return value
      } else {
        let isCollection = tokens[0].endsWith('*')
        tokens.shift()
        return this.getValue(value, tokens, isCollection)
      }
    }
  }
}

Expression.prototype.evaluateInForm = function(tokens, definition, repeaterRows, formPathFunction = false) {
  let target = tokens.shift()
  if (!definition) { // form root
    definition = this.opts.flow.formData ? this.opts.flow.formData : this.opts.flow.reactFlow().state.formData
    if (target == 'data') {
      return tokens.length > 0 ? this.getValue(definition.data || Value.v(null), tokens, false) : definition.data
    }
    if (!formPathFunction) {
      repeaterRows = this.opts.triggeredByField ? MC.getFieldParamValue(this.opts.triggeredByField.param, '@iteration') : null
    }
  }
  if (!target) { // whole form tree from root
    if (tokens.length == 0) {
      return this.opts.flow.mapFormOutput(definition, {}, this.opts.triggeredByField, 'store', 'no', null, null)
    }
  }
  let subField = null
  if (definition.fields) {
    subField = definition.fields.find(f => f.id == target || f.id == (target + '*'))
  }
  if (subField) {
    let dynamicFields = false
    if ('dynamicPanel' == subField.widget && tokens.length > 0 && (tokens[0].replace('*', '') == 'fields' || tokens[0] == 'fieldsData')) { // DEPRECATED fields
      tokens.shift() // remove fieldsData
      dynamicFields = true
    }
    if (tokens.length > 0) {
      if (target == 'rows*') {
        let allRows = MC.getFieldParamBooleanValue(definition.parent.param, '@allRowsOutput')
        let res = []
        let repeaterRowsToPass = Array.isArray(repeaterRows) ? repeaterRows.slice(1) : null
        if (Array.isArray(repeaterRows) && (formPathFunction || this.opts.inForm != 'form' && !allRows)) {
          if (definition.fields[0].rows[repeaterRows[0]]) {
            res.push(this.evaluateInForm([...tokens], definition.fields[0].rows[repeaterRows[0]], repeaterRowsToPass, formPathFunction))
          } else {
            res.push(Value.v(null))
          }
        } else {
          if (Array.isArray(definition.fields[0].rows) && definition.fields[0].rows.length > 0) {
            for (let r of definition.fields[0].rows) {
              res.push(this.evaluateInForm([...tokens], r, repeaterRowsToPass, formPathFunction))
            }
          }
        }
        return res.length > 0 ? Value.v(res, 'collection') : Value.v(null) 
      } else {
        return this.evaluateInForm(tokens, subField, repeaterRows, formPathFunction)
      }
    } else {
      let widgetData = Value.dataNode({})
      let defToPass = definition.id == 'rows*' && definition.parent.id == 'rows*' ? {...definition, id: 'dummyparent'} : definition // if one row, it is needed serialized like a normal field (path ends with field under row), so id is changed from rows* to dummy
      let res = this.opts.flow.mapFormOutput(defToPass, widgetData, null, null, 'no', null, null)
      if (definition.formId) {
        widgetData = res
      }
      res = Value.getProperty(widgetData, subField.id.replace('*', '')) // remove * when is .../rows*
      if (dynamicFields) {
        res = Value.getProperty(res, 'fieldsData')
      }
      return res
    }
  } else {
    if (definition.formId) { // is form root
      if (target == '@submitAction') {
        return Value.v(this.opts.logicName)
      }
      if (target == '@submitBehaviour') {
        return Value.v('store')
      }
      if (target == '@submitTrigger' && this.opts.triggeredByField) {
        return Value.v(this.opts.triggeredByField.id)
      }
      if (target == '@submitTriggerIndex' && !MC.isNull(repeaterRows)) {
        return Value.castToCollection(Value.fromJson(repeaterRows))
      }
      if (target == '@submitTriggerPath' && this.opts.triggeredByField) {
        return Value.v(this.opts.flow.getFormFieldPath(this.opts.triggeredByField))
      }
      if (target == '@width') {
        return Value.v(this.opts.flow.reactFlow().containerRef.current.offsetWidth, 'decimal')
      }
    }
    return this.getValue(Value.fromJson(definition.param), [target, ...tokens], false)
  }
}  

Expression.prototype.getValue = function(value, tokens, isCollection) {
  // COMPATIBILITY FLAG
  if (isCollection && (this.starfix || Value.isCollection(value))) {
    value = Value.castToCollection(value)
    let res = []
    for (let i=0; i< Value.collectionSize(value); i++) {
      if (tokens.length > 1) {
        let subTokens = tokens.slice()
        subTokens.shift()
        res[i] = this.getValue(this.getValueByKey(value.value[i], tokens[0]), subTokens, tokens[0].endsWith('*'))
      } else {
        res[i] = this.getValueByKey(value.value[i], tokens[0])
      }
    }
    return res.length > 0 ? Value.v(res, 'collection') : Value.v(null)
  } else if (!Value.isNull(value)) {
    if (Value.isCollection(value)) {
      value = value.value[0]
    }
    let result = this.getValueByKey(value, tokens[0])
    if (Value.isNull(result) && Value.hasProperty(result, '@customwidget') && Value.hasProperty(result, 'value') && Value.isDataNode(Value.getProperty(result, 'value')) && Value.hasProperty(Value.getProperty(result, 'value'), tokens[0])) {
      result = Value.getProperty(Value.getProperty(result, 'value'), tokens[0])
    }
    if (tokens.length > 1) {
      let subTokens = tokens.slice()
      subTokens.shift()
      return this.getValue(result, subTokens, tokens[0].endsWith('*'))
    } else {
      if (!Value.isNull(result) || Value.isCollection(result)) {
        if (tokens[0].endsWith('*') && (this.starfix || Value.isCollection(result))) {
          if (Value.isCollection(result)) {
            return Value.v([...result.value], 'collection')
          } else {
            return Value.v([result], 'collection') 
          }
        } else {
          if (Value.isCollection(result)) {
            result = result.value[0]
          }
          return result
        }
      } else {
        return Value.v(null)
      }
    }
  } else {
    return Value.v(null)
  }
}

Expression.prototype.getValueByKey = function(object, key) {
  if (Value.isEmpty(object)) {
    return Value.v(null)
  }
  if (key.endsWith('*')) {
    key = key.substring(0, key.length - 1)
  }
  if (Value.isCollection(object)) {
    let res = []
    for (let item of object.value) {
      res.push(this.getValueByKey(item, key))
    }
    return res.length > 0 ? Value.v(res, 'collection') : Value.v(null)
  } else {
    return Value.getProperty(object, key)
  }
}

Expression.prototype.evaluateOperator = function(args) {
  switch (this.data.operator) {
    case '{}': return this.operatorDetachedTree(args); break;
    case '>': return this.scalarOperator(this.operatorGreater, args); break;
    case '<': return this.scalarOperator(this.operatorLower, args); break;
    case '>=': return this.scalarOperator(this.operatorGreaterEquals, args); break;
    case '<=': return this.scalarOperator(this.operatorLowerEquals, args); break;
    case '==': return this.scalarOperator(this.operatorEquals, args); break;
    case '!=': return this.scalarOperator(this.operatorNotEquals, args); break;
    case '+': return this.scalarOperator(this.operatorPlus, args); break;
    case '-':
    case '−': return this.scalarOperator(this.operatorMinus, args); break;
    case '*': return this.scalarOperator(this.operatorMultiply, args); break;
    case '/': return this.scalarOperator(this.operatorDivide, args); break;
    case 'abs': return  this.scalarOperator(this.operatorAbs, args); break;
    case 'accumulateData': return this.operatorAccumulateData(args); break;
    case 'addDuration': return this.scalarOperator(this.operatorAddDuration, args); break;
    case 'and': return this.scalarOperator(this.operatorAnd, args); break;
    case 'appCfgVal': return this.scalarOperator(this.operatorAppCfgVal, args); break;
    case 'appCfgVal2': return this.scalarOperator(this.operatorAppCfgVal2, args); break;
    case 'avg': return this.runOperator(this.operatorAvg, FunctionMode.AdaptiveScalarArgsCount, args); break;
    case 'buildUri': return this.scalarOperator(this.operatorBuildUri, args); break;
    case 'cast': return this.scalarOperator(this.operatorCast, args); break;
    case 'castable': return this.operatorCastable(args); break;
    case 'collection': return this.operatorCollection(args); break;
    case 'collectionItem': return this.operatorCollectionItem(args); break;
    case 'collectionSize': return this.operatorCollectionSize(args); break;
    case 'collectionUnwrap': return this.operatorCollectionUnwrap(args); break;
    case 'concat': return this.scalarOperator(this.operatorConcat, args); break;
    case 'contains': return this.operatorContains(args); break;
    case 'count': return this.operatorCount(args); break;
    case 'currentDate': return this.operatorCurrentDate(); break;
    case 'dataNode': return this.operatorDataNode(args); break;
    case 'dataToEntries': return this.scalarOperator(this.operatorDataToEntries, args); break;
    case 'dataToLiteral': return this.operatorDataToLiteral(args); break;
    case 'dataToJson': return this.scalarOperator(this.operatorDataToJson, args); break;
    case 'dataToJson2': return this.operatorDataToJson(args); break;
    case 'dataToXml': return this.scalarOperator(this.operatorDataToXml, args); break;
    case 'decodeBase64': return this.scalarOperator(this.operatorDecodeBase64, args); break;
    case 'decodeHex': return this.scalarOperator(this.operatorDecodeHex, args); break;
    case 'decodeUrl': return this.scalarOperator(this.operatorDecodeUrl, args); break;
    case 'delete': return this.operatorDelete(args); break;
    case 'deleteData': return this.operatorDeleteData(args); break;
    case 'distinct': return this.operatorDistinct(args); break;
    case 'div': return this.scalarOperator(this.operatorDiv, args); break;
    case 'durationBetween': return this.scalarOperator(this.operatorDurationBetween, args); break;
    case 'durationComponent': return this.scalarOperator(this.operatorDurationComponent, args); break;
    case 'emptyToNull': return this.scalarOperator(this.operatorEmptyToNull, args); break;
    case 'encodeBase64': return this.scalarOperator(this.operatorEncodeBase64, args); break;
    case 'encodeHex': return this.scalarOperator(this.operatorEncodeHex, args); break;
    case 'encodeUrl': return this.scalarOperator(this.operatorEncodeUrl, args); break;
    case 'endsWith': return this.scalarOperator(this.operatorEndsWith, args); break;
    case 'entriesToData': return this.operatorEntriesToData(args); break;
    case 'error': return this.operatorError(args); break;
    case 'escapeHtml': return this.scalarOperator(this.operatorEscapeHtml, args); break;
    case 'every': return this.operatorEvery(args); break;
    case 'exists': return this.operatorExists(args); break;
    case 'false': return false; break;
    case 'fill': return this.operatorFill(args); break;
    case 'filter': return this.operatorFilter(args); break;
    case 'fillTimezone': return this.scalarOperator(this.operatorFillTimezone, args); break;
    case 'find': return this.operatorFind(args); break;
    case 'first': return this.operatorFirst(args); break;
    case 'firstNonNull': return this.operatorFirstNonNull(args); break;
    case 'formPath': return this.operatorFormPath(args); break;
    case 'fromMilliseconds': return this.scalarOperator(this.operatorFromMilliseconds, args); break;
    case 'flatten': return this.operatorFlatten(args); break;
    case 'for': return this.operatorFor(args); break;
    case 'formatDate': return this.scalarOperator(this.operatorFormatDate, args); break;
    case 'formatIban': return this.scalarOperator(this.operatorFormatIban, args); break;
    case 'formatNumber': return this.scalarOperator(this.operatorformatNumber, args); break;
    case 'formatText': return this.scalarOperator(this.operatorFormatText, args); break;
    case 'group': return this.operatorGroup(args); break;
    case 'hasData': return this.scalarOperator(this.operatorHasData, args); break;
    case 'hasDataN': return this.operatorHasDataN(args); break;
    case 'ibanToDisplay': return this.scalarOperator(this.operatorIbanToDisplay, args); break;
    case 'if': return this.runOperator(this.operatorIf, FunctionMode.AdaptiveScalarFirstArg, args); break;
    case 'indexOf': return this.scalarOperator(this.operatorIndexOf, args); break;
    case 'isEmpty': return this.operatorIsEmpty(args); break;
    case 'isNull': return this.operatorIsNull(args); break;
    case 'join': return this.operatorJoin(args); break;
    case 'jsonToData': return this.scalarOperator(this.operatorJsonToData, args); break;
    case 'last': return this.operatorLast(args); break;
    case 'lastIndexOf': return this.scalarOperator(this.operatorLastIndexOf, args); break;
    case 'length': return this.scalarOperator(this.operatorLength, args); break;
    case 'logarithm': return this.scalarOperator(this.operatorLogarithm, args); break;
    case 'lookup': return this.operatorLookup(args); break;
    case 'literalToData': return this.scalarOperator(this.operatorLiteralToData, args); break;
    case 'map': return this.operatorMap(args); break;
    case 'matches': return this.scalarOperator(this.operatorMatches, args); break;
    case 'max': return this.runOperator(this.operatorMax, FunctionMode.AdaptiveScalarArgsCount, args); break;
    case 'mergeCollections': return this.operatorMergeCollections(args); break;
    case 'mergeData': return this.operatorMergeData(args); break;
    case 'mergeDataDeep': return this.operatorMergeDataDeep(args); break;
    case 'min': return this.runOperator(this.operatorMin, FunctionMode.AdaptiveScalarArgsCount, args); break;
    case 'mod': return this.scalarOperator(this.operatorMod, args); break;
    case 'namespaceForPrefix': return this.operatorNamespaceForPrefix(args); break;
    case 'normalizeDuration': return this.scalarOperator(this.operatorNormalizeDuration, args); break;
    case 'not': return this.scalarOperator(this.operatorNot, args); break;
    case 'nullToEmpty': return this.scalarOperator(this.operatorNullToEmpty, args); break;
    case 'or': return this.scalarOperator(this.operatorOr, args); break;
    case 'parseDate': return this.scalarOperator(this.operatorParseDate, args); break;
    case 'parseUri': return this.scalarOperator(this.operatorParseUri, args); break;
    case 'path': return this.scalarOperator(this.operatorPath, args); break;
    case 'position': return this.operatorPosition(args); break;
    case 'position1': return this.operatorPlus([this.operatorPosition(args), Value.v(1, 'integer')]); break;
    case 'power': return this.scalarOperator(this.operatorPower, args); break;
    case 'quote': return this.operatorQuote(args); break;
    case 'random': return this.scalarOperator(this.operatorRandom, args); break;
    case 'reduce': return this.operatorReduce(args); break;  
    case 'relative': return this.operatorRelative(args); break;
    case 'removeDiacritics': return this.scalarOperator(this.operatorRemoveDiacritics, args); break;
    case 'removeTimezone': return this.scalarOperator(this.operatorRemoveTimezone, args); break;
    case 'replace': return this.scalarOperator(this.operatorReplace, args); break;
    case 'result': return this.operatorResult(args); break;
    case 'reverse': return this.operatorReverse(args); break;
    case 'riRelativize': return this.scalarOperator(this.operatorRiRelativize, args); break;
    case 'riResolve': return this.scalarOperator(this.operatorRiResolve, args); break;
    case 'round': return this.scalarOperator(this.operatorRound, args); break;
    case 's:=':
    case 's:==':
    case 's:!==':
    case 's:!=':
    case 's:=~':
    case 's::=':
    case 's:<':
    case 's:<=':
    case 's:>':
    case 's:>=':
    case 's:<*':
    case 's:<=*':
    case 's:>*':
    case 's:>=*': return this.operatorStorageOperator(this.data.operator.substring(2), args); break;
    case 's:and': return this.runOperator(this.operatorStorageAnd, FunctionMode.AdaptiveArgs, args); break;
    case 's:or': return this.runOperator(this.operatorStorageOr, FunctionMode.AdaptiveArgs, args); break;
    case 's:path': return this.operatorStoragePath(args); break;
    case 's:property': return this.operatorStorageProperty(args); break;
    case 's:trailingPath': return this.operatorStorageTrailingPath(args); break;
    case 's:value': return this.operatorStorageValue(args); break;
    case 'select': return this.operatorSelect(args); break;
    case 'setTimezone': return this.scalarOperator(this.operatorSetTimezone, args); break;
    case 'shorten': return this.scalarOperator(this.operatorShorten, args); break;
    case 'some': return this.operatorSome(args); break;
    case 'sort': return this.operatorSort(args); break;
    case 'split': return this.scalarOperator(this.operatorSplit, args); break;
    case 'startsWith': return this.scalarOperator(this.operatorStartsWith, args); break;
    case 'staticValue': return this.scalarOperator(this.operatorStaticValue, args); break;
    case 'staticValues': return this.scalarOperator(this.operatorStaticValues, args); break;
    case 'staticValueKeys': return this.scalarOperator(this.operatorStaticValueKeys, args); break;
    case 'stringContains': return this.scalarOperator(this.operatorStringContains, args); break;
    case 'stringFind': return this.scalarOperator(this.operatorStringFind, args); break;
    case 'submittedBy': return this.operatorSubmittedBy(args); break;
    case 'subsequence': return this.operatorSubsequence(args); break;
    case 'substring': return this.scalarOperator(this.operatorSubstring, args); break;
    case 'substring1': return this.scalarOperator(this.operatorSubstring1, args); break;
    case 'sum': return this.runOperator(this.operatorSum, FunctionMode.AdaptiveScalarArgsCount, args); break;
    case 'switch': return this.runOperator(this.operatorSwitch, FunctionMode.AdaptiveScalarFirstArg, args); break;
    case 'tableLookup': return this.scalarOperator(this.operatorTableLookup, args); break;
    case 'tableLookupPrefix': return this.scalarOperator(this.operatorTableLookupPrefix, args); break;
    case 'timezone': return this.scalarOperator(this.operatorTimezone, args); break;
    case 'toDate': return this.scalarOperator(this.operatorToDate, args); break;
    case 'toDateTime': return this.scalarOperator(this.operatorToDateTime, args); break;
    case 'toMilliseconds': return this.scalarOperator(this.operatorToMilliseconds, args); break;
    case 'toLowerCase': return this.scalarOperator(this.operatorToLowerCase, args); break;
    case 'toTime': return this.scalarOperator(this.operatorToTime, args); break;
    case 'toTimezone': return this.scalarOperator(this.operatorToTimezone, args); break;
    case 'toUpperCase': return this.scalarOperator(this.operatorToUpperCase, args); break;
    case 'toUtc': return this.scalarOperator(this.operatorToTimezone, [...args, Value.v('utc', 'string')]); break;
    case 'treeFind': return this.operatorTreeFind(args); break;
    case 'treeReduce': return this.operatorTreeReduce(args); break;
    case 'trim': return this.scalarOperator(this.operatorTrim, args); break;
    case 'trim0': return this.scalarOperator(this.operatorTrim0, args); break;
    case 'try': return this.operatorTry(args); break;
    case 'true': return true; break;
    case 'typeOf': return this.operatorTypeOf(args); break;
    case 'union': return this.operatorUnion(args); break;
    case 'unquote': return Value.error('function "unquote" can be used only inside "quote" function!'); break;
    case 'update': return this.operatorUpdate(args); break;
    case 'updateData': return this.operatorUpdateData(args); break;
    case 'uuid': return this.operatorUUID(); break;
    case 'validateBic': return this.scalarOperator(this.operatorValidateBic, args); break;
    case 'validateIban': return this.scalarOperator(this.operatorValidateIban, args); break;
    case 'valueAt': return this.operatorValueAt(args); break;
    case 'valueToJson': return this.operatorDataToJson(args); break;
    case 'xmlToData': return this.scalarOperator(this.operatorXmlToData, args); break;
    default:
      if (this.opts && this.opts.function && this.opts.function[this.data.operator]) {
        return this.runOperator(this.customOperator, this.opts.function[this.data.operator].mode, args)
      } else {
        return Value.error('Unknown function "' + this.data.operator + '"!')
      }
  }
}

Expression.prototype.runOperator = function(operator, mode, args) {
  switch (mode) {
    case FunctionMode.Scalar: return this.scalarOperator(operator, args)
    case FunctionMode.NonScalar: return operator.call(this, args)
    case FunctionMode.AdaptiveScalarFirstArg: return args && args[0] && Value.isCollection(args[0]) ? this.scalarOperator(operator, args) : operator.call(this, args)
    case FunctionMode.AdaptiveScalarArgsCount: return args && args.length == 1 ? operator.call(this, args) : this.scalarOperator(operator, args)
    case FunctionMode.AdaptiveArgs: return args && args.length == 1 && Value.isCollection(args[0]) ? operator.call(this, args[0].value) : operator.call(this, args)
  }
}

Expression.prototype.scalarOperatorCall = function(operator, args) {
  if (['if', 'switch'].indexOf(this.data.operator) == -1) {
    for (let i=0; i<args.length; i++) {
      if (Value.isError(args[i])) {
        return args[i]
      }
    }
  }
  return operator.call(this, args)
}  

Expression.prototype.scalarOperator = function(operator, args) {
  if (MC.isNull(args) && !Array.isArray(args)) {
    return operator.call(this, args)
  }
  let size = -1
  for (let i=0; i<args.length; i++) {
    if (Value.isCollection(args[i])) {
      if (Value.collectionSize(args[i]) > size) {
        size = Value.collectionSize(args[i])
      }
    }
  }
  if (size == -1) {
    return this.scalarOperatorCall(operator, args)
  } else if (size == 0) {
    let scalarArguments = []
    for (let i = 0; i < args.length; i++) {
      if (Value.isCollection(args[i]) || Value.isNull(args[i])) {
        scalarArguments.push(Value.v(null))
      } else {
        scalarArguments.push(args[i])
      }
    }
    return this.scalarOperatorCall(operator, args)
  } else {
    let resultColl = []
    let firstErr = null
    for (let i = 0; i < size; i++) {
      let itemArgumentsValue = []
      for (let j = 0; j < args.length; j++) {
        let argument = args[j]
        if (Value.isCollection(argument)) {
          if (i < Value.collectionSize(argument)) {
            itemArgumentsValue.push(Value.collectionItem(argument, i))
          } else {
            itemArgumentsValue.push(Value.v(null))
          }
        } else {
          itemArgumentsValue.push(argument)
        }
      }
      let itemArguments = []
      for (let j = 0; j < itemArgumentsValue.length; j++) {
        itemArguments.push(itemArgumentsValue[j])
      }
      let res
      try {
        res = this.scalarOperator(operator, itemArguments)
      } catch (e) {
        res = Value.error(e.message)
      }
      if (Value.isError(res) && !firstErr) {
        firstErr = res
      }
      resultColl.push(res)      
    }
    let res = Value.v(resultColl, 'collection')
    // COMPATIBILITY FLAG
    if ('if' == this.data.operator && resultColl.length == 1 && !this.starfix) {
      res = Value.collectionItem(res, 0)
    }
    if (firstErr) {
      Value.errorInside(res, Value.getErrorMessage(firstErr))
    }
    return res
  }
}

Expression.prototype.customOperator = function(args) {
  let def = this.opts.function[this.data.operator]
  let opts = Object.assign({}, this.opts)
  opts.scope = {vars: {}}
  this.clearBase(opts)
  if (def.arg) {
    if (this.opts.trace && !Array.isArray(this.trace.args)) {
      this.trace.args = []
    }
    for (let i=0; i<def.arg.length; i++) {
      let argdef = def.arg[i]
      let value = (!args[i] || Value.isNull(args[i])) && !MC.isNull(argdef.defaultValue) ? Value.v(argdef.defaultValue, argdef.basicType) : (MC.isNull(args[i]) ? Value.v(null) : args[i])
      if (!argdef.optional && value === undefined) {
        return Value.error(`Argument ${i} of custom function ${def.name} is mandatory.`)
      }
      value = argdef.collection ? Value.castToCollection(value) : argdef.basicType != 'anyType' ? Value.castToScalar(value, argdef.basicType) : value
      if (this.opts.trace && !this.trace.args[i]) {
        this.trace.args[i] = {}
      }
      if (argdef.name) {
        opts.scope.vars['$'+argdef.name] = value
        if (this.opts.trace) {
          this.trace.args[i].variable = '$'+argdef.name
        }
      }
      opts.scope.vars['$'+(i+1)] = value
      if (this.opts.trace) {  
        this.trace.args[i].variable = '$'+(i+1)
      }
    }  
  }
  if (def.mapping && def.mapping.length > 0) {
    let exprs = this.evaluateVariables(def.mapping, opts)
    if (Value.isError(exprs)) {
      return exprs
    }
    for (let expr of exprs) {
      let res = this.evaluateLazy(expr, opts, def.name, 'eval') 
      if (res != undefined && !Value.isPureNull(res)) {
        if (!def.optional && Value.isPureNull(res)) {
          return Value.error(`Custom function ${def.name} does not permit null value as a result.`)
        }
        if (def.basicType) {
          if (def.collection) {
            res = Value.castToCollection(res)
          } else {
            res = def.basicType != 'anyType' ? Value.castToScalar(res, def.basicType) : res
          }
        } else {
          res = Value.v(null)
        }
        return res
      }
    }          
  }
  return Value.v(null)
}

Expression.prototype.printArgs = function(args) {
  let res = []
  for (let arg of args) {
    res.push('"' + Value.toLiteral(arg, false) + '"')
  }
  return "[" + res.join(", ") + "]"
}

Expression.prototype.operatorGreater = function(args) {
  if (args.length != 2) {
    return Value.error('Function ">" works only with two args! ' + args.length + ' args were passed.')
  }
  args = this.normalizeToCompare(args)
  if (Value.isNumber(args[0]) && Value.isNumber(args[1])) {
    return Value.v((new Decimal(args[0].value)).greaterThan(args[1].value), 'boolean')
  } else if (Value.isDuration(args[0]) && Value.isDuration(args[1])) {
    let comp = args[0].value.compareTo(args[1].value)
    if (comp == -2) {
      return Value.error(`Uncomparable values: '${args[0].value.toIsoString()}' and '${args[1].value.toIsoString()}'`)
    }
    return args[0].value.compareTo(args[1].value) > 0 ? Value.v(true, 'boolean') : Value.v(false, 'boolean')  
  } else {
    return Value.v(args[0].value > args[1].value, 'boolean')
  }
}

Expression.prototype.operatorLower = function(args) {
  if (args.length != 2) {
    return Value.error('Function "<" works only with two args! ' + args.length + ' args were passed.')
  }
  args = this.normalizeToCompare(args)
  if (Value.isNull(args[0]) && !Value.isNull(args[1])) {
    return Value.v(true, 'boolean')
  }
  if (Value.isNumber(args[0]) && Value.isNumber(args[1])) {
    return Value.v((new Decimal(args[0].value)).lessThan(args[1].value), 'boolean')
  } else if (Value.isDuration(args[0]) && Value.isDuration(args[1])) {
    let comp = args[0].value.compareTo(args[1].value)
    if (comp == -2) {
      return Value.error(`Uncomparable values: '${args[0].value.toIsoString()}' and '${args[1].value.toIsoString()}'`)
    }
    return args[0].value.compareTo(args[1].value) < 0 ? Value.v(true, 'boolean') : Value.v(false, 'boolean')   
  } else {
    return Value.v(args[0].value < args[1].value, 'boolean')
  }
}

Expression.prototype.operatorGreaterEquals = function(args) {
  if (args.length != 2) {
    return Value.error('Function ">=" operator works only with two args! ' + args.length + ' args were passed.')
  }
  args = this.normalizeToCompare(args)
  if (Value.isNull(args[0]) && !Value.isNull(args[1]) && (Value.isEmpty(args[1]) || args[1].type == 'string' && args[1].value.trim() === '')) {
    return Value.v(false, 'boolean')
  }
  if (Value.isNumber(args[0]) && Value.isNumber(args[1])) {
    return Value.v((new Decimal(args[0].value)).greaterThanOrEqualTo(args[1].value), 'boolean')
  } else if (Value.isDuration(args[0]) && Value.isDuration(args[1])) {
    let comp = args[0].value.compareTo(args[1].value)
    if (comp == -2) {
      return Value.error(`Uncomparable values: '${args[0].value.toIsoString()}' and '${args[1].value.toIsoString()}'`)
    }
    return args[0].value.compareTo(args[1].value) >= 0 ? Value.v(true, 'boolean') : Value.v(false, 'boolean')   
  } else {
    return Value.v(args[0].value >= args[1].value, 'boolean')
  }
}

Expression.prototype.operatorLowerEquals = function(args) {
  if (args.length != 2) {
    return Value.error('Function "<=" works only with two args! ' + args.length + ' args were passed.')
  }
  args = this.normalizeToCompare(args)
  if (Value.isNull(args[0]) && !Value.isNull(args[1])) {
    return Value.v(true, 'boolean')
  }
  if (Value.isNumber(args[0]) && Value.isNumber(args[1])) {
    return Value.v((new Decimal(args[0].value)).lessThanOrEqualTo(args[1].value), 'boolean')
  } else if (Value.isDuration(args[0]) && Value.isDuration(args[1])) {
    let comp = args[0].value.compareTo(args[1].value)
    if (comp == -2) {
      return Value.error(`Uncomparable values: '${args[0].value.toIsoString()}' and '${args[1].value.toIsoString()}'`)
    }
    return args[0].value.compareTo(args[1].value) <= 0 ? Value.v(true, 'boolean') : Value.v(false, 'boolean')
  } else {
    return Value.v(args[0].value <= args[1].value, 'boolean')
  }
}

Expression.prototype.operatorCount = function(args) {
  if (args.length > 1) {
    return Value.error('Count function works only with one argument! ' + args.length + ' args were passed.')
  }
  if (Value.isNull(args[0])) {
    return Value.v(0, 'integer')
  } else if (Value.isCollection(args[0])) {
    let result = 0
    let coll = Value.collectionValue(args[0])
    for (let i=0; i<coll.length; i++) {
      if (Value.isCollection(coll[i])) {
        result += parseInt(this.operatorCount([coll[i]]).value)
      } else if (!Value.isNull(coll[i])) {
        result++
      }
    }
    return Value.v(result, 'integer')
  } else {
    return Value.v(1, 'integer')
  }
}

Expression.prototype.operatorCollectionSize = function(args) {
  if (args.length > 1) {
    return Value.error('Function "collectionSize" works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isPureNull(args[0])) {
    return Value.v(0, 'integer')
  } else if (Value.isCollection(args[0])) {
    return Value.v(Value.collectionSize(args[0]), 'integer') 
  } else {
    return Value.v(1, 'integer')
  }
}

Expression.prototype.operatorAvg = function(args) {
  const sum = (coll, currentSum, countHolder) => {
    for (let v of coll) {
      if (Value.isCollection(v)) {
        currentSum = sum(Value.collectionValue(v), currentSum, countHolder)
      } else {
        let dv = Value.castToScalar(v, 'decimal')
        if (!Value.isNull(dv) && !Value.isEmpty(dv)) {
          if (Value.isNumber(dv)) {
            currentSum = currentSum == null ? new Decimal(dv.value) : currentSum.add(dv.value)
            countHolder.count++
          } else {
            return Value.error('Argument of function "avg" must be number! Passed: ' + Value.toLiteral(dv))
          }
        }
      }
    }
    return currentSum
  }
  if (args.length == 0) {
    return Value.error('Function "avg" must have at least one argument!')
  }
  let coll = null
  if (args.length == 1) {
    coll = Value.collectionValue(Value.castToCollection(args[0]))
  } else {
    coll = []
    for (let arg of args) {
      coll.push(Value.castToScalar(arg))
    }
  }
  let countHolder = {count: 0}
  let result = sum(coll, null, countHolder)
  if (result == null) {
    return Value.v(null)
  }
  result = result.div(countHolder.count)
  return Value.v(result.toFixed(result.isInt() || result.dp() < 18 ? undefined : 18), 'decimal')
}

Expression.prototype.operatorValueAt = function(args) {
  if (args.length != 2) {
    return Value.error('Function "valueAt" must have exactly two arguments! Passed arguments:' + this.printArgs(args))
  }
  let index
  try {
    index = Value.castToScalar(args[1], 'int')
  } catch (e) {
    return Value.error('Second argument of function "valueAt" must be an integer! Passed arguments:' + this.printArgs(args))
  }
  if (Value.isNullOrEmpty(index)) {
    return Value.error('Second argument of function "valueAt" must not be null or empty!')
  }
  index = parseInt(index.value)
  let coll = this.flattenCollection(args[0], true)
  if (index < 0) {
    index = Value.collectionSize(coll) + index
  }
  if (index < 0 || index >= Value.collectionSize(coll)) {
    return Value.v(null)
  }
  return Value.collectionItem(coll, index)
}

Expression.prototype.operatorCollectionItem = function(args) {
  if (args.length != 2) {
    return Value.error('Function "collectionItem" must have exactly two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let index
  try {
    index = Value.castToScalar(args[1], 'int')
  } catch (e) {
    return Value.error('Second argument of function "collectionItem" must be an integer! Passed arguments:' + this.printArgs(args))
  }
  if (Value.isNullOrEmpty(index)) {
    index = 0
  } else {
    index = parseInt(index.value)
  }
  if (!Value.isCollection(args[0])) {
    if (index == 0 || index == -1) {
      return args[0]
    } else {
      return Value.v(null)
    }
  } else {
    if (index < 0) {
      index = Value.collectionSize(args[0]) + index
    }
    if (index < 0 || index >= Value.collectionSize(args[0])) {
      return Value.v(null)
    } 
    return Value.collectionItem(args[0], index) 
  }
}

Expression.prototype.operatorFirst = function(args) {
  if (args.length != 1) {
    return Value.error('Function "first" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (!Value.isCollection(args[0])) {
    return args[0]
  } else {
    if (Value.collectionSize(args[0]) == 0) {
      return Value.v(null)
    } else {
      return Value.collectionItem(args[0], 0)
    }
  }
}

Expression.prototype.operatorLast = function(args) {
  if (args.length != 1) {
    return Value.error('Function "last" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (!Value.isCollection(args[0])) {
    return args[0]
  } else {
    if (Value.collectionSize(args[0]) == 0) {
      return Value.v(null)
    } else {
      return Value.collectionItem(args[0], Value.collectionSize(args[0])-1)
    }
  }
}

Expression.prototype.operatorSubsequence = function(args) {
  if (args.length < 2 || args.length > 3) {
    return Value.error('Function "subsequence" must have two or three arguments! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
    return args[0]
  }
  if (!Value.isCollection(args[0]) && Value.isNull(args[0])) {
    return Value.v(null)
  }
  let argument1Coll = Value.castToCollection(args[0])
  let length = Value.collectionSize(argument1Coll)
  let from = Value.castToScalar(args[1], 'int')
  from = Value.isNullOrEmpty(from) ? 0 : parseInt(from.value)
  if (from < 0) {
    from = from + length
  }
  if (from < 0) {
    from = 0
  } else if (from > length) {
    from = length
  }
  let to = Value.v(null)
  if (args.length == 3) {
    to = Value.castToScalar(args[2], 'int')
  }
  to = Value.isNullOrEmpty(to) ? length : parseInt(to.value)
  if (to < 0) {
    to = to + length
  }
  if (to < 0) {
    to = 0
  } else if (to < length) {
    to++
  } else if (to > length) {
    to = length
  }
  if (to < from) {
    to = from
  }
  let result = Value.v(argument1Coll.value.slice(from, to), 'collection')
  if (Value.collectionSize(result) == 0) {
    return Value.v(null)
  }
  return result
}

Expression.prototype.operatorConcat = function(args) {
  let result = ''
  for (let arg of args) {
    if (!Value.isNullOrEmpty(arg)) {
      result += Value.castToScalar(arg, 'string').value
    }
  }
  return Value.v(result, 'string')
}

Expression.prototype.operatorSubmittedBy = function(args) {
  if (args.length > 1) {
    return Value.error('SubmittedBy operator works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  let argument = args[0] || Value.v(null)
  let lastFormAction = this.evaluateSource({source: '@lastFormAction'}, false)
  let event = this.evaluateSource({source: '@event'}, false)
  if (Value.isNull(event)) {
    if (Value.isNull(lastFormAction) || Value.isNull(argument) || !argument.value.startsWith(lastFormAction.value + '/')) {
      return Value.v(false, 'boolean')
    }
  }
  argument = argument.value
  let argumentAction = argument.substring(0, argument.indexOf('/'))
  let submitTriggerPath = this.evaluateSource({source: argumentAction + '/@submitTriggerPath'}, false)
  return Value.v(argument == argumentAction + '/' + submitTriggerPath.value, 'boolean')
}

Expression.prototype.operatorSelect = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    return Value.error('Function "select" must have two to four args! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  let base = this.enterBaseContext()
  let result = this.select(arg1Coll, exprs)
  this.leaveBaseContext(base)
  return result
}

Expression.prototype.select = function(arg1Coll, exprs) {
  let result = []
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    let item = Value.collectionItem(arg1Coll, i)
    this.setPosition(i, item)
    let resultItem = Value.v(null)
    if (Value.isCollection(item)) {
      resultItem = this.select(item, exprs)
    } else {
      let isSelectedValue = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1), 'boolean')
      if (Value.isError(isSelectedValue)) {
        return isSelectedValue
      }
      if (Value.isTrue(isSelectedValue)) {
        if (exprs.length > 2) {
          resultItem = this.evaluateLazy(exprs[2], null, null, null, 2)
        } else {
          resultItem = item
        }
      } else {
        if (exprs.length > 3) {
          resultItem = this.evaluateLazy(exprs[3], null, null, null, 3) 
        }
      }
    }
    if (Value.isError(resultItem)) {
      return resultItem
    }
    result.push(resultItem)
    this.unsetPosition()
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorFilter = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    return Value.error('Function "filter" must have two to four args! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  let base = this.enterBaseContext()
  var result = []
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    let item = Value.collectionItem(arg1Coll, i)
    this.setPosition(i, item)
    let isSelectedValue = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1), 'boolean')
    if (Value.isError(isSelectedValue)) {
      return isSelectedValue
    }
    let resultItem = Value.v(null)
    if (Value.isTrue(isSelectedValue)) {
      if (exprs.length > 2) {
        resultItem = this.evaluateLazy(exprs[2], null, null, null, 2)
      } else {
        resultItem = item
      }
    } else {
      if (exprs.length > 3) {
        resultItem = this.evaluateLazy(exprs[3], null, null, null, 3)
      }
    }
    if (Value.isError(resultItem)) {
      return resultItem
    }
    if (!Value.isNull(resultItem)) {
      result.push(resultItem)
    }
    this.unsetPosition()
  }
  this.leaveBaseContext(base)
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorFind = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    return Value.error('Function "find" must have two to four args! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  let base = this.enterBaseContext()
  let result
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    let item = Value.collectionItem(arg1Coll, i)
    this.setPosition(i, item)
    let isSelectedValue = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1), 'boolean')
    if (Value.isError(isSelectedValue)) {
      return isSelectedValue
    }
    if (Value.isTrue(isSelectedValue)) {
      if (exprs.length > 2) {
        result = this.evaluateLazy(exprs[2], null, null, null, 2) 
      } else {
        result = item
      }
      break
    }
    this.unsetPosition()
  }
  this.leaveBaseContext(base)
  if (!result) {
    result = exprs.length > 3 ? this.evaluateLazy(exprs[3]) : Value.v(null)
  } 
  return result
}

Expression.prototype.operatorTreeFind = function(exprs) {
  if (exprs.length < 2 || exprs.length > 4) {
    return Value.error('Function "treeFind" must have 2 to 4 arguments! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNullOrEmpty(arg1Coll)) {
    return arg1Coll
  }
  let base = this.enterBaseContext()
  let result = Value.v(null)
  arg1Coll = Value.collectionValue(Value.castToCollection(arg1Coll))
  arg1Coll = [...arg1Coll]
  while (arg1Coll.length > 0) {
    let item = arg1Coll.shift()
    this.setPosition(0, item)
    let isSelectedValue = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1), 'boolean')
    if (Value.isError(isSelectedValue)) {
      return isSelectedValue
    }
    if (exprs.length > 2) {
      let subNodes = this.evaluateLazy(exprs[2], null, null, null, 2)
      if (Value.isError(subNodes)) {
        return subNodes
      }
      if (!Value.isNull(subNodes)) {
        subNodes = Value.collectionValue(Value.castToCollection(subNodes))
        arg1Coll = arg1Coll.concat(subNodes)
      }
    }
    let resultItem = Value.v(null)
    if (Value.isTrue(isSelectedValue)) {
      if (exprs.length > 3) {
        resultItem = Value.castToScalar(this.evaluateLazy(exprs[3], null, null, null, 3))
      } else {
        resultItem = item
      }
    }
    if (!Value.isNull(resultItem)) {
      result = resultItem
      break
    }
    this.unsetPosition()
  }
  this.leaveBaseContext(base)
  return result
}

Expression.prototype.getData = function(item, dataPath) {
  if (Value.isCollection(item)) {
    item = Value.collectionItem(item, 0)
  }
  let i = dataPath.indexOf('/')
  let key = dataPath
  if (i > -1) {
    key = key.substring(0, i)
  }
  if (key.endsWith('*')) {
    key = key.substring(0, key.length -1)
  }
  if (i < 0) {
    return Value.getProperty(item, key)
  } else {
    if (Value.hasProperty(item, key)) {
      return this.getData(Value.getProperty(item, key), dataPath.substring(i + 1))
    } else {
      return Value.v(null)
    }
  }
}

Expression.prototype.operatorRelative = function(args) {
  if (args.length > 1) {
    return Value.error('Function "relative" must have zero or one argument! Passed arguments: ' + this.printArgs(args))
  }
  let basePathRefs = this.bases()
  if (MC.isNull(basePathRefs)) {
    return Value.error('Base is not defined, cannot use function "relative" here!')
  }
  let relativePath
  if (args.length == 0) {
    relativePath = "."
  } else {
    if (Value.isNullOrEmpty(args[0])) {
      relativePath = "."
    } else {
      relativePath = Value.castToScalar(args[0], 'string').value
    }
  }
  if (relativePath == "." || relativePath.startsWith("./")) {
    relativePath = relativePath.replace(".", "$v" + basePathRefs.length)
  } else if (!relativePath.startsWith("$v")) {
    relativePath = "$v" + basePathRefs.length + "/" + relativePath
  }
  let i = relativePath.indexOf("/")
  let relativeToBase = parseInt(i == -1 ? relativePath.substring(2) : relativePath.substring(2, i))
  if (relativeToBase > this.positions().length) {
    return Value.error("Iteration variable $v" + relativeToBase + " not defined")
  }
  relativePath = i == -1 ? "." : relativePath.substring(i +  1);
  let positions = this.positions().slice().reverse()[relativeToBase - 1].slice();
  let positionValues = this.positionValues().slice().reverse()[relativeToBase - 1].slice()
  let basePathRef = basePathRefs.slice().reverse()[relativeToBase - 1]
  if (relativePath === ".") {
    return positionValues[0]
  } else if (!relativePath.startsWith('..')) {
    let positionValue = Value.collectionItem(this.flattenCollection(Value.v(positionValues, 'collection')), 0)
    if (Value.isDataNode(positionValue)) { 
      return this.getValue(positionValue, relativePath.split('/'), false)
    } else if (Value.isNullOrEmpty(positionValue)) {
      return Value.v(null)
    } else {
      return Value.error("Value at base path '" + basePathRef + "' is not a complex (data node) value, cannot dereference relative subpath '" + relativePath + "'")
    }
  }
  let steps = basePathRef.split('/')
  let shortenedBasePath
  let relativePathFull = relativePath
  if (relativePath.startsWith('..')) {
    while (relativePath.startsWith('..')) {
      let last = steps.pop()
      if (MC.isNull(last)) {
        return Value.error('Relative function error - unable to resolve relative path "' + relativePathFull + '" on base path "' + basePathRef + '"')
      }
      if (last.endsWith('*')) {
        positions.pop()
      }
      if (relativePath.length == '..'.length) {
        relativePath = null
      } else {
        relativePath = relativePath.substring('..'.length + '/'.length)
      }
    }
    shortenedBasePath = steps.join('/')
  } else {
    shortenedBasePath = basePathRef
  }
  let resolvedPath = this.relativize(shortenedBasePath, relativePath)
  let dereferenced = this.evaluateSource({source: resolvedPath}, false)
  while (positions.length > 0) {
    dereferenced = Value.castToCollection(dereferenced)
    let position = positions.shift()
    if (position >= Value.collectionSize(dereferenced)) {
      return Value.v(null)
    }
    dereferenced = Value.collectionItem(dereferenced, position)
  }
  return dereferenced
}

Expression.prototype.operatorResult = function(args) {
  if (args.length > 1) {
    return Value.error('Function "result" must have zero or one argument! Passed arguments: ' + this.printArgs(args))
  }
  let resultValues = this.resultValues()
  if (!Array.isArray(resultValues) || resultValues.length == 0) {
    return Value.error('Function "result" cannot be used here, not in a reduce context!')
  }
  if (args.length == 0) {
    return this.peekResultValue()
  } else {
    return Value.error('Function "result" with argument is not implemented yet!')
  }
}

Expression.prototype.operatorPosition = function(args) {
  if (args.length > 1) {
    return Value.error('Function "position" must have zero or one argument! Passed arguments: ' + this.printArgs(args))
  }
  let position = this.position()
  if (args.length < 1) {
    if (Array.isArray(position)) {
      position = position[position.length - 1]
      if (!MC.isNumeric(position)) {
        return Value.error('Position is not defined, cannot use function "position" here! Passed arguments: ' + this.printArgs(args))
      }
    }
  } else {
    let nesting = Value.castToScalar(args[0], 'string')
    if (Value.isNullOrEmpty(nesting)) {
      return Value.error('Nesting depth value must not be null or empty if argument is specified! Passed arguments: ' + this.printArgs(args))
    }
    if (!nesting.value.startsWith("$v")) {
      return Value.error('Invalid nesting depth value reference, must match "$v" + number! Passed arguments: ' + this.printArgs(args))
    }
    let nestingInt = parseInt(nesting.value.substring(2))
    if (nesting == NaN) {
      return Value.error('Invalid nesting depth value reference, must match "$v" + number! Passed arguments: ' + this.printArgs(args))
    }
    let positions = this.positions()
    if (nestingInt <= 0 || nestingInt > positions.length) {
      return Value.error('Nesting depth value out of range: ' + nestingInt + ', must be in 1 to ' + position.length + '! Passed arguments: ' + this.printArgs(args))
    }
    positions =  positions[(positions.length - 1) - (nestingInt - 1)]
    if (nestingInt <= 0 || nestingInt > positions.length) {
      position = positions[positions.length - 1]
    } else {
      position = positions[nestingInt - 1]
    }
  }
  return Value.v(position, 'int')
}

Expression.prototype.relativize = function(path, relPath) {
  if (relPath == '.') {
    return path;
  } else if (relPath.startsWith('./')) {
    relPath = relPath.replace(/\.\//g, '');
    return this.relativize(path, relPath);
  } else if (relPath.startsWith('../')) {
    relPath = relPath.replace(/\.\.\//, '');
    path = path.substring(0, path.lastIndexOf('/'));
    return this.relativize(path, relPath);
  } else {
    return path + (path ? '/' : '') + relPath;
  }
};

Expression.prototype.operatorEquals = function(args) {
  if (args.length != 2) {
    return Value.error('== operator works only with two args! ' + args.length + ' args were passed.')
  }
  args = this.normalizeToCompare(args)
  if (Value.isNumber(args[0]) && Value.isNumber(args[1])) {
    return Value.v((new Decimal(args[0].value)).equals(args[1].value), 'boolean')
  } else if (Value.isDateOrTime(args[0]) && Value.isDateOrTime(args[1])) {
    return Value.v(args[0].value.equals(args[1].value), 'boolean')
  } else if (Value.isDuration(args[0]) && Value.isDuration(args[1])) {
    let comp = args[0].value.compareTo(args[1].value)
    if (comp == -2) {
      return Value.error(`Uncomparable values: '${args[0].value.toIsoString()}' and '${args[1].value.toIsoString()}'`)
    }
    return args[0].value.compareTo(args[1].value) == 0 ? Value.v(true, 'boolean') : Value.v(false, 'boolean')  
  } else {
    return Value.v(args[0].value == args[1].value, 'boolean')
  }
}

Expression.prototype.normalizeToCompare = function(args) {
  args[0] = Value.castToScalar(args[0], args[0].type)
  args[1] = Value.castToScalar(args[1], args[1].type)
  if (args[0].type == "boolean" && !Value.isNullOrEmpty(args[1])) {
    args[1] = Value.v(args[1].value, 'boolean')
  } else if (Value.isNumber(args[0]) && !Value.isNullOrEmpty(args[1])) {
    args[1] = Value.v(args[1].value, 'decimal')
  } else if (Value.isNumber(args[1]) && !Value.isNullOrEmpty(args[0])) {  
    args[0] = Value.v(args[0].value, 'decimal')
  } else if (Value.isDateOrTime(args[0]) || Value.isDateOrTime(args[1])) {
    let lux0 = MC.dateTimeStringToLuxon(args[0].value)
    let lux1 = MC.dateTimeStringToLuxon(args[1].value)
    if (lux0.v.isValid && lux1.v.isValid) {
      args[0] = {type: 'dateTime', value: lux0.v}
      args[1] = {type: 'dateTime', value: lux1.v}
    } else {
      throw new Error(`Uncomparable values: '${args[0].value}' and '${args[1].value}'`)
    }
  } else if (Value.isDuration(args[0]) && !Value.isNullOrEmpty(args[1]) || Value.isDuration(args[1]) && !Value.isNullOrEmpty(args[0])) {
    args[0] = Value.v(args[0].value, 'duration')  
    args[1] = Value.v(args[1].value, 'duration')
    let duration0 = new Duration()
    duration0.parseIsoString(args[0].value)
    let duration1 = new Duration()
    duration1.parseIsoString(args[1].value)
    args[0].value = duration0
    args[1].value = duration1
  } else {
    args[0] =  Value.v(args[0].value, 'string', Value.isEmpty(args[0]))
    args[1] =  Value.v(args[1].value, 'string', Value.isEmpty(args[1]))
  }
  return args
}

Expression.prototype.operatorNotEquals = function(args) {
  if (args.length != 2) {
    return Value.error('!= operator works only with two args! ' + args.length + ' args were passed.')
  }
  args = this.normalizeToCompare(args)
  if (Value.isNumber(args[0]) && Value.isNumber(args[1])) {
    return Value.v(!(new Decimal(args[0].value)).equals(args[1].value), 'boolean')
  } else if (Value.isDateOrTime(args[0]) && Value.isDateOrTime(args[1])) {
    return Value.v(!args[0].value.equals(args[1].value), 'boolean')
  } else if (Value.isDuration(args[0]) && Value.isDuration(args[1])) {
    let comp = args[0].value.compareTo(args[1].value)
    if (comp == -2) {
      return Value.error(`Uncomparable values: '${args[0].value.toIsoString()}' and '${args[1].value.toIsoString()}'`)
    }
    return args[0].value.compareTo(args[1].value) != 0 ? Value.v(true, 'boolean') : Value.v(false, 'boolean') 
  } else {
    return Value.v(args[0].value != args[1].value, 'boolean')
  }
}

Expression.prototype.operatorEmptyToNull = function(args) {
  if (args.length != 1) {
    return Value.error('emptyToNull operator works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return Value.v(null)
  } else {
    return args[0]
  }
}

Expression.prototype.operatorIf = function(args) {
  if (args.length < 2 || args.length > 3) {
    return Value.error('If function works only with two or three arguments! ' + args.length + ' arguments were passed.')
  }
  let condition =  Value.castToScalar(args[0], 'boolean')
  if (Value.isTrue(condition)) {
    return this.evaluateLazy(args[1], this.opts)
  } else if (args.length == 3) {
    return this.evaluateLazy(args[2], this.opts)
  } else {
    return Value.v(null)
  }
}

Expression.prototype.caseResult = function(input, exprs, argumentValues) {
  let i = 1
  while (true) {
    if (i > argumentValues.length - 1) {
      return Value.v(null)
    }
    if (i == argumentValues.length - 1) {
      let result = argumentValues[i]
      if (Value.isNull(result)) {
        result = this.evaluateLazy(exprs[i]) 
        argumentValues[i] = result       
      }
      return result
    }
    let _case = argumentValues[i]
    if (Value.isNull(_case)) {
      _case = this.evaluateLazy(exprs[i]) 
      argumentValues[i] = _case
    }
    if (Value.isError(_case)) {
      return _case
    }
    if (Value.isNull(_case) && Value.isNull(input) || Value.isEmpty(_case) && Value.isEmpty(input) || Value.isTrue(this.operatorEquals([input, _case]))) {
      let result = argumentValues[i + 1]
      if (Value.isNull(result)) {
        result = this.evaluateLazy(exprs[i+1]) 
        argumentValues[i + 1] = result
      }
      return result
    }
    i = i + 2
  }
}

Expression.prototype.operatorSwitch = function(exprs) {
  if (exprs.length < 2) {
    return Value.error('Function swith must have at least two arguments" ' + exprs.length + ' arguments were passed.')
  }
  return this.caseResult(exprs[0], exprs, new Array(exprs.length).fill(Value.v(null)))
}

Expression.prototype.operatorMin = function(args) {
  const min = (type, collection, currentMin) => {
    for (let item of collection) {
      if (Value.isCollection(item)) {
        currentMin = min(type, Value.collectionValue(item), currentMin)
      } else {
        let itemScalar = Value.castToScalar(item, type)
        if (!Value.isNull(itemScalar) && !Value.isEmpty(itemScalar)) {
          if (type == 'integer' || type == 'decimal') {
            currentMin = currentMin == null ? itemScalar : new Decimal(currentMin.value).lessThan(itemScalar.value) ? currentMin : itemScalar
          } else if (type == 'dateTime' || type == 'date'  || type == 'time') {
            currentMin = currentMin == null || MC.dateTimeStringToLuxon(itemScalar.value).v < MC.dateTimeStringToLuxon(currentMin.value).v ? itemScalar : currentMin
          } else if (type == 'duration') {
            let duration = new Duration()
            duration.parseIsoString(itemScalar.value) 
            let curr = new Duration()
            if (currentMin !== null) {
              curr.parseIsoString(currentMin.value)
            }
            currentMin = currentMin == null || curr.compareTo(duration) > 0 ? itemScalar : currentMin
          } else {
            currentMin = currentMin == null || currentMin.value > itemScalar.value ? itemScalar : currentMin
          }
        }
      }
    }
    return currentMin
  }
  if (args.length == 0) {
    return Value.error('Function "min" must have at least one argument!')
  }
  let coll = null
  if (args.length == 1) {
    coll = Value.collectionValue(Value.castToCollection(args[0]))
  } else {
    coll = []
    for (let arg of args) {
      coll.push(Value.castToScalar(arg))
    }
  }
  let resultType = this.detectResultType(coll, null)
  if (resultType == null) {
    return Value.v(null)
  }
  if (['integer', 'decimal', 'dateTime', 'date', 'time', 'duration', 'string'].indexOf(resultType) > -1 ) {
    let result = min(resultType, coll, null)
    if (result && result.value === '' && resultType == 'string') {
      return Value.v(null)
    }
    return Value.castToScalar(result, resultType)
  } else {
    return Value.error('Function "min": Unknown aggregation mode for arguments: ' + this.printArgs(args))
  }
}

Expression.prototype.detectResultType = function(coll, candidate) {
  for (let item of coll) {
    if (Value.isNull(item) || Value.isEmpty(item)) {
      continue
    }
    let newCandidate = null
    if (Value.isCollection(item)) {
      newCandidate = this.detectResultType(Value.collectionValue(item), candidate)
    } else if (!Value.isDataNode(item)) {
      if (item.type != 'string' || item.value !== '') {
        if (Value.isNumber(item)) {
          newCandidate = Value.isInt(item) ? 'integer' : 'decimal'
        } else {
          newCandidate = item.type
        }
      }
    } else {
      throw new Error('Cannot aggregate non-scalar value: ' + Value.toLiteral(item))
    }
    if (candidate == null) {
      candidate = newCandidate
    } else if (newCandidate != null && candidate != newCandidate) {
      if (candidate == 'string') {
        candidate = newCandidate
      } else if (newCandidate != 'string') {
        if (candidate == 'integer') {
          if (newCandidate == 'decimal') {
            candidate = 'decimal'
          } else {
            throw new Error(`Cannot aggregate scalar value type ${candidate} with ${newCandidate}!`)
          }
        } else if (candidate == 'decimal') {
          if (newCandidate != 'integer') {
            throw new Error(`Cannot aggregate scalar value type ${candidate} with ${newCandidate}!`)
          }
        } else if (candidate == 'dateTime') {
          if (newCandidate != 'date') {
            throw new Error(`Cannot aggregate scalar value type ${candidate} with ${newCandidate}!`)
          }
        } else if (candidate == 'date') {
          if (newCandidate == 'dateTime') {
            candidate = 'dateTime'
          } else {
            throw new Error(`Cannot aggregate scalar value type ${candidate} with ${newCandidate}!`)
          }
        } else {
          throw new Error(`Cannot aggregate scalar value type ${candidate} with ${newCandidate}!`)
        }
      }
    }
  }
  return candidate
}


Expression.prototype.operatorMax = function(args) {
  const max = (type, collection, currentMax) => {
    for (let item of collection) {
      if (Value.isCollection(item)) {
        currentMax = max(type, Value.collectionValue(item), currentMax)
      } else {
        let itemScalar = Value.castToScalar(item, type)
        if (!Value.isNull(itemScalar) && !Value.isEmpty(itemScalar)) {
          if (type == 'integer' || type == 'decimal') {
            currentMax = currentMax == null ? itemScalar : new Decimal(currentMax.value).greaterThan(itemScalar.value) ? currentMax : itemScalar
          } else if (type == 'dateTime' || type == 'date'  || type == 'time') {
            currentMax = currentMax == null || MC.dateTimeStringToLuxon(itemScalar.value).v > MC.dateTimeStringToLuxon(currentMax.value).v ? itemScalar : currentMax
          } else if (type == 'duration') {
            let duration = new Duration()
            duration.parseIsoString(itemScalar.value) 
            let curr = new Duration()
            if (currentMax !== null) {
              curr.parseIsoString(currentMax.value)
            }
            currentMax = currentMax == null || curr.compareTo(duration) < 0 ? itemScalar : currentMax
          } else {
            currentMax = currentMax == null || currentMax.value < itemScalar.value ? itemScalar : currentMax
          }
        }
      }
    }
    return currentMax
  }
  if (args.length == 0) {
    return Value.error('Function "max" must have at least one argument!')
  }
  let coll = null
  if (args.length == 1) {
    coll = Value.collectionValue(Value.castToCollection(args[0]))
  } else {
    coll = []
    for (let arg of args) {
      coll.push(Value.castToScalar(arg))
    }
  }
  let resultType = this.detectResultType(coll, null)
  if (resultType == null) {
    return Value.v(null)
  }
  if (['integer', 'decimal', 'dateTime', 'date', 'time', 'duration', 'string'].indexOf(resultType) > -1 ) {
    let result = max(resultType, coll, null)
    if (result && result.value === '' && resultType == 'string') {
      return Value.v(null)
    }
    return Value.castToScalar(result, resultType)
  } else {
    return Value.error('Function "max": Unknown aggregation mode for arguments: ' + this.printArgs(args))
  }
}

Expression.prototype.operatorPlus = function(args) {
  if (args.length < 2) {
    return Value.error('Function "+" works only with two or more args! Passed arguments: ' + this.printArgs(args))
  }
  let result = Value.v(null)
  for (let i=0; i<args.length; i++) {
    let arg = Value.castToScalar(args[i], 'decimal')
    if (!Value.isNull(arg)) {
      if (Value.isEmpty(arg)) {
        if (!(result instanceof Decimal)) {
          result = arg
        }
      } else {
        if (result instanceof Decimal) {
          result = result.add(new Decimal(arg.value))
        } else {
          result = new Decimal(arg.value)
        }
      }
    }
  }
  if (result instanceof Decimal) {
    return Value.v(result.toFixed(result.isInt() || result.dp() < 18 ? undefined : 18), 'decimal')
  } else {
    return result
  }
}

Expression.prototype.operatorMinus = function(args) {
  if (args.length < 2) {
    return Value.error('Function "-" works only with two or more args! ' + args.length + ' args were passed. Passed arguments: ' + this.printArgs(args))
  }
  let result = new Decimal(Value.castToScalar(args[0], 'decimal').value)
  for (let i=1; i<args.length; i++) {
    let arg = Value.castToScalar(args[i], 'decimal')
    result = result.minus(new Decimal(arg.value))
  }
  return Value.v(result.toFixed(result.isInt() || result.dp() < 18 ? undefined : 18), 'decimal')
}

Expression.prototype.operatorMultiply = function(args) {
  if (args.length < 2) {
    return Value.error('Function "*" must have at least two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let result = null
  for (let arg of args) {
    arg = Value.castToScalar(arg, 'decimal')
    if (Value.isNullOrEmpty(arg)) {
      return Value.error('Cannot multiply with argument of function "*", value is ' + Value.toLiteral(arg) + '! Passed arguments: ' + this.printArgs(args))
    }
    if (!Value.isNumber(arg)) {
      return Value.error('Cannot cast to number argument of function "*", value is ' + Value.toLiteral(arg)  + '! Passed arguments: ' + this.printArgs(args))
    }
    if (result == null) {
      result = new Decimal(arg.value)
    } else {
      result = result.mul(new Decimal(arg.value))
    }
  }
  return result == null ? Value.v(null) : Value.v(result.toFixed(), 'decimal')
}

Expression.prototype.operatorPower = function(args) {
  if (args.length != 2) {
    return Value.error('Function "power" works only with two! Passed arguments: ' + this.printArgs(args))
  }
  let arg1 = Value.castToScalar(args[0], 'decimal')
  let arg2 = Value.castToScalar(args[1], 'decimal')
  if (Value.isNull(arg1) || Value.isEmpty(arg1) && Value.isNull(arg2) || Value.isEmpty(arg2)) {
    return Value.v(null)
  }
  if (Value.isNullOrEmpty(arg1) || Value.isNullOrEmpty(arg2)) {
    return Value.error('Either none or both args of function "power" must be specified Passed arguments: ' + this.printArgs(args))
  }
  return Value.v((new Decimal(arg1.value)).pow(new Decimal(arg2.value)).toPrecision(18), 'decimal')
}

Expression.prototype.operatorDivide = function(args) {
  if (args.length < 2) {
    return Value.error('Function "/" works only with two or more args! Passed arguments: ' + this.printArgs(args))
  }
  let result = new Decimal(Value.castToScalar(args[0], 'decimal').value)
  for (let i=1; i<args.length; i++) {
    let arg = Value.castToScalar(args[i], 'decimal')
    if (Number(arg.value).valueOf() == 0) {
      return Value.error('Operator "/" not allows dividing by zero! Passed arguments: ' + this.printArgs(args))
    }
    result = result.div(new Decimal(arg.value))
  }
  return Value.v(result.toFixed(), 'decimal')
}

Expression.prototype.operatorMod = function(args) {
  if (args.length < 2) {
    return Value.error('Function "mod" works only with two or more args! Passed arguments: ' + this.printArgs(args))
  }
  let result = new Decimal(Value.castToScalar(args[0], 'decimal').value)
  for (let i=1; i<args.length; i++) {
    let arg = Value.castToScalar(args[i], 'decimal')
    result = result.mod(new Decimal(arg.value))
  }
  return Value.v(result.toFixed(), 'decimal')
}

Expression.prototype.operatorDiv = function(args) {
  if (args.length < 2) {
    return Value.error('Function "div" works only with two or more args! Passed arguments: ' + this.printArgs(args))
  }
  let result = new Decimal(Value.castToScalar(args[0], 'decimal').value)
  for (let i=1; i<args.length; i++) {
    result = result.div(new Decimal(Value.castToScalar(args[i], 'decimal').value)).floor()
  }
  return Value.v(result.toFixed(), 'decimal')
}

Expression.prototype.operatorOr = function(args) {
  for (let arg of args) {
    arg = this.evaluateLazy(arg)
    if (Value.isError(arg)) {
      return arg
    }
    arg = Value.castToScalar(arg, 'boolean')
    if (Value.isTrue(arg)) {
      return Value.v(true, 'boolean')
    }
  }
  return Value.v(false, 'boolean')
}

Expression.prototype.operatorAnd = function(args) {
  for (let arg of args) {
    arg = this.evaluateLazy(arg)
    if (Value.isError(arg)) {
      return arg
    }
    arg = Value.castToScalar(arg, 'boolean')
    if (Value.isNullOrEmpty(arg) || Value.isFalse(arg)) {
      return Value.v(false, 'boolean')
    }
  }
  return Value.v(true, 'boolean')
}

Expression.prototype.operatorNot = function(args) {
  if (args.length != 1) {
    return Value.error('Function "not" operator must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'boolean')
  if (Value.isNullOrEmpty(arg)) {
    return Value.v(true, 'boolean')
  } else {
    return Value.v(arg.value == 'false', 'boolean')
  }
}

Expression.prototype.operatorSum = function(args) {
  const detectBasicTypes = (coll, types) => {
    for (let item of coll) {
      if (!Value.isNullOrEmpty(item)) {
        if (Value.isCollection(item)) {
          types = detectBasicTypes(Value.collectionValue(item), types)
        } else {
          types.add(item.type)
        } 
      }
    }
    return types
  }
  const containsOnlyNullOrEmpty = (coll) => {
    for (const item of coll) {
      if (Value.isNullOrEmpty(item)) {
        continue
      }
      if (item.type == 'string' && item.value === '') {
        continue
      }
      if (Value.isCollection(item) && containsOnlyNullOrEmpty(Value.collectionValue(item))) {
        continue
      }
      return false
    }
    return true
  }
  const sum = (coll, type) => {
    let result = null
    for (let item of coll) {
      if (Value.isCollection(item)) {
        let collres = sum(Value.collectionValue(item), type)
        result = result == null ? collres : result.add(collres)
      } else {
        let itemScalar = Value.castToScalar(item)
        if (!Value.isNullOrEmpty(itemScalar)) {
          if (type == 'number') {
            result = result == null ? new Decimal(itemScalar.value) : result.add(new Decimal(itemScalar.value))
          } else {
            let act = new Duration()
            act.parseIsoString(itemScalar.value)
            result = result == null ? act : result.add(act)
          }
        }
      }
    }
    return result
  }
  if (args.length == 0) {
    return Value.error('Function "sum" must have at least one argument!')
  }
  let coll = null
  if (args.length == 1) {
    coll = Value.collectionValue(Value.castToCollection(args[0]))
  } else {
    coll = []
    for (let arg of args) {
      coll.push(Value.castToScalar(arg))
    }
  }
  let types = detectBasicTypes(coll, new Set())
  types.delete('string')
  if (types.size == 0) {
    if (containsOnlyNullOrEmpty(coll)) {
      return Value.v(null)
    } else {
      return Value.error('Cannot sum string only values, unknown sum mode! Passed arguments: ' + this.printArgs(args))
    }
  }
  types.delete('integer')
  types.delete('decimal')
  const containsDuration = types.delete('duration')
  if (types.size > 0) {
    return Value.error('Cannot sum value with type(s): ' + types + '. Passed arguments: ' + this.printArgs(args))
  }
  let result = sum(coll, containsDuration ? 'duration' : 'number')
  if (result == null) {
    return Value.v(null)
  } else if (MC.isDurationObject(result)) {
    return Value.v(result.toIsoString(), 'duration')
  } else {
    return Value.v(result.toFixed(), 'decimal')
  }
}

Expression.prototype.operatorUnion = function(args) {
  let result = []
  for (let arg of args) {
    if (Value.isCollection(arg)) {
      result = result.concat(Value.collectionValue(arg))
    } else if (!Value.isNullOrEmpty(arg)) {
      result.push(arg)
    }
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorMergeCollections = function(args) {
  let result = []
  for (let arg of args) {
    if (Value.isCollection(arg)) {
      result = result.concat(Value.collectionValue(arg))
    } else {
      result.push(arg)
    }
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorContains = function(args) {
  if (args.length != 2) {
    return Value.error('Function "contains" must have two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let argument1Coll = Value.castToCollection(args[0])
  let argument2Coll = Value.castToCollection(args[1])
  let result = []
  for (let containedItem of Value.collectionValue(argument2Coll)) {
    let contains = false
    for (let item of Value.collectionValue(argument1Coll)) {
      try {
        if (this.operatorEquals([item, containedItem]).value == 'true') {
          contains = true
          break
        }
      } catch (e) {}
    }
    result.push(Value.v(contains, 'boolean'))
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorCollection = function(args) {
  let result = []
  for (let item of args) {
    result.push(item)
  }
  return Value.v(result, 'collection') 
}

Expression.prototype.operatorIsEmpty = function(args) {
  if (args.length != 1) {
    return Value.error('IsEmpty operator just works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNullOrEmpty(args[0]) || Value.isEmptyString(args[0])) {
    return Value.v(true, 'boolean')
  }
  if (Value.isCollection(args[0])) {
    for (let arg of Value.collectionValue(args[0])) {
      if (!Value.isNullOrEmpty(arg) && !Value.isEmptyString(arg)) {
        return Value.v(false, 'boolean')
      }
    }
    return Value.v(true, 'boolean')
  }
  return Value.v(false, 'boolean')
}

Expression.prototype.operatorIsNull = function(args) {
  if (args.length != 1) {
    return Value.error('IsNull operator just works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  return Value.v(Value.isNull(args[0]), 'boolean')
}

Expression.prototype.operatorFill = function(args) {
  if (args.length != 2) {
    return Value.error('Function "fill" must have exactly two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let arg2 = Value.castToScalar(args[1], 'string')
  let count = 0
  if (!Value.isNullOrEmpty(arg2)) {
    if (!MC.isNumeric(arg2.value)) {
      return Value.error('Second argument of function "fill" must be number! Passed arguments: ' + this.printArgs(args))
    }
    count = parseInt(arg2.value)
    if (count < 0) {
      count = 0
    }
  }
  if (count == 0) {
    return Value.v(null)
  }
  let coll = []
  for (let i = 0; i < count; i++) {
    coll.push(args[0])
  }
  return Value.v(coll, 'collection')
}

Expression.prototype.operatorHasData = function(args) {
  if (args.length > 1) {
    return Value.error('Function hasData must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0]) || Value.isEmptyString(args[0])) {
    return Value.v(false, 'boolean')
  } else {
    return Value.v(true, 'boolean')
  }
}

Expression.prototype.operatorHasDataN = function(args) {
  if (args.length > 1) {
    return Value.error('Function hasDataN  must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  return this.hasData(args[0]) ? Value.v(true, 'boolean') : Value.v(false, 'boolean')
}

Expression.prototype.hasData = function(value) {
  if (Value.isNullOrEmpty(value) || Value.isEmptyString(value)) {
    return false
  }
  if (Value.isCollection(value)) {
    for (let item of Value.collectionValue(value)) {
      if (this.hasData(item)) {
        return true
      }
    }
    return false
  }
  if (Value.isDataNode(value)) {
    for (let item of Object.values(value.value)) {
      if (this.hasData(item)) {
        return true
      }
    }
    return false
  }
  return true
}  

Expression.prototype.operatorExists = function(args) {
  if (args.length > 1) {
    return Value.error('Operator exists works only with one argument! ' + args.length + ' args were passed.')
  }
  if (Value.isNull(args[0])) {
    return Value.v(false, 'boolean')
  } else {
    return Value.v(true, 'boolean')
  }
}

Expression.prototype.operatorStorageProperty = function(args) {
  if (args.length != 1) {
    return Value.error('"s:property" function works only with one argument! ' + args.length + ' args were passed.')
  }
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  }
  let nsis = this.evaluateSource({source: 'env/ns*'}, false)
  let prefixed = Value.castToScalar(args[0], 'string').value
  if (prefixed.indexOf(':') > -1 && Value.isCollectionNotEmpty(nsis)) {
    let tokens = prefixed.split(':')
    for (let ns of Value.collectionValue(nsis)) {
      if (Value.getProperty(ns ,'prefix').value == tokens[0]) {
        prefixed = '{' + Value.getProperty(ns ,'uri').value + '}' + tokens[1]
        break
      }
    }
  } else if (prefixed.indexOf(':') < 0) {
    prefixed = '{}' + prefixed
  }  
  return Value.v(prefixed, 'string')
}

Expression.prototype.operatorStorageValue = function(args) {
  if (args.length != 1) {
    return Value.error('Function "s:value" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg1 = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg1)) {
    return arg1
  }
  return Value.v(encodeURIComponent(arg1.value), 'string')
}

Expression.prototype.operatorStorageAnd = function(args) {
  let parts = []
  for (let arg of args) {
    arg = Value.castToScalar(arg, 'string')
    if (!Value.isNullOrEmpty(arg)) {
      parts.push(arg.value)
    }
  }
  if (parts.length == 0) {
    return Value.v(null)
  } else if (parts.length == 1) {
    return Value.v(parts[0], 'string')
  } else {
    return Value.v('(' + parts.join(';') + ')', 'string')
  }
}

Expression.prototype.operatorStorageOr = function(args) {
  let parts = []
  for (let arg of args) {
    arg = Value.castToScalar(arg, 'string')
    if (!Value.isNullOrEmpty(arg)) {
      parts.push(arg.value)
    }
  }
  if (parts.length == 0) {
    return Value.v(null)
  } else if (parts.length == 1) {
    return Value.v(parts[0], 'string')
  } else {
    return Value.v('or(' + parts.join(';') + ')', 'string')
  }
}

Expression.prototype.operatorStorageOperator = function(operator, args) {
  if (args.length != 2) {
    return Value.error('"' + operator + '" operator works with two args! ' + args.length + ' args were passed.')
  }
  args[0] = Value.castToScalar(args[0], 'string')
  args[1] = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(args[0])) {
    return Value.error('"' + operator + '" must have first argument not empty! Passed arguments: ' + this.printArgs(args))
  }
  return  Value.v(encodeURIComponent(args[0].value) + operator + encodeURIComponent(args[1].value), 'string')
}

Expression.prototype.operatorStoragePath = function(args) {
  if (args.length < 1) {
    return Value.error('"s:path" operator must have at least one argument! ' + args.length + ' args were passed.')
  }
  let filter = ''
  let sep = ''
  for (let arg of args) {
    if (!Value.isNull(arg)) {
      filter += sep
      sep = '/'
      filter += arg.value
    }
  }
  if (filter == '') {
    return Value.v(null)
  } else {
    return Value.v(filter, 'string')
  }
}

Expression.prototype.operatorStorageTrailingPath = function(args) {
  if (args.length < 1) {
    return Value.error('"s:trailingPath" operator must have at least one argument! ' + args.length + ' args were passed.')
  }
  let filter = ''
  let sep = ''
  for (let arg of args) {
    if (!Value.isNull(arg)) {
      filter += sep
      sep = '/'
      filter += arg.value
    }
  }
  if (filter == '') {
    return Value.v(null)
  } else {
    return Value.v('*/' + filter, 'string')
  }
}

Expression.prototype.operatorSubstring = function(args) {
  if (args.length != 2 && args.length != 3) {
    return Value.error('Function "substring" works only with 2 or 3 args! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNull(args[0])) {
    return Value.v(null)
  }
  args[1] = Number(Value.castToScalar(args[1], 'int').value).valueOf()
  if (args[2]) {
    args[2] = Number(Value.castToScalar(args[2], 'int').value).valueOf()
  } else {
    args[2] = Value.v(null)
  }
  let s = args[0].value
  if (args[1] < 0) {
    args[1] = s.length + Number(args[1])
  }
  if (Value.isNull(args[2])) {
    args[2] = s.length
  } else if (args[2] < 0) {
    args[2] = s.length + Number(args[2])
  }
  if (args[1] > args[2] || args[1] > s.length) {
    return Value.v('', 'string')
  }
  return Value.v(s.substring(Number(args[1]), Number(args[2] + 1)), 'string')
}

Expression.prototype.operatorSubstring1 = function(args) {
  if (args.length != 2 && args.length != 3) {
    return Value.error('Operator "substring1" works only with 2 or 3 args! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNull(args[0])) {
    return Value.v(null)
  }
  args[1] = Number(Value.castToScalar(args[1], 'int').value).valueOf()
  if (args[2]) {
    args[2] = Number(Value.castToScalar(args[2], 'int').value).valueOf()
  } else {
    args[2] = Value.v(null)
  }
  let s = args[0].value
  if (args[1] < 0) {
    args[1] = s.length + Number(args[1]) + 1
  }
  if (Value.isNull(args[2])) {
    args[2] = s.length
  } else if (args[2] < 0) {
    args[2] = s.length + Number(args[2]) + 1
  }
  args[1] = args[1] > 0 ? args[1] - 1 : args[1];
  if (args[1] > args[2] || args[1] > s.length) {
    return Value.v('', 'string')
  }
  return Value.v(s.substring(Number(args[1]), Number(args[2]), 'string'))
}

Expression.prototype.operatorTrim = function(args) {
  if (args.length != 1) {
    return Value.error('Operator trim works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  let s = Value.castToScalar(args[0], 'string')
  if (Value.isNull(s)) {
    return Value.v(null)
  } else {
    return Value.v(s.value.trim(), 'string')
  }
}

Expression.prototype.operatorTrim0 = function(args) {
  if (args.length != 1) {
    return Value.error('Operator trim works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  let s = Value.castToScalar(args[0], 'string')
  if (Value.isNull(s)) {
    return Value.v(null)
  } else {
    return Value.v(s.value.replace(/^0+/, ''), 'string')
  }
}

Expression.prototype.operatorLength = function(args) {
  if (args.length != 1) {
    return Value.error('Operator length works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  let s = Value.castToScalar(args[0], 'string')
  if (Value.isNull(s)) {
    return Value.v(null)
  } else {
    return Value.v(s.value.length, 'int')
  }
}

Expression.prototype.operatorCurrentDate = function() {
  let mockNow = this.operatorAppCfgVal([Value.v('fl:mockNow', 'string')])
  if (!Value.isNullOrEmpty(mockNow)) {
    return Value.v(mockNow.value, 'dateTime')
  } else {
    return Value.v(DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ"), 'dateTime')
  }
}

Expression.prototype.operatorFormatDate = function(args) {
  if (args.length != 2 && args.length != 1) {
    return Value.error('Function "formatDate" works only with one or two args! Passed arguments: ' + this.printArgs(args))
  }
  let date = Value.castToScalar(args[0], 'dateTime')
  if (Value.isNullOrEmpty(date)) {
    return args[0]
  }
  let lo = MC.dateTimeStringToLuxon(date.value)
  if (!lo.v.isValid) {
    return Value.error('Function "formatDate" must have date, dateTime or time as first argument! Passed arguments: ' + this.printArgs(args))
  }
  let format = args[1] ? Value.castToScalar(args[1], 'string') : Value.v(null)
  if (Value.isNullOrEmpty(format)) {
    return Value.v(lo.v.toFormat("yyyy-MM-dd HH:mm:ss"), 'string')
  } else {
    return Value.v(MC.formatDate(date.value, format.value), 'string')
  }
}

Expression.prototype.operatorStartsWith = function(args) {
  if (args.length != 2) {
    return Value.error('Function startsWith works only with two args! Passed arguments: ' + this.printArgs(args))
  }
  let s = Value.castToScalar(args[0], 'string')
  let s1 = Value.castToScalar(args[1], 'string')
  if (Value.isNull(s)) {
    return Value.v(false, 'boolean')
  } else {
    return Value.v(s.value.startsWith(s1.value), 'boolean')
  }
}

Expression.prototype.operatorEndsWith = function(args) {
  if (args.length != 2) {
    return Value.error('Operator endsWith works only with two args! Passed arguments: ' + this.printArgs(args))
  }
  let s = Value.castToScalar(args[0], 'string')
  let s1 = Value.castToScalar(args[1], 'string')
  if (Value.isNull(s)) {
    return Value.v(false, 'boolean')
  } else {
    return Value.v(s.value.endsWith(s1.value), 'boolean')
  }
}

Expression.prototype.operatorSplit = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('"Function split must have one or two arguments"! Passed arguments: ' + this.printArgs(args))
  }
  let s = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(s)) {
    return s
  }
  let splitBy = args[1] ? Value.castToScalar(args[1], 'string') : Value.v('', 'string')
  if (Value.isNull(splitBy)) {
    return s
  }
  let res = []
  for (let r of s.value.split(new RegExp(splitBy.value))) {
    res.push(Value.v(r, 'string'))
  }
  return Value.v(res, 'collection')
}

Expression.prototype.operatorUUID = function() {
  return Value.v(MC.generateId(), 'string')
}

Expression.prototype.operatorAppCfgVal = function(args) {
  if (args.length != 1) {
    return Value.error('Function "appCfgValGet" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return Value.error('Argument of function "appCfgValGet" cannot be null or empty! Passed arguments: ' + this.printArgs(args))
  }
  let conf = this.evaluateSource({source: 'env/configuration'}, false)
  if (Value.isNull(conf)) {
    return Value.v(null)
  }
  const path = arg.value.split('/').map(t => t.indexOf(':') > 0 ? t : `cfgi:${t}`).join('/')
  return this.getData(conf, path)
}

Expression.prototype.operatorAppCfgVal2 = function(args) {
  if (args.length != 1) {
    return Value.error('Function "appCfgValGet2" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return Value.error('Argument of function "appCfgValGet2" cannot be null or empty! Passed arguments: ' + this.printArgs(args))
  }
  let conf = this.evaluateSource({source: 'env/configuration'}, false)
  if (Value.isNull(conf)) {
    return Value.v(null)
  }
  const path = arg.value.split('/').map(t => t.indexOf(':') > 0 ? t : `cfgi:${t}`).join('/')
  return this.getData(conf, path)
}

Expression.prototype.operatorJsonToData = function(args) {
  if (args.length != 1) {
    return Value.error('Function "jsonToData" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let data = JSON.parse(arg.value)
  data = MC.nullsToEmpty(data)
  return Value.v(data, 'anyType')
}

Expression.prototype.operatorLiteralToData = function(args) {
  if (args.length != 1) {
    return Value.error('Function "literalToData" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return Value.v(null)
  }
  return this.evaluateSource({source: arg.value.trim()}, false)
}

Expression.prototype.operatorDataToLiteral = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "dataToLiteral" must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let prettyPrint = args.length > 1 && Value.isFalse(Value.castToScalar(args[1], 'boolean')) ? false : true
  return Value.v(Value.toLiteral(args[0], prettyPrint), 'string')
} 

Expression.prototype.operatorDataToJson = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "dataToJson" must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isPureNull(args[0])) {
    return Value.v(null)
  }
  let prettyPrint = args.length > 1 && Value.isFalse(Value.castToScalar(args[1], 'boolean')) ? false : true
  let res = Value.toJson(args[0], true, false, true)
  return Value.v(JSON.stringify(res, null, prettyPrint ? '  ' : null), 'string')
}

Expression.prototype.operatorRound = function(args) {
  if (args.length < 1 || args.length > 3) {
    return Value.error('Function "round" must have one, two or three arguments! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'decimal')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let decimalPlaces = 0
  if (args[1]) {
    let decimalPlacesArg = Value.castToScalar(args[1], 'int')
    if (!Value.isNullOrEmpty(decimalPlacesArg)) {
      if (decimalPlacesArg.value == '0') {
        return Value.error('Precision (second argument) of funciton "round"  must not be zero if used! Passed arguments: ' + this.printArgs(args))
      } else {
        decimalPlaces = parseInt(decimalPlacesArg.value)
      }
    }
  }
  let mode
  if (args[2]) {
    mode = Value.castToScalar(args[2], 'string').value
  }
  if (mode == '0' || mode === "up") {
    if (new Decimal(arg.value).greaterThanOrEqualTo(0)) {
      mode = "ceiling"
    } else {
      mode = "floor"
    }
  }
  let result
  if (MC.isNullOrEmpty(mode) || mode == 4 || mode == "half_up") {
    mode = Decimal.ROUND_HALF_UP
  } else if (mode == 1 || mode === "down") {
    mode = Decimal.ROUND_DOWN
  } else if (mode == 2 || mode === "ceiling") {
    mode = Decimal.ROUND_CEIL
  } else if (mode == 3 || mode === "floor") {
    mode = Decimal.ROUND_FLOOR
  } else if (mode == 5 || mode === "half_down") {
    mode = Decimal.ROUND_HALF_DOWN
  } else if (mode == 6 || mode === "half_even") {
    mode = Decimal.ROUND_HALF_EVEN
  } else {
    return Value.error('Unsupported rounding type ("' + mode + '") of funciton "round" in third argument! Passed arguments: ' + this.printArgs(args))
  }
  if (decimalPlaces < 0) {
    let shift = Decimal.pow(10, -1*decimalPlaces-1)
    result = Decimal.div(arg.value, shift).toDP(0, mode)
    result = Decimal.mul(result, shift)
  } else {
    result = (new Decimal(arg.value)).toDP(decimalPlaces, mode)
  }
  return Value.v(result.toFixed(), 'decimal')
}

Expression.prototype.operatorJoin = function(args) {
  if (args.length != 2 && args.length != 1) {
    return Value.error('Operator join works only with one or two args! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNull(args[0])) {
    return Value.v(null)
  }
  let arg = Value.castToCollection(args[0])
  let separator = ''
  if (args[1]) {
    let arg1 = Value.castToScalar(args[1], 'string')
    if (!Value.isNullOrEmpty(arg1)) {
        separator = arg1.value
    }
  }
  arg = Value.v([...arg.value],'collection')
  arg = this.operatorFlatten([arg])
  let result = ''
  for (let i = 0; i < Value.collectionSize(arg); i++) {
    let item = Value.collectionItem(arg, i)
    if (!Value.isNull(item)) {
      if (i > 0) {
        result += separator
      }
      result += item.value
    }
  }
  return Value.v(result, 'string')
}

Expression.prototype.operatorToUpperCase = function(args) {
  if (args.length != 1) {
    return Value.error('Operator toUpperCase works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNull(args[0])) {
    return Value.v(null)
  } else {
    return Value.v((args[0].value).toUpperCase(), 'string')
  }
}

Expression.prototype.operatorToLowerCase = function(args) {
  if (args.length != 1) {
    return Value.error('Operator toLowerCase works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNull(args[0])) {
    return Value.v(null)
  } else {
    return Value.v((args[0].value).toLowerCase(), 'string')
  }
}

Expression.prototype.operatorEscapeHtml = function(args) {
  if (args.length != 1) {
    return Value.error('Operator escapeHtml works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  } else {
    return Value.v((args[0].value).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&apos;"), 'string')
  }
}

Expression.prototype.operatorMatches = function(args) {
  if (args.length != 2) {
    return Value.error('Operator matches works only with two args! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNull(args[0])) {
    return Value.v(false, 'boolean')
  } else {
    args[1] = Value.castToScalar(args[1], 'string')
    return Value.v((new RegExp('^' + args[1].value + '$')).test(args[0].value), 'boolean')
  }
}

Expression.prototype.operatorEncodeHex = function(args) {
  if (args.length != 1 && args.length != 2) {
    return Value.error('Operator encodeHex works only with one ore two args! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  }
  let input = args[0].value
  let bytes = MC.toUTF8Array(input)
  let out = []
  let digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
  for (var i = 0, j = 0; i < bytes.length; i++) {
    out[j++] = digits[(0xF0 & bytes[i]) >>> 4]
    out[j++] = digits[0x0F & bytes[i]]
  }
  return Value.v(out.join(''), 'string')
}

Expression.prototype.operatorDecodeHex = function(args) {
  if (args.length != 1 && args.length != 2) {
    return Value.error('Operator decodeHex works only with one or two args! Passed arguments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  }
  let data = args[0].value
  let len = data.length
  if (len % 2) {
    return Value.error('Argument of function "decodeHex" has odd number of characters!')
  }
  let digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F']
  let out = []
  for (let i = 0, j = 0; j < len; i++) {
    let f = digits.indexOf(data.charAt(j)) << 4
    j++
    f = f | digits.indexOf(data.charAt(j))
    j++
    out[i] = (f & 0xFF)
  }
  return Value.v(MC.fromUTF8Array(out), 'string')
}

Expression.prototype.operatorEncodeBase64 = function(args) {
  if (args.length != 1 && args.length != 2) {
    return Value.error('Function "encodeBase64" must have one or two arguments! Passed aruments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  }
  try {
    return Value.v(btoa(unescape(encodeURIComponent(args[0].value))), 'string')
  } catch (e) {
    return Value.v(btoa(args[0].value), 'string')
  }
}

Expression.prototype.operatorDecodeBase64 = function(args) {
  if (args.length != 1 && args.length != 2) {
    return Value.error('Function "decodeBase64" must have one or two arguments! Passed aruments: ' + this.printArgs(args))
  }
  args[0] = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  }
  try {
    return Value.v(decodeURIComponent(escape(window.atob(args[0].value))), 'string')
  } catch (e) {
    return Value.v(window.atob(args[0].value), 'string')
  }
}

Expression.prototype.operatorNullToEmpty = function(args) {
  if (args.length != 1) {
    return Value.error('Operator nullToEmpty works only with one argument! Passed aruments: ' + this.printArgs(args))
  }
  if (Value.isNull(args[0])) {
    return Value.v('', args[0].type, true)
  } else {
    return args[0]
  }
}

Expression.prototype.operatorFlatten = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "flatten" must have one or two arguments! Passed aruments: ' + this.printArgs(args))
  }
  if (args.length < 2) {
    if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
      return args[0]
    }
    return this.flattenCollection(args[0], false)
  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
    return Value.v(null)
  }
  let argument1Coll = Value.castToCollection(args[0])
  if (Value.collectionSize(argument1Coll) == 0) {
    return Value.v(null)
  }
  let argument2 = Value.castToScalar(args[1], 'integer')
  let depth = Value.isNullOrEmpty(argument2) ? 0 : parseInt(argument2.value)
  return this.flattenCollectionDepth(argument1Coll, depth)
}

Expression.prototype.flattenCollection = function(coll, withNull) {
  let result = []
  if (Value.isCollection(coll)) {
    for (let v of Value.collectionValue(coll)) {
      result = result.concat(Value.collectionValue(this.flattenCollection(v, withNull)))
    }
  } else {
    if (Value.isNull(coll)) {
      if (withNull) {
        result.push(coll)
      }
    } else {
      result.push(coll)
    }
  }
  return Value.v(result, 'collection')
}

Expression.prototype.flattenCollectionDepth = function(coll, depth) {
  if (depth == 0) {
    return coll
  }
  let flattened = []
  for (let v of Value.collectionValue(coll)) {
    if (Value.isCollection(v)) {
      flattened = flattened.concat(Value.collectionValue(this.flattenCollectionDepth(v, depth > 0 ? depth - 1 : -1)))
    } else {
      flattened.push(v)
    }
  }
  return Value.v(flattened, 'collection')
}

Expression.prototype.operatorDataToXml = function(args) {
  if (args.length < 1 || args.length > 3) {
    return Value.error('Function "dataToXml" must have one to three arguments! Passed aruments: ' + this.printArgs(args))
  }
  if (Value.isNullOrEmpty(args[0]) || !Value.isDataNode(args[0])) {
    return args[0]
  }
  let strict = false
  if (args[1] && Value.isTrue(Value.castToScalar(args[1], 'boolean'))) {
    strict = true
  }
  let pretty = false
  if (args[2] && Value.isTrue(Value.castToScalar(args[2], 'boolean'))) {
    pretty = true
  }
  let data = Value.toJson(args[0])
  data = strict && Object.getOwnPropertyNames(data).length > 1 ? {data: data} : data
  let xml = MC.objectToXML(data, 0)
  if (strict) {
    xml = '<?xml version="1.0"?>\n' + xml
  }
  if (pretty) {
    return Value.v(xml, 'string')
  } else {
    return Value.v(MC.stripWhiteSpaceInXML(xml), 'string')
  }
}

Expression.prototype.operatorXmlToData = function(args) {
  if (args.length < 1 || args.length > 3) {
    return Value.error('Function "xmlToData" works only with one to three arguments! Passed aruments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(args[0])) {
    return arg
  }
  let strict = false
  let ignoreUndefinedNamespaces = false
  if (args.length > 1) {
    if (Value.castToScalar(args[1], 'boolean').value == 'true') {
      strict = true
      if (args.length > 2) {
        if (Value.castToScalar(args[2], 'boolean').value == 'true') {
          ignoreUndefinedNamespaces = true
        }
      }
    }
  }
  return Value.fromJson(MC.xmlStringToObject(arg.value, Value.toJson(Value.getProperty(this.cData.env, 'ns')), strict, true, ignoreUndefinedNamespaces))
}

Expression.prototype.operatorStaticValue = function(args) {
  if (args.length != 2 && args.length != 3) {
    return Value.error('Function "staticValue" works only with two or three args! Passed arguments: ' + this.printArgs(args))
  }
  const valueKey = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(valueKey)) {
    return valueKey
  }
  const staticList = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(staticList)) {
    return Value.error('Second argument of "staticValue" can not be empty! Passed arguments: ' + this.printArgs(args))
  }
  let lang = Value.v(null)
  if (args[2]) {
    lang = Value.castToScalar(args[2], 'string')
  }
  let value = this.cData.svl ? Value.getProperty(this.cData.svl, staticList.value) : Value.v(null)
  if (Value.isNullOrEmpty(value)) {
    return Value.error('Static value list with name "' + staticList.value + '" not found! Passed arguments: ' + this.printArgs(args))
  }
  value = Value.castToCollection(value)
  let svl = Value.v(null)
  for (let item of Value.collectionValue(value)) {
    if (Value.getProperty(item, 'value').value == valueKey.value) {
      svl = item
      break
    }
  }
  if (!Value.isNullOrEmpty(svl)) {
    let res = Value.getProperty(svl, 'title')
    if (!Value.isNullOrEmpty(lang) && Value.hasProperty(svl, 'mut')) {
      let mut = Value.getProperty(svl, 'mut')
      if (Value.hasProperty(mut, lang.value)) {
        res = Value.getProperty(mut, lang.value)
      }
    }
    return res
  }
  return Value.v(null)
}

Expression.prototype.operatorStaticValues = function(args) {
  if (args.length != 1 && args.length != 2) {
    return Value.error('Operator "staticValues" works only with one or two args! Passed arguments: ' + this.printArgs(args))
  }
  const staticList = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(staticList)) {
    return Value.error('First argument of "staticValues" can not be empty! Passed arguments: ' + this.printArgs(args))
  }
  let value = this.cData.svl ? Value.getProperty(this.cData.svl, staticList.value) : Value.v(null)
  if (Value.isNullOrEmpty(value)) {
    return Value.v(null)
  }
  value = Value.castToCollection(value)
  let lang = Value.v(null)
  if (args[1]) {
    lang = Value.castToScalar(args[1], 'string')
  }
  let result = []
  for (let svl of Value.collectionValue(value)) {
    if (!Value.isNullOrEmpty(svl)) {
      let res = Value.v('', 'string', true)
      if (Value.hasProperty(svl, 'title')) {
        let title = Value.getProperty(svl, 'title')
        if (!Value.isNullOrEmpty(title)) {
          res = title
        }
      }
      if (!Value.isNullOrEmpty(lang) && Value.hasProperty(svl, 'mut')) {
        let mut = Value.getProperty(svl, 'mut')
        if (Value.hasProperty(mut, lang.value)) {
          res = Value.getProperty(mut, lang.value)
        }
      }
      result.push(res)
    }
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorStaticValueKeys = function(args) {
  if (args.length != 1) {
    return Value.error('Operator "staticValueKeys" works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  const staticList = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(staticList)) {
    return Value.error('First argument of "staticValueKeys" can not be empty! Passed arguments: ' + this.printArgs(args))
  }
  let value = this.cData.svl ? Value.getProperty(this.cData.svl, staticList.value) : Value.v(null)
  if (Value.isNullOrEmpty(value)) {
    return Value.v(null)
  }
  value = Value.castToCollection(value)
  let result = []
  for (let svl of Value.collectionValue(value)) {
    result.push(Value.getProperty(svl, 'value'))
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorFromMilliseconds = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "fromMilliseconds" must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'int')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let zone = 'local'
  if (args[1] && !Value.isNullOrEmpty(args[1])) {
    zone = args[1].value == 'Z' ? 'utc' : args[1].value
  } else {
    let timezone = this.operatorAppCfgVal([Value.v('fl:localTimezoneId', 'string')])
    if (!Value.isNullOrEmpty(timezone)) {
      zone = timezone.value
    } 
  }
  let lux = DateTime.fromMillis(Number(arg.value), {zone: zone})
  if (lux.isValid) {
    return Value.v(MC.luxonToDateTimeString({v: lux}, 'dateTime', true), 'dateTime')
  } else {
    return Value.error('Arguments of "fromMilliseconds" must be valid unix milliseconds number and optional valid timezone! Passed arguments: ' + this.printArgs(args))
  }
}

Expression.prototype.operatorToMilliseconds = function(args) {
  if (args.length != 1) {
    return Value.error('Function "toMilliseconds" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let lux = MC.dateTimeStringToLuxon(arg.value)
  if (lux.v.isValid) {
    if (!MC.hasTimezone(arg.value)) {
      return Value.error('Argument of function "toMilliseconds" value must have timezone! Passed arguments: ' + this.printArgs(args))
    }
    return Value.v(lux.v.toMillis(), 'int')
  } else {
    return Value.error('Argument of function "toMilliseconds" must be valid date time! Passed arguments: ' + this.printArgs(args))
  }
}

Expression.prototype.operatorTry = function(exprs) {
  if (exprs.length > 0) {
    for (let i=0; i<exprs.length; i++) {
      try {
        const result = this.evaluateLazy(exprs[i]) 
        if (!Value.isError(result)) {
          return result
        }
      } catch (e) {
        this.pushSubTrace("EVAL ERROR!")
      }
    }
    return Value.v(null)
  } else {
    return Value.error('Function "try" must have at least one argument!')
  }
}

Expression.prototype.operatorToDate = function(args) {
  if (args.length != 1) {
    return Value.error('Function "toDate" works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let lux = MC.dateTimeStringToLuxon(arg.value)
  if (lux.v.isValid) {
    return Value.v(lux.v.toFormat('yyyy-MM-dd'), 'date')
  } else {
    return Value.error('Argument of "toDate" must be valid date time! Passed: ' + arg.value)
  }
}

Expression.prototype.operatorToTime = function(args) {
  if (args.length != 1) {
    return Value.error('Function "toTime" works only with one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let lux = MC.dateTimeStringToLuxon(arg.value)
  if (lux.v.isValid) {
    return Value.v(MC.luxonToDateTimeString(lux, 'time'), 'time')
  } else {
    return Value.error('Argument of "toTime" must be valid date time! Passed: ' + arg.value)
  }
}

Expression.prototype.operatorAddDuration = function(args) {
  if (args.length < 2) {
    return Value.error('Function "addDuration" must have at least two args! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let result = MC.dateTimeStringToLuxon(arg.value)
  if (!result.v.isValid) {
    return Value.error('Operator "addDuration" must have date, dateTime or time as first argument! Passed arguments: ' + this.printArgs(args))
  }
  for (let i=1; i<args.length; i++) {
    arg = Value.castToScalar(args[i], 'duration')
    if (!Value.isNullOrEmpty(arg)) {
      let act = new Duration()
      act.parseIsoString(arg.value)
      if (!act.isValidDuration()) {
        return Value.error('Operator "addDuration" works only with durations from second argument! Passed arguments: ' + this.printArgs(args))
      }
      MC.luxonAdd(result, act)
    }
  }
  return Value.v(MC.luxonToDateTimeString(result, 'dateTime'), 'dateTime')
}

Expression.prototype.operatorDataNode = function(args) {
  let result = {}
  for (let i=0; i<args.length; i += 2) {
    let key = Value.castToScalar(args[i], 'string')
    if (Value.isNullOrEmpty(key)) {
      return Value.error('Complex value property name in function "dataNode" cannot be null or empty! Passed arguments: ' + this.printArgs(args))
    }
    key = key.value
    if (key.endsWith('*')) {
      key = key.substring(0, key.length-1)
    }
    let value = i + 1 < args.length ? args[i+1] : Value.v(null)
    result[key] = value
  }
  return Value.dataNode(result)
}

Expression.prototype.operatorDurationBetween = function(args) {
  if (args.length!= 2 && args.length!= 3) {
    return Value.error('Function "durationBetween" must have two or three args! Passed arguments: ' + this.printArgs(args))
  }
  let date1 = MC.dateTimeStringToLuxon(args[0].value)
  if (!date1.v.isValid) {
    return Value.error('Function "addDuration" must have valid dateTime as first argument! Passed arguments: ' + this.printArgs(args))
  }
  let date2 = MC.dateTimeStringToLuxon(args[1].value)
  if (!date2.v.isValid) {
    return Value.error('Function "addDuration" must have valid dateTime as second argument! Passed arguments: ' + this.printArgs(args))
  }
  if (MC.objectHasTimezone(date1) != MC.objectHasTimezone(date2)) {
    return Value.error('Either both of the args must have timezone or none of them!')
  }
  if (args.length == 3) {
    let unit = Value.castToScalar(args[2], 'string')
    if (!Value.isNullOrEmpty(unit)) {
      const units = ["y", "M", "d", "H", "m", "s", "S"]
      if (units.indexOf(unit.value) == -1) {
        return Value.error('Unknown duration unit ' + args[1] + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units))
      }
      let result = new Duration()
      switch (unit.value) {
        case 'y': result.from(Math.floor(date2.v.diff(date1.v, 'years').toObject().years), 0, 0, 0, 0, 0, 0); break
        case 'M': result.from(0, Math.floor(date2.v.diff(date1.v, 'months').toObject().months), 0, 0, 0, 0, 0); break
        case 'd': result.from(0, 0, Math.floor(date2.v.diff(date1.v, 'days').toObject().days), 0, 0, 0, 0); break
        case 'H': result.from(0, 0, 0, Math.floor(date2.v.diff(date1.v, 'hours').toObject().hours), 0, 0, 0); break
        case 'm': result.from(0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'minutes').toObject().minutes), 0, 0); break
        case 's': result.from(0, 0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'seconds').toObject().seconds), 0); break
        case 'S': result.from(0, 0, 0, 0, 0, 0, Math.floor(date2.v.diff(date1.v, 'milliseconds').toObject().milliseconds)); break
      }
      return Value.v(result.toIsoString(), 'duration')
    }
  } else {
    let duration = MC.durationBetween(date1, date2)
    return Value.v(duration.toIsoString(),  'duration')
  }
}

Expression.prototype.operatorToTimezone = function(args) {
  if (args.length != 1 && args.length != 2) {
    return Value.error('Operator "toTimezone" must have one or two args! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  if (arg.value.match(/^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?)?)?)?$/i)) {
    return Value.error('Cannot convert value to different timezone, value ' + arg.value + ' is in no timezone!')
  }
  let arg1 = args[1] ? Value.castToScalar(args[1], 'string') : Value.v(null)
  let zone = 'local'
  if (!Value.isNullOrEmpty(arg1)) {
    zone = arg1.value == 'Z' ? 'utc' : arg1.value
  } else {
    let timezone = this.operatorAppCfgVal([Value.v('fl:localTimezoneId', 'string')])
    if (!Value.isNullOrEmpty(timezone)) {
      zone = timezone.value
    } 
  }
  let another = MC.dateTimeStringToLuxon(arg.value)
  another.v = another.v.setZone(zone)
  return Value.v(MC.luxonToDateTimeString(another), MC.getDateTimeType(another))  
}

Expression.prototype.operatorTimezone = function(args) {
  if (args.length > 1) {
    return Value.error('Function "timezone" must have no or one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (args.length == 0) {
    let mockNow = this.operatorAppCfgVal([Value.v('fl:mockNow', 'string')])
    if (Value.isNullOrEmpty(mockNow)) {
      return Value.v(DateTime.local().toFormat('ZZ'), 'string')
    } else {
      return Value.v(DateTime.fromISO(mockNow.value).toFormat('ZZ'), 'string')
    }
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return Value.error('Argument of function "timezone" can not be empty ot null! Passed arguments: ' + this.printArgs(args))
  }
  let lux = MC.dateTimeStringToLuxon(arg.value)
  if (!lux.v.isValid) {
    return Value.error('Argument of function "timezone" must be valid dateTime, date or time string! Passed arguments: ' + this.printArgs(args))
  }
  let match = (arg.value).match(/^([\d-:\.T]*)(([+-]\d\d:\d\d)|Z)$/i)
  if (match) {
    return Value.v(match[2], 'string')
  } else {
    return Value.v(null)
  } 
}

Expression.prototype.operatorParseDate = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "parseDate" must have one or two args! Passed arguments: ' + this.printArgs(args))
  }
  let value = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(value)) {
    return value
  }
  value = value.value
  let formatString = Value.castToScalar(args[1], 'string').value
  if (formatString.indexOf('y') == -1) {
    value = '0000-' + value
    formatString = 'yyyy-' + formatString
  }
  let lux = {v: DateTime.fromFormat(value, JdateFormat.toLuxonFormatString(formatString), {setZone: true}), _i: value}
  if (!lux.v.isValid) {
    return Value.error(`Cannot parse dateTime with function "parseDate" from string "${args[0]}" with pattern "${args[1]}"!`)
  } else {
    return Value.v(MC.luxonToDateTimeString(lux, 'dateTime'), 'dateTime')
  }
}

Expression.prototype.operatorParseUri = function(args) {
  if (args.length !== 1) {
    return Value.error('Function "parseUri" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let value = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(value)) {
    return value
  }
  const str = value.value
  let o = {
    key: ["source","scheme","authority","userInfo","user","password","host","port","relative","pathString","directory","file","queryString","fragment"],
    parser: {
      strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
      loose:  /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
    }
  }
  let m = o.parser["strict"].exec(str)
  let uri = {}
  let i = 14
  while (i--) uri[o.key[i]] = m[i] || null
  let queryObj = {}
  const queryString = uri[o.key[12]]
  if (queryString != null) {
    queryString.replace(/(?:^|&)([^&=]*)=?([^&]*)/g, function (g0, g1, g2) {
      g2 = decodeURIComponent(g2)
      if (g1) {
        if (Object.hasOwn(queryObj, g1)) {
          if (Array.isArray(queryObj[g1])) {
            queryObj[g1].push(g2)
          } else {
            queryObj[g1] = [queryObj[g1], g2]
          }
        } else {
          queryObj[g1] = g2
        }
      } 
    })
  }
  if (!MC.isEmptyObject(queryObj)) {
    let queryArr = []
    for (let key in queryObj) {
      queryArr.push({name: key, value: queryObj[key]})
    }   
    queryArr.sort(function(a,b) {
      return a.name.localeCompare(b.name)
    })
    uri.query = {}
    for (let param of queryArr) {
      uri.query[param.name] = param.value
    }  
  }
  uri.trailingSlash = uri.pathString !== null && uri.pathString.endsWith('/')
  if (uri.pathString) {
    uri['path'] = []
    for (let segment of uri.pathString.split('/')) {
      if (MC.isNull(segment) || segment == '') continue
      let path = {}
      if (segment.indexOf(';')) {
        path.name = segment.split(';')[0]
        for (let matrix of segment.split(';')) {
          if (matrix.indexOf('=') > 0) {
            if (!path.parameters) path.parameters = {}
            path.parameters[matrix.split('=')[0]] = matrix.split('=')[1]       
          }
        }
      } else {
        path.name = segment
      }
      uri['path'].push(path)
    }
  }
  if (uri['fragment']) {
    uri['fragment'] = decodeURIComponent(uri['fragment'])
  }
  return Value.v(uri, 'anyType')
}

Expression.prototype.operatorBuildUri = function(args) {
  if (args.length !== 1) {
    return Value.error('Function "buildUri" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  }
  if (!Value.isDataNode(args[0])) {
    return Value.error('Argument of function "buildUri" must be a data node! Passed arguments: ' + this.printArgs(args))
  }
  let components = Value.toJson(args[0])
  let path = ""
  if (!MC.isNull(components.path)) {
    for (let pathSegment of MC.asArray(components.path)) {
      if (pathSegment.name) {
        path = path + (path ? "/" : "") + encodeURIComponent(pathSegment.name)
      }
      if (!MC.isNull(pathSegment.parameters) && MC.isPlainObject(pathSegment.parameters)) {
        for (let matrix in pathSegment.parameters) {
          for (let val of MC.asArray(pathSegment.parameters[matrix])) {
            path = path + ";" + encodeURIComponent(matrix) + "=" + encodeURIComponent(val)
          }
        }  
      }
    }
    if (components.trailingSlash) {
      path = path + "/"
    }
  }
  let query = ""
  if (!MC.isNull(components.query) && MC.isPlainObject(components.query)) {
    for (let par in components.query) {
      for (let val of MC.asArray(components.query[par])) {
        if (!MC.isNull(val)) {
          query = query + (query ? "&" : "?") + encodeURIComponent(par) + "=" + encodeURIComponent(val)
        }
      }
    }
  }
  let res = ""
  if (components.scheme) {
    res = res + components.scheme + "://"
  }
  if (components.userInfo) {
    res = res + components.userInfo + "@"
  }
  if (components.host) {
    res = res + components.host
  }
  if (components.port) {
    res = res + ":" + components.port
  }
  if (res && (path || query || components.fragment)) {
    res = res + "/"
  }
  if (path) {
    res = res + path
  }
  if (query) {
    res = res + query
  }
  if (components.fragment) {
    res = res + "#" + encodeURIComponent(components.fragment)
  }
  return Value.v(res, 'string')
}

Expression.prototype.operatorToDateTime = function(args) {
  if (args.length != 2) {
    return Value.error('Function "toDateTime" must have must have exactly two args! Passed arguments: ' + this.printArgs(args))
  }
  let date = Value.castToScalar(args[0], 'date')
  if (Value.isNullOrEmpty(date)) {
    date = '0000-01-01'
  } else {
    date = date.value
  }
  let time = Value.castToScalar(args[1], 'time')
  if (Value.isNullOrEmpty(time)) {
    time = '00:00:00'
  } else {
    time = time.value
  }
  let tz1 = this.operatorTimezone([Value.v(date)]).value
  let tz2 = this.operatorTimezone([Value.v(time)]).value
  if (tz1 && tz2 && tz1 != tz2) {
    return Value.error('Date and time args of function "toDateTime" are in different timezones! Passed arguments: ' + this.printArgs(args))
  }
  let zoneOut = ''
  if (tz1) {
    zoneOut = tz1
  } else if (tz2) {
    zoneOut = tz2
  }
  date = this.operatorRemoveTimezone([Value.v(date)]).value
  time = this.operatorRemoveTimezone([Value.v(time)]).value
  return Value.v(date + 'T' + time + zoneOut, 'dateTime')
}

Expression.prototype.operatorSetTimezone = function(args) {
  if (args.length < 1 || args.length > 3) {
    return Value.error('Function "setTimezone" must have one to three arguments! Passed arguments: ' + this.printArgs(args))
  }
  if (args[2] && Value.isTrue(Value.castToScalar(args[2], 'boolean'))) {
    return Value.error('Strict mode in function "setTimezone" is not supported! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let zone = 'local'
  if (args[1] && !Value.isNullOrEmpty(args[1])) {
    zone = args[1].value == 'Z' ? 'utc' : args[1].value
  } else {
    let timezone = this.operatorAppCfgVal([Value.v('fl:localTimezoneId', 'string')])
    if (!Value.isNullOrEmpty(timezone)) {
      zone = timezone.value
    } 
  }
  let another = MC.dateTimeStringToLuxon((arg.value).replace(/(([+-]\d\d:\d\d)|Z)/, ''), true)
  another.v = another.v.setZone(zone, {keepLocalTime: true})
  return Value.v(MC.luxonToDateTimeString(another, null, true), 'dateTime')
}

Expression.prototype.operatorRemoveTimezone = function(args) {
  if (args.length != 1) {
    return Value.error('Function "removeTimezone" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  return Value.v(arg.value.replace(/(([+-]\d\d:\d\d)|Z)/, ''))
}

Expression.prototype.operatorNormalizeDuration = function(args) {
  if (args.length < 2 || args.length > 4) {
    return Value.error('Function "normalizeDuration" must have two, three or four args! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'duration')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let date = Value.castToScalar(args[1], 'dateTime')
  if (Value.isNullOrEmpty(date)) {
    return Value.error('Second argument of function "normalizeDuration" cannot be null or empty! Passed arguments: ' + this.printArgs(args));
  }
  let atStart = true
  if (args.length > 2 && Value.isFalse(Value.castToScalar(args[2], 'boolean'))) {
    atStart = false
  }
  let duration = new Duration()
  duration.parseIsoString(arg.value)
  if (!atStart) {
    duration.negate()
  }
  let other = MC.dateTimeStringToLuxon(date.value)
  date = MC.dateTimeStringToLuxon(date.value)
  MC.luxonAdd(other, duration)
  if (args.length > 3) {
    let unit = Value.castToScalar(args[3], 'string').value
    const units = ["y", "M", "d", "H", "m", "s", "S"]
    if (units.indexOf(unit) == -1) {
      return Value.error('Unknown duration unit ' + unit + ' as fourth argument of function "normalizeDuration"! Available units are: ' + JSON.stringify(units))
    }
    var result = new Duration()
    switch (unit) {
      case 'y': result.from(Math.trunc(other.v.diff(date.v, 'years').toObject().years), 0, 0, 0, 0, 0, 0); break
      case 'M': result.from(0, Math.trunc(other.v.diff(date.v, 'months').toObject().months), 0, 0, 0, 0, 0); break
      case 'd': result.from(0, 0, Math.trunc(other.v.diff(date.v, 'days').toObject().days), 0, 0, 0, 0); break
      case 'H': result.from(0, 0, 0, Math.trunc(other.v.diff(date.v, 'hours').toObject().hours), 0, 0, 0); break
      case 'm': result.from(0, 0, 0, 0, Math.trunc(other.v.diff(date.v, 'minutes').toObject().minutes), 0, 0); break
      case 's': result.from(0, 0, 0, 0, 0, Math.trunc(other.v.diff(date.v, 'seconds').toObject().seconds), 0); break
      case 'S': result.from(0, 0, 0, 0, 0, 0, Math.trunc(other.v.diff(date.v, 'milliseconds').toObject().milliseconds)); break
    }
    return Value.v(result.toIsoString(), 'duration')
  } else {
    let result = MC.durationBetween(date, other)
    if (!atStart) {
      result.negate()
    }
    return Value.v(result.toIsoString(), 'duration')
  }
}

Expression.prototype.operatorFillTimezone = function(args) {
  if (args.length < 1 || args.length > 3) {
    return Value.error('Function "fillTimezone" must have one to three arguments! Passed arguments: ' + this.printArgs(args))
  }
  if (args[2] && Value.isTrue(Value.castToScalar(args[2], 'boolean'))) {
    return Value.error('Strict mode in function "fillTimezone" is not supported! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'dateTime')
  if (Value.isNullOrEmpty(arg) || MC.hasTimezone(arg.value)) {
    return arg
  }
  let zone = 'local'
  if (args[1] && !Value.isNullOrEmpty(args[1])) {
    zone = args[1].value == 'Z' ? 'utc' : args[1].value
  } else {
    let timezone = this.operatorAppCfgVal([Value.v('fl:localTimezoneId', 'string')])
    if (!Value.isNullOrEmpty(timezone)) {
      zone = timezone.value
    } 
  }
  let another = MC.dateTimeStringToLuxon(arg.value)
  another.v = another.v.setZone(zone, {keepLocalTime: true})
  return Value.v(MC.luxonToDateTimeString(another, null, true), 'dateTime')
}

Expression.prototype.operatorDurationComponent = function(args) {
  if (args.length != 2) {
    return Value.error('Function "durationComponent" must have exactly two args! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'duration')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  let duration = new Duration()
  duration.parseIsoString(arg.value)
  let unit = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(unit)) {
    return Value.error('Second argument of function "durationComponent" can not be null or empty! Passed arguments: ' + this.printArgs(args))
  }
  unit = unit.value
  var units = ["y", "M", "d", "H", "m", "s", "S"]
  if (units.indexOf(unit) == -1) {
    return Value.error('Unknown duration unit ' + unit + ' as second argument of function "durationComponent"! Available units are: ' + JSON.stringify(units))
  }
  switch (unit) {
    case 'y': return Value.v(duration.getYears(), 'int')
    case 'M': return Value.v(duration.getMonths(), 'int')
    case 'd': return Value.v(duration.getDays(), 'int')
    case 'H': return Value.v(duration.getHours(), 'int')
    case 'm': return Value.v(duration.getMinutes(), 'int')
    case 's': return Value.v(duration.getSeconds(), 'int')
    case 'S': return Value.v(duration.getMilliseconds(), 'int')
  }
}

Expression.prototype.operatorEvery = function(exprs) {
  if (exprs.length != 2) {
    return Value.error('Function "every" must have exactly two args! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  if (Value.collectionSize(arg1Coll) == 0) {
    return Value.v(true, 'boolean')
  }
  let base = this.enterBaseContext()
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    this.setPosition(i, Value.collectionItem(arg1Coll ,i))
    let value = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1), 'boolean')
    if (Value.isError(value)) {
      return value
    }
    this.unsetPosition()
    if (Value.isNullOrEmpty(value) || Value.isFalse(value)) {
      this.leaveBaseContext(base)
      return Value.v(false, 'boolean')
    }
  }
  this.leaveBaseContext(base)
  return Value.v(true, 'boolean')
}

Expression.prototype.operatorGroup = function(exprs) {
  if (exprs.length < 2 || exprs.length > 3) {
    return Value.error('Function "group" must have two or thre arguments! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  if (Value.collectionSize(arg1Coll) == 0) {
    return Value.v(null)
  }
  let groups = new Map()
  let base = this.enterBaseContext()
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    let item = Value.collectionItem(arg1Coll, i)
    this.setPosition(i, item)
    let groupingKey = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1), 'string')
    if (Value.isError(groupingKey)) {
      return groupingKey
    }
    let group = groups.get(groupingKey.value)
    if (!group) {
      group = {type: 'collection', value: []}
      groups.set(groupingKey.value, group)
    }
    group.value.push(item)
    this.unsetPosition()
  }
  this.leaveBaseContext(base)
  let result = []
  if (exprs.length == 2) {
    for (let val of groups.values()) {
      result.push(val)
    }
  } else {
    let filterAndOrder = this.evaluateLazy(exprs[2])
    if (Value.isError(filterAndOrder)) {
      return filterAndOrder
    }
    if (Value.isNullOrEmpty(filterAndOrder)) {
      return filterAndOrder
    }
    for (let filterItem of Value.collectionValue(Value.castToCollection(filterAndOrder))) {
      let filterKey = Value.castToScalar(filterItem, 'string').value
      let group = groups.get(filterKey)
      result.push(MC.isNull(group) || Value.isNull(group) ? Value.v(null) : group)
    }
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorSome = function(exprs) {
  if (exprs.length < 1 || exprs.length > 2) {
    return Value.error('Function "some" must have one or two arguments! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (exprs.length == 1) {
    arg1Coll = this.flattenCollection(Value.castToCollection(arg1Coll))
    for (let item of Value.collectionValue(arg1Coll)) {
      if (Value.isTrue(item)) {
        return Value.v(true, 'true')
      }
    }
    return Value.v(false, 'boolean')
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  if (Value.collectionSize(arg1Coll) == 0) {
    return Value.v(false, 'boolean')
  }
  let base = this.enterBaseContext()
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    this.setPosition(i, Value.collectionItem(arg1Coll, i))
    let value = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1), 'boolean')
    if (Value.isError(value)) {
      return value
    }
    this.unsetPosition()
    if (Value.isNullOrEmpty(value)) {
      continue
    }
    if (Value.isTrue(value)) {
      this.leaveBaseContext(base)
      return Value.v(true, 'boolean')
    }
  }
  this.leaveBaseContext(base)
  return Value.v(false, 'boolean')
}

Expression.prototype.operatorMap = function(exprs) {
  if (exprs.length != 2) {
    return Value.error('Function "map" must have exactly two args! ' + exprs.length + ' args were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  let base = this.enterBaseContext()
  let result = []
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    this.setPosition(i, Value.collectionItem(arg1Coll, i))
    let item = this.evaluateLazy(exprs[1], null, null, null, 1)
    if (Value.isError(item)) {
      return item
    }
    result.push(item)
    this.unsetPosition()
  }
  this.leaveBaseContext(base)
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorReduce = function(exprs) {
  if (exprs.length < 2 || exprs.length > 3) {
    return Value.error('Function "reduce" must have two or three arguments! ' + exprs.length + ' arguments were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNull(arg1Coll) && !Value.isCollection(arg1Coll)) {
    return Value.v(null)
  }
  if (Value.isEmpty(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isCollection(arg1Coll) && Value.collectionSize(arg1Coll) == 0) {
    return Value.v(null)
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  let result = Value.v(null)
  if (exprs.length == 3) {
    result = this.evaluateLazy(exprs[2], null, null, null, 2)
    if (Value.isError(result)) {
      return result
    }
  }
  let base = this.enterBaseContext()
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    this.setPosition(i, Value.collectionItem(arg1Coll, i))
    this.pushResultValue(result)
    result = this.evaluateLazy(exprs[1], null, null, null, 1)
    if (Value.isError(result)) {
      return result
    }
    this.unsetPosition()
    this.popResultValue()
  }
  this.leaveBaseContext(base)
  return result
}

Expression.prototype.operatorFor = function(exprs) {
  if (exprs.length < 3 || exprs.length > 4) {
    return Value.error('Function "for" must have must have three or four arguments! ' + exprs.length + ' arguments were passed.')
  }
  let i = 0
  let iterationsLimit = 1_000_000
  if (exprs.length > 3) {
    let iterationsLimitValue = this.evaluateLazy(exprs[3], null, null, null, 3)
    if (Value.isError(iterationsLimitValue)) {
      return iterationsLimitValue
    }
    iterationsLimitValue = Value.castToScalar(iterationsLimitValue, 'integer')
    if (!Value.isNullOrEmpty(iterationsLimitValue)) {
      iterationsLimit = parseInt(terationsLimitValue.value)
    }
  }
  let currentState = this.evaluateLazy(exprs[0], null, null, null, 0)
  if (Value.isError(currentState)) {
    return currentState
  }
  let base = this.enterBaseContext()
  try {
    while (true) {
      if (iterationsLimit > 0 && i == iterationsLimit) {
        return Value.error('Maximum number (' + iterationsLimit + ') of iterations cycles in function "for" reached!')
      }
      try {
        this.setPosition(i, currentState)
        this.pushResultValue(currentState)
        let breakConditionResult = this.evaluateLazy(exprs[1], null, null, null, 1)
        if (Value.isError(breakConditionResult)) {
          return breakConditionResult
        }
        breakConditionResult = Value.castToScalar(breakConditionResult, 'boolean')
        if (Value.isNullOrEmpty(breakConditionResult)) {
          return Value.error('Break condition of "for" function must return boolean value (null or empty produced instead)!')
        }
        if (Value.isTrue(breakConditionResult)) {
          break
        }
        currentState = this.evaluateLazy(exprs[2], null, null, null, 2)
        if (Value.isError(currentState)) {
          return currentState
        }
      } finally {
        this.unsetPosition()
        this.popResultValue()
      }
      i++
    }
  } finally {
    this.leaveBaseContext(base)
  }
  return currentState
}

Expression.prototype.treeReduceApply = function(childExpressionDef, reduceExpressionDef) {
  let childrenColl = this.evaluateLazy(childExpressionDef, null, null, null, 1)
  if (Value.isError(childrenColl)) {
    return childrenColl
  }
  childrenColl = Value.castToCollection(childrenColl)
  let childrenResults = Value.v(null)
  if (!Value.isNullOrEmpty(childrenColl) && Value.isCollectionNotEmpty(childrenColl)) {
    let base = this.enterBaseContext()
    childrenResults = []
    for (let i = 0; i < Value.collectionSize(childrenColl); i++) {
      let item = Value.collectionItem(childrenColl, i)
      this.setPosition(i, item)
      this.pushResultValue(Value.v(null))
      let ritem = this.treeReduceApply(childExpressionDef, reduceExpressionDef)
      if (Value.isError(ritem)) {
        return ritem
      }
      childrenResults.push(ritem)
      this.unsetPosition()
      this.popResultValue()
    }
    this.leaveBaseContext(base)
  }
  this.pushResultValue(Value.v(childrenResults, 'collection'))
  let result = this.evaluateLazy(reduceExpressionDef, null, null, null, 2)
  this.popResultValue()
  return result
}

Expression.prototype.operatorTreeReduce = function(exprs) {
  if (exprs.length != 3) {
    return Value.error('Function "treeReduce" must have three arguments! ' + exprs.length + ' arguments were passed.')
  }
  let result = null
  let initialNode = this.evaluateLazy(exprs[0])
  if (Value.isError(initialNode)) {
    return initialNode
  }
  let base = this.enterBaseContext()
  this.setPosition(0, initialNode)
  this.pushResultValue(Value.v(null))
  result = this.treeReduceApply(exprs[1], exprs[2])
  this.unsetPosition()
  this.popResultValue()
  this.leaveBaseContext(base)
  return result
}

Expression.prototype.operatorTableLookup = function(args) {
  if (args.length < 2 || args.length > 4) {
    return Value.error('Function "tableLookup" must have two, three or four arguments! Passed arguments: ' + this.printArgs(args))
  }
  let value = Value.castToScalar(args[0], 'string')
  let tableName = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(tableName)) {
    return Value.error('Second argument of function "tableLookup" cannot be null or empty! Passed arguments: ' + this.printArgs(args))
  }
  tableName = tableName.value
  let vmts = this.cData.vmt && Value.hasProperty(this.cData.vmt, tableName) ? Value.getProperty(this.cData.vmt, tableName) : Value.v(null)
  if (Value.isNullOrEmpty(vmts)) {
    return Value.error('Value mapping table ' + tableName + ' does not exist in component!')
  }
  let mandatory = false
  if (args[2] && Value.isTrue(Value.castToScalar(args[2], 'boolean'))) {
    mandatory = true
  }
  let echo = false
  if (args[3] && Value.isTrue(Value.castToScalar(args[3], 'boolean'))) {
    echo = true
  }
  let result = Value.getProperty(vmts, value.value)
  if (Value.isNull(result)) {
    if (mandatory) {
      return Value.error('Function "tableLookup": Value ' + value.value + ' not found in table ' + tableName + ' input values! Passed arguments: ' + this.printArgs(args))
    }
    return echo ? value : Value.v(null)
  } else {
    let evaluated = this.evaluateSource({source: result.value}, false)
    if (Value.isNull(evaluated) && result.value !== 'null') {
      return result
    } else {
      return evaluated
    }
  }
}

Expression.prototype.operatorTableLookupPrefix = function(args) {
  if (args.length != 2) {
    return Value.error('Function "tableLookupPrefix" must have two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let inputPrefixString = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(inputPrefixString)) {
    inputPrefixString = Value.v("")
  }
  let tableName = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(tableName)) {
    return Value.error('Second argument of function "tableLookupPrefix" cannot be null or empty! Passed arguments: ' + this.printArgs(args))
  }
  tableName = tableName.value
  let vmts = this.cData.vmt && Value.hasProperty(this.cData.vmt, tableName) ? Value.getProperty(this.cData.vmt, tableName) : Value.v(null)
  if (Value.isNullOrEmpty(vmts)) {
    return Value.error('Value mapping table ' + tableName + ' does not exist in component!')
  }
  let output = []
  for (let key in vmts.value) {
    let evaluatedKey = this.evaluateSource({source: key}, false)
    let keyToComapre = evaluatedKey
    if (Value.isNullOrEmpty(evaluatedKey)) {
      keyToComapre = Value.v("")
    }
    if (keyToComapre.value.startsWith(inputPrefixString.value)) {
      let evaluated = this.evaluateSource({source: Value.getProperty(vmts, key).value}, false)
      output.push(Value.dataNode({'input': evaluatedKey, 'output': evaluated}))
    }
  }
  return output.length > 0 ? Value.v(output, 'collection') : Value.v(null)
}

Expression.prototype.operatorDistinct = function(exprs) {
  if (exprs.length < 1 || exprs.length > 2) {
    return Value.error('Function "distinct" must have one or two arguments! ' + exprs.length + ' arguments were passed.')
  }
  let arg1Coll = this.evaluateLazy(exprs[0])
  if (Value.isError(arg1Coll)) {
    return arg1Coll
  }
  if (Value.isNullOrEmpty(arg1Coll)) {
    return arg1Coll
  }
  arg1Coll = Value.castToCollection(arg1Coll)
  let uniquenessKeys = arg1Coll
  if (exprs.length == 2) {
    let base = this.enterBaseContext()
    uniquenessKeys = []
    for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
      this.setPosition(i, Value.collectionItem(arg1Coll, i))
      let item = this.evaluateLazy(exprs[1], null, null, null, 1)
      if (Value.isError(item)) {
        return item
      }
      uniquenessKeys.push(item)
      this.unsetPosition()
    }
    uniquenessKeys = Value.v(uniquenessKeys, 'collection')
    this.leaveBaseContext(base)
  }
  let result = []
  main:
  for (let i = 0; i < Value.collectionSize(arg1Coll); i++) {
    for (let j = 0; j < i; j++) {
      if (Value.collectionItem(uniquenessKeys, i).value == Value.collectionItem(uniquenessKeys, j).value) {
        continue main
      }
    }
    result.push(Value.collectionItem(arg1Coll, i))
  }
  return Value.v(result, 'collection')
}

Expression.prototype.operatorAbs = function(args) {
  if (args.length != 1) {
    return Value.error('Function "abs" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], args[0].type == 'duration' ? 'duration' : 'decimal')
  if (Value.isNullOrEmpty(arg)) {
    return arg
  }
  if (Value.isNumber(arg)) {
    return Value.v((new Decimal(arg.value)).abs().toFixed(), 'decimal')
  } else {
    let result = new Duration()
    result.parseIsoString(arg.value)
    if (result.getNegative()) {
      result.negate()
    }
    return Value.v(result.toIsoString(), 'duration')
  }
}

Expression.prototype.operatorLogarithm = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "logarithm" must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let number = Value.castToScalar(args[0], 'decimal')
  if (Value.isNullOrEmpty(number)) {
    return Value.v(null)
  }
  number = new Decimal(number.value)
  if (number.lessThan(0)) {
    return Value.error('Logarithm can be calculated only on positive number! Passed arguments: ' + this.printArgs(args))
  }
  let base = null
  if (args.length > 1) {
    base = Value.castToScalar(args[1], 'decimal')
    if (MC.isNullOrEmpty(base)) {
      return Value.error('Logarithm base must not be null or empty! Passed arguments: ' + this.printArgs(args))
    }
    base = new Decimal(base.value)
    if (base.lessThan(0) || base.equals(1)) {
      return Value.error('Logarithm base must positive number other than one! Passed arguments: ' + this.printArgs(args))
    }
  }
  let result
  if (base == null) {
    result = Decimal.ln(number)
  } else if (base.equals(2)) {
    result = Decimal.log2(number)
  } else if (base.equals(10)) {
    result = Decimal.log10(number)
  } else {
    result = Decimal.log(number).div(Decimal.log(base))
  }
  return Value.v(result.toFixed(result.isInt() || result.dp() < 18 ? undefined : 17), result.isInt() ? 'int' : 'decimal')
}

Expression.prototype.operatorSort = function(exprs) {
  if (exprs.length < 1 || exprs.length > 4) {
    return Value.error('Function "sort" must have one to four arguments! ' + exprs.length + ' args were passed.')
  }
  let collection = this.evaluateLazy(exprs[0])
  if (Value.isError(collection)) {
    return collection
  }
  if (Value.isNullOrEmpty(collection)) {
    return collection
  }
  collection = Value.castToCollection(collection)
  let sortBy = []
  if (exprs.length > 1) {
    let arg2 =  this.evaluateLazy(exprs[1])
    if (!Value.isNull(arg2)) {
      let base = this.enterBaseContext()
      for (let i = 0; i < Value.collectionSize(collection); i++) {
        this.setPosition(i, Value.collectionItem(collection, i))
        let value = Value.castToScalar(this.evaluateLazy(exprs[1], null, null, null, 1))
        if (Value.isError(value)) {
          return value
        }
        sortBy.push(Value.isNull(value) ? Value.v('', 'string') : value)
        this.unsetPosition()
      }
      this.leaveBaseContext(base)
    }  
  }
  sortBy = Value.v(sortBy, 'collection')
  if (Value.isNull(sortBy)) {
    sortBy = collection
  }
  let objects = []
  for (let i=0; i<Value.collectionSize(collection); i++) {
    objects.push({sortBy: (i < Value.collectionSize(sortBy) ? Value.collectionItem(sortBy, i) : Value.collectionItem(sortBy, Value.collectionSize(sortBy) - 1)), value: Value.collectionItem(collection, i)})
  }
  let desc = false
  if (exprs[2]) {
    let descRes = Value.castToScalar(this.evaluateLazy(exprs[2]), 'string')
    if (Value.isError(descRes)) {
      return descRes
    }
    if (descRes.value.toLowerCase() == 'desc' || descRes.value.toLowerCase() == 'descending') {
      desc = true
    }
  }
  let language = null
  if (exprs[3]) {
    language = Value.castToScalar(this.evaluateLazy(exprs[3]), 'string')
    if (Value.isError(language)) {
      return language
    }
    language = language.value
  }
  const flip = (fn) => (a, b) => fn(b, a)
  let collator
  if (language) {
    collator = new Intl.Collator(language)
  } else {
    collator = new Intl.Collator()
  }
  let comparator = (a, b) => {
    if (Value.isNumber(a.sortBy)) {
      return Value.isTrue(this.operatorNotEquals([a.sortBy, b.sortBy])) ? Value.isTrue(this.operatorGreater([a.sortBy, b.sortBy])) ? 1 : -1 : 0
    } if (a.sortBy.type == 'duration') {
      let ad = new Duration()
      ad.parseIsoString(a.sortBy.value)
      let bd = new Duration()
      bd.parseIsoString(b.sortBy.value)
      return ad.compareTo(bd)
    } else {
      return collator.compare(a.sortBy.value, b.sortBy.value)
    }
  }
  if (desc) {
    comparator = flip(comparator)
  }
  objects.sort(comparator)
  let result = []
  for (let o of objects) {
    result.push(o.value)
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorDelete = function(args) {
  if (args.length != 2) {
    return Value.error('Function "delete" must have exactly two args! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
    return args[0]
  }
  let indexes = []
  let arg1 = Value.castToCollection(args[1])
  for (let i of Value.collectionValue(arg1)) {
    i = Value.castToScalar(i, 'int')
    if (!Value.isNullOrEmpty(i)) {
      indexes.push(Number(i.value).valueOf())
    }
  }
  let arg0 = Value.castToCollection(args[0])
  if (indexes.length == 0) {
    return arg0
  }
  let result = []
  for (let i = 0; i < Value.collectionSize(arg0); i++) {
    if (indexes.indexOf(i) == -1) {
      result.push(Value.collectionItem(arg0, i))
    }
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorUpdate = function(args) {
  if (args.length != 3) {
    return Value.error('Function "update" must have exactly three args! Passed arguments: ' + this.printArgs(args));
  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
    return args[0]
  }
  let arg1 = Value.castToCollection(args[1])
  let indexes = []
  for (let i of Value.collectionValue(arg1)) {
    i = Value.castToScalar(i, 'int')
    indexes.push(Value.isNullOrEmpty(i) ? null : Number(i.value).valueOf())
  }
  let arg0 = Value.castToCollection(args[0])
  if (indexes.length == 0) {
    return arg0
  }
  let arg2 = Value.castToCollection(args[2])
  let result = [...Value.collectionValue(arg0)]
  let arg2Size = Value.collectionSize(arg2)
  for (let i = 0; i < indexes.length; i++) {
    if (indexes[i] !== null) {
      let replacementValue = i < arg2Size ? Value.collectionItem(arg2, i) : Value.collectionItem(arg2, arg2Size-1)
      if (replacementValue == null) {
        replacementValue = Value.v(null)
      }
      if (indexes[i] >= result.length) {
        return Value.error('Index ' + indexes[i] + ' of bounds for length ' + result.length + ' in function "update"! Passed arguments: ' + this.printArgs(args));
      }
      result[indexes[i]] = replacementValue
    }
  }
  return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
}

Expression.prototype.operatorCollectionUnwrap = function(args) {
  if (args.length < 1 || args.length > 3) {
    return Value.error('Function "collectionUnwrap" must have one, two or three args! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isCollection(args[0])) {
    let index = 0
    if (args.length > 1) {
      let arg1 = Value.castToScalar(args[1], 'int')
      if (!Value.isNullOrEmpty(arg1) && arg1.value > 0) {
        index = parseInt(arg1.value)
      }
    }
    let depth = -1
    if (args.length > 2) {
      let arg2 = Value.castToScalar(args[2], 'int')
      if (!Value.isNullOrEmpty(arg2) && arg2.value > -1) {
        depth = parseInt(arg2.value)
      }
    }
    if (depth == -1) {
      depth = Value.collectionDepth(args[0]) - 1
    }
    let res = [...Value.collectionValue(args[0])]
    this.unwrap(res, depth, index)
    return Value.v(res, 'collection')
  }
  return args[0]
}

Expression.prototype.unwrap = function(collection, atDepth, index) {
  for (let i = 0; i < collection.length; i++) {
    if (Value.isCollection(collection[i])) {
      let collItem = Value.collectionValue(Value.castToCollection(collection[i]))
      if (atDepth == 1) {
        let selectedItem = collItem.length > index ? collItem[index] : Value.v(null)
        collection[i] = selectedItem
      } else {
        let res = [...collItem]
        this.unwrap(res, atDepth - 1, index)
        collection[i] = Value.v(res, 'collection')
      }
    }
  }
}

Expression.prototype.operatorIbanToDisplay = function(args) {
  if (args.length != 1) {
    return Value.error('Function "ibanToDisplay" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let iban = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(iban)) {
    return iban
  }
  iban = iban.value
  if (!iban.match(/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{0,30}$/)) {
    return Value.error("Invalid IBAN value: " + iban)
  }
  let formatted = ''
  let i = 0
  while (i + 4 <= iban.length) {
    if (i > 0) {
      formatted += " "
    }
    formatted += iban.substring(i, i + 4)
    i += 4
  }
  if (i < iban.length) {
    formatted += " "
    formatted += iban.substring(i)
  }
  return Value.v(formatted, 'string')
}

Expression.prototype.operatorLookup = function(args) {
  if (args.length != 3) {
    return Value.error('Function "lookup" must have exactly three args! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNull(args[0])) {
    return args[0]
  }
  let collection = Value.collectionValue(Value.castToCollection(args[0]))
  let keys = Value.collectionValue(Value.castToCollection(args[1]))
  let values = Value.collectionValue(Value.castToCollection(args[2]))
  if (keys.length != values.length) {
    return Value.error('Collections in second and third argument of function "lookup" must have same size! Passed arguments: ' + this.printArgs(args))
  }
  let result = []
  for (let item of collection) {
    let found = false
    for (var k=0; k<keys.length; k++) {
      if (keys[k].value == item.value) {
        result.push(values[k])
        found = true
        break
      }
    }
    if (!found) {
      result.push(Value.v(null))
    }
  }
  return Value.v(result, 'collection')
}

Expression.prototype.operatorReplace = function(args) {
  if (args.length < 2 || args.length > 5) {
    return Value.error('Function "replace" must have two to five arguments! Passed arguments: ' + this.printArgs(args))
  }
  let string = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(string)) {
    return string
  } else {
    string = string.value
  }
  let what = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(what)) {
    return string
  } else {
    what = what.value
  }
  let rwith = Value.v(null)
  if (args.length > 2 ) {
    rwith = Value.castToScalar(args[2], 'string')
  }
  if (Value.isNullOrEmpty(rwith)) {
    rwith = ''
  } else {
    rwith = rwith.value
  }
  let useRegexp = false
  if (args[3] && Value.isTrue(Value.castToScalar(args[3], 'boolean'))) {
    useRegexp = true
  }
  let firstOnly = false
  if (args[4] && Value.isTrue(Value.castToScalar(args[4], 'boolean'))) {
    firstOnly = true
  }
  if (useRegexp) {
    rwith = rwith.replace('$0', function () { return '$&'}).replace("\\\\", "\\")  // hack - convert from JAVA replace to JAVASCRIPT - // -> /  , $0 -> $&
    return Value.v(string.replace(new RegExp(what, firstOnly ? '' : 'g'), rwith), 'string')
  } else {
    if (firstOnly) {
      return Value.v(string.replace(what, rwith), 'string')
    } else {
      return Value.v(string.split(what).join(rwith), 'string')
    }
  }
}

Expression.prototype.operatorFirstNonNull = function(args) {
  for (let arg of args) {
    if (Value.isCollection(arg)) {
      let flattened = this.flattenCollection(arg)
      if (Value.collectionSize(flattened) > 0) {
        return arg
      }
    } else {
      if (!Value.isPureNull(arg)) {
        return arg
      }
    }
  }
  return Value.v(null)
}

Expression.prototype.operatorQuote = function(exprs) {
  if (exprs.length != 1) {
    return Value.error('Function "quote" must have exactly one argument! ' + exprs.length + ' args were passed.')
  }
  let props = '<rbs:Data xmlns:d="http://metarepository.com/fspl/svc_mta#" xmlns:fl="http://resourcebus.org/interpreters/flow/#" xmlns:rbs="http://resourcebus.org/ns/storage#">\n'
  props += this.exprToPropertiesXml(exprs[0])
  if (this.cData.env && Value.hasProperty(this.cData.env, 'ns')) {
    let nss = Value.castToCollection(Value.getProperty(this.cData.env, 'ns'))
    for (let ns of Value.collectionValue(nss)) {
      props += '<fl:namespace rbs:id="' + Value.getProperty(ns, 'prefix').value + '">\n'
      props += '<d:prefix>' + Value.getProperty(ns, 'prefix').value + '</d:prefix>\n'
      props += '<d:uri>' + Value.getProperty(ns, 'uri').value + '</d:uri>\n'
      props += '</fl:namespace>\n'
    }
  }
  props += '</rbs:Data>'
  return Value.v(props, 'string')
}

Expression.prototype.exprToPropertiesXml = function(expr) {
  let props = ''
  if (expr.operator) {
    if (expr.operator == 'unquote') {
      props += '<d:param1>' + this.operatorUnquote(expr.expr) + '</d:param1>\n'
      return props;
    } else {
      props += '<d:mfunction>' + MC.escapeXML(expr.operator) + '</d:mfunction>\n'
    }
  }
  if (expr.source) {
    props += '<d:param1>' + MC.escapeXML(expr.source) + '</d:param1>\n'
  }
  if (expr.expr && Array.isArray(expr.expr)) {
    for (var i=0; i<expr.expr.length; i++) {
      props += '<d:OperationActionMapping>\n'
      props += this.exprToPropertiesXml(expr.expr[i])
      props += '</d:OperationActionMapping>\n'
    }
  }
  return props
}

Expression.prototype.operatorUnquote = function(exprs) {
  if (!Array.isArray(exprs) || exprs.length != 1) {
    return Value.error('Function "unquote" must have exactly one argument! Passed arguments: ' + JSON.stringify(exprs))
  }
  let result = this.evaluateLazy(exprs[0])
  if (Value.isError(result)) {
    return result
  }
  if (Value.isCollectionNotEmpty(result)) {
    let res = '['
    let sep = ''
    for (let val of Value.collectionValue(result)) {
      res += sep + "'" + val.value + "'"
      sep = ', '
    }
    return res + ']'
  } else {
    return "'" + result.value + "'"
  }
}

Expression.prototype.operatorPath = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "path" must have must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let argument = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(argument)) {
    return Value.error('First argument of function "path" must be specified. Passed arguments: ' + this.printArgs(args))
  }
  if (args.length == 1) {
    return this.evaluateSource({source: argument.value}, false)
  } else {
    let argument2 = args[1]
    if (Value.isNullOrEmpty(argument2)) {
      return Value.v(null)
    } else if (!Value.isDataNode(argument2)) {
      return Value.error('Second argument of function "path" must be a data node value. Passed arguments: ' + this.printArgs(args))
    } else {
      return this.getValue(argument2, argument.value.split("/"), Value.isCollection(argument2))
    }
  }
}

Expression.prototype.operatorFormPath = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "formPath" must have must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let argument = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(argument)) {
    return Value.error('First argument of function "formPath" must be specified. Passed arguments: ' + this.printArgs(args))
  }
  let tokens = argument.value.split("/")
  tokens.shift()
  if (args.length == 1 || Value.isNullOrEmpty(args[1])) {
    return this.evaluateInForm(tokens)
  } else {
    let repeaterRows = []
    for (let index of Value.collectionValue(Value.castToCollection(args[1]))) {
      index = Value.castToScalar(index, 'integer')
      if (Value.isNullOrEmpty(index)) {
        return Value.error('Index of row in argument of function "formPath" must be not null or empty. Passed arguments: ' + this.printArgs(args))
      }
      repeaterRows.push(parseInt(index.value))
    }
    return this.evaluateInForm(tokens, null, repeaterRows, true)
  }
}

Expression.prototype.operatorShorten = function(args) {
  if (args.length != 2 && args.length != 3) {
    return Value.error('Function "shorten" works only with two or three args! Passed arguments: ' + this.printArgs(args))
  }
  let str = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(str)) {
    return str
  }
  let length = parseInt(Value.castToScalar(args[1], 'int').value)
  let addDoots = true
  if (args[2] && Value.isFalse(Value.castToScalar(args[2], 'boolean'))) {
    addDoots = false
  }
  let res = str.value
  if (res.length > length) {
    res = res.substring(0, length)
    if (addDoots) {
      res += '...'
    }
  }
  return Value.v(res, 'string')
}

Expression.prototype.operatorCast = function(args) {
  if (args.length != 2) {
    return Value.error('Function "cast" must have exactly two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let desiredType = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(desiredType)) {
    return Value.error('Second argument of function "cast" must not be null or empty! Passed arguments: ' + this.printArgs(args))
  }
  return Value.castToScalar(args[0], desiredType.value)
}

Expression.prototype.operatorCastable = function(args) {
  if (args.length != 2) {
    return Value.error('Function "castable" must have exactly two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let desiredType = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(desiredType)) {
    return Value.error('Second argument of function "castable" must not be null or empty! Passed arguments: ' + this.printArgs(args))
  }
  try {
    if (Value.isCollection(args[0])) {
      let coll = this.flattenCollection(args[0])
      for (let item of Value.collectionValue(coll)) {
        Value.castToScalar(item, desiredType.value)
      }
    } else {
      Value.castToScalar(args[0], desiredType.value)
    }
  } catch (e) {
    return Value.v(false, 'boolean')
  }
  return Value.v(true, 'boolean')
}

Expression.prototype.operatorRiResolve = function(args) {
  if (args.length != 2) {
    return Value.error('Function "riResolve" must have two arguments! Passed arguments: ' + this.printArgs(args))
  }
  const base = Value.castToScalar(args[0], 'string')
  const ref = Value.castToScalar(args[1], 'string')
  if (Value.isNull(ref)) {
    return ref
  }
  if (Value.isNull(base)) {
    return Value.error('First argument can be null only if second argument is null as well! Passed arguments: ' + this.printArgs(args))
  } 
  const sep = '/'
  if (ref.value.startsWith(sep)) {
    return Value.v(ref.value.substring(1), 'string')
  } else {
    let result = new MC.URLUtils(ref.value, base.value).href
    if (result.startsWith(sep)) {
      return Value.v(result.substring(1), 'string')
    } else {
      return Value.v(result, 'string')
    }
  }
}

Expression.prototype.operatorRiRelativize = function(args) {
  if (args.length !== 2 && args.length !== 3) {
    return Value.error('Function "riRelativize" must have two or two or three args! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNull(args[0]) && Value.isNull(args[1])) {
    return Value.v(null)
  }
  const base = Value.castToScalar(args[0], 'string').value
  const ri = Value.castToScalar(args[1], 'string').value
  const preferAbsolute = (args[2] && Value.isFalse(Value.castToScalar(args[2], 'boolean'))) ? false : true
  const sep = '/'
  const pathSegments = base.split(sep).filter(t => t !== '')
  const riPathSegments = ri.split(sep).filter(t => t !== '')
  const fsStyle = (base.endsWith(sep) && base !== '/')
  let segments = []
  let i = 0
  // get index of last common segment (the highest index of segment for which preceding segments (including self) are
  // same and following segments are different - in other words, number of shared path segments)
  while (pathSegments.length > i && riPathSegments.length > i && pathSegments[i] === riPathSegments[i]) {
    i++
  }
  if (i !== 0 || !preferAbsolute) {
    // add "step up" segment until this.pathSegments is traversed up to the last common segment (if necessary)
    // additional "minus one" is needed because of specific URI resolution rules (child segment is not appended to last
    // segment, but to a segment preceding last)
    const to = fsStyle ? (pathSegments.length - i) : (pathSegments.length - i - 1)
    for (let j = 0; j < to; j++) {
      segments.push('..')
    }
  }
  if (fsStyle) {
    // add all ri.pathSegments segments following last common segment
    for (let j = i; j < riPathSegments.length; j++) {
      segments.push(riPathSegments[j])
    }
  } else {
    // add all ri.pathSegments segments following last common segment (repeat last common segment if not "stepping up")
    for (let j = (i < pathSegments.length || i === 0 ? i : i - 1); j < riPathSegments.length; j++) {
      segments.push(riPathSegments[j])
    }
  }
  let relative = segments.join(sep)
  if (i === 0 && preferAbsolute) {
    relative = sep + relative
  }
  if (ri.endsWith(sep) && relative === '') {
    return Value.v('.', 'string')
  }
  if (ri.endsWith(sep) && ri !== '/') {
    relative = relative + sep
  }
  return Value.v(relative, 'string')
}

Expression.prototype.operatorStringFind = function(args) {
  if (args.length != 2 && args.length != 3) {
    return Value.error('Function "stringFind" must have two or three arguments! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (Value.isNull(arg)) {
    return arg
  }
  let arg1 = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(arg1)) {
    return Value.v(null)
  }
  if (!args[2] || !Value.isFalse(Value.castToScalar(args[2], 'boolean'))) {
    let res = (new RegExp('^' + arg1.value + '$')).exec(arg.value)
    if (MC.isNull(res)) {
      return Value.v(null)
    } else {
      return Value.v(res.filter((el, i) => i>0).map(el => Value.v(el, 'string')), 'collection')
    }
  } else {
    let result = []
    let matches = (arg.value).match(new RegExp(arg1.value, 'g'))
    if (matches === null) {
      matches = []
    }
    for (let match of matches) {
      let res = (new RegExp('^' + arg1.value + '$')).exec(match)
      if (!MC.isNull(res)) {
        result.push(Value.v(res.filter((el, i) => i>0).map(el => Value.v(el, 'string')), 'collection'))
      }
    }
    return result.length > 0 ? Value.v(result, 'collection') : Value.v(null)
  }
}

Expression.prototype.operatorStringContains = function(args) {
  if (args.length != 2 && args.length != 3) {
    return Value.error('Function "stringContains" must have two or three arguments! Passed arguments: ' + this.printArgs(args))
  }
  let string = Value.castToScalar(args[0], 'string')
  if (Value.isNull(string)) {
    return Value.v(false, 'boolean')
  }
  string = string.value
  let substring = Value.castToScalar(args[1], 'string')
  if (Value.isNull(substring)) {
    return Value.v(false, 'boolean')
  }
  substring = substring.value
  let caseSensitive = (args[2] && Value.isFalse(Value.castToScalar(args[2], 'boolean'))) ? false : true
  if (!caseSensitive) {
    string = string.toLowerCase()
    substring = substring.toLowerCase()
  }
  return Value.v(string.indexOf(substring) > -1, 'boolean')
}

Expression.prototype.operatorIndexOf = function(args) {
  if (args.length < 2 || args.length > 3) {
    return Value.error('Function "indexOf" must have two or three arguments! Passed arguments: ' + this.printArgs(args))
  }
  let str = Value.castToScalar(args[0], 'string')
  if (Value.isNull(str)) {
    return str
  }
  let from = undefined
  if (args[2]) {
    from = parseInt(Value.castToScalar(args[2], 'int').value)
  }
  return Value.v(str.value.indexOf(Value.castToScalar(args[1], 'string').value, from), 'string')
}

Expression.prototype.operatorLastIndexOf = function(args) {
  if (args.length < 2 || args.length > 3) {
    return Value.error('Function "lastIndexOf" must have two or three arguments! Passed arguments: ' + this.printArgs(args))
  }
  let str = Value.castToScalar(args[0], 'string')
  if (Value.isNull(str)) {
    return str
  }
  let from = undefined
  if (args[2]) {
    from = parseInt(Value.castToScalar(args[2], 'int').value)
  }
  return Value.v(str.value.lastIndexOf(Value.castToScalar(args[1], 'string').value, from), 'string')
}

Expression.prototype.operatorDataToEntries = function(args) {
  if (args.length != 1) {
    return Value.error('Function "dataToEntries" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNullOrEmpty(args[0])) {
    return Value.v(null)
  }
  if (!Value.isDataNode(args[0])) {
    return Value.error('Only data node value can be argument of function "dataToEntries"! Passed arguments: ' + this.printArgs(args))
  }
  let result = []
  for (let key in args[0].value) {
    result.push(Value.dataNode({key: Value.v(key.endsWith('*') ? key.substring(0, key.length-1) : key, 'string'), value: args[0].value[key]}))
  }
  return Value.v(result, 'collection')
}

Expression.prototype.operatorEntriesToData = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "entriesToData" must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let collection1 = Value.collectionValue(Value.castToCollection(args[0]))
  if (args.length == 1) {
    let result = {}
    for (let pair of collection1) {
      pair = Value.castToDataNode(pair)
      let key = Value.castToScalar(Value.getProperty(pair, "key"), 'string')
      let value = Value.getProperty(pair, "value")
      if (!Value.isNull(key) && !Value.isNull(value)) {
        result[key.value] = value
      }
    }
    return Value.dataNode(result)
  }
  let collection2 = Value.collectionValue(Value.castToCollection(args[1]))
  let size = collection1.length > collection2.length ? collection1.length : collection2.length
  if (size <= 0) {
    return Value.v(null)
  }
  let dataNodeValue = {}
  for (let i = 0; i < size; i++) {
    let key = collection1.length > i ? collection1[i] : Value.v(null)
    key = Value.castToScalar(key, 'string')
    if (Value.isNullOrEmpty(key)) {
      key = 'undefined_key_at_index_' + i
    } else {
      key = key.value
    }
    let value = collection2.length > i ? collection2[i] : Value.v(null)
    if (!Value.isNull(value)) {
      dataNodeValue[key] = value
    }
  }
  return Value.dataNode(dataNodeValue)
}

Expression.prototype.operatorUpdateData = function(args) {
  if (args.length < 3) {
    return Value.error('Function "updateData" must have at least three arguments! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
    return args[0]
  }
  let result = Object.assign({}, Value.castToDataNode(args[0]).value)
  for (let i = 1; i < args.length - 1; i = i + 2) {
    let propertyName = Value.castToScalar(args[i], 'string')
    if (Value.isNullOrEmpty(propertyName)) {
      return Value.error('Complex value property name in "updateData" cannot be null or empty! (argument # ' + (i+1) + ') Passed arguments: ' + this.printArgs(args))

    }
    let propertyValue = args[i+1]
    if (!Value.isNull(propertyValue)) {
      result[propertyName.value] = propertyValue
    }
  }
  return Value.dataNode(result)
}

Expression.prototype.operatorAccumulateData = function(args) {
  if (args.length < 3) {
    return Value.error('Function "accumulateData" must have at least three arguments! Passed arguments: ' + this.printArgs(args))

  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
    return args[0]
  }
  let result = Object.assign({}, Value.castToDataNode(args[0]).value)
  for (let i = 1; i < args.length - 1; i = i + 2) {
    let propertyName = Value.castToScalar(args[i], 'string')
    if (Value.isNullOrEmpty(propertyName)) {
      return Value.error('Complex value property name in "accumulateData" cannot be null or empty! (argument # ' + (i+1) + ') Passed arguments: ' + this.printArgs(args))
    }
    let propertyValue = args[i+1]
    if (!Value.isNull(propertyValue)) {
      propertyName = propertyName.value
      if (MC.isNull(result[propertyName]) || Value.isNull(result[propertyName])) {
        result[propertyName] = propertyValue
      } else {
        let accumulatedValue = Value.collectionValue(Value.castToCollection(result[propertyName]))
        accumulatedValue = accumulatedValue.concat(Value.collectionValue(Value.castToCollection(propertyValue)))
        result[propertyName] = Value.v(accumulatedValue, 'collection')
      }
    }
  }
  return Value.dataNode(result)
}

Expression.prototype.operatorEncodeUrl = function(args) {
  if (args.length != 1) {
    return Value.error('Function "encodeUrl" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (MC.isNullOrEmpty(arg)) {
    return arg
  }
  return Value.v(encodeURIComponent(arg.value), 'string')
}

Expression.prototype.operatorDecodeUrl = function(args) {
  if (args.length != 1) {
    return Value.error('Function "decodeUrl" must have exactly one arguments! Passed arguments: ' + this.printArgs(args))
  }
  let arg = Value.castToScalar(args[0], 'string')
  if (MC.isNullOrEmpty(arg)) {
    return arg
  }
  return Value.v(decodeURIComponent(arg.value), 'string')
}

Expression.prototype.operatorFormatIban = function(args) {
  if (args.length < 3 || args.length > 4) {
    return Value.error('Function "formatIban" must have three or four arguments! Passed arguments: ' + this.printArgs(args))
  }
  let countryCode = Value.castToScalar(args[0], 'string')
  let bankCode = Value.castToScalar(args[1], 'string')
  let accountNumber = Value.castToScalar(args[2], 'string')
  if (Value.isNullOrEmpty(countryCode) || Value.isNullOrEmpty(bankCode) || MC.isNullOrEmpty(accountNumber)) {
    return Value.error('First three arguments of function "formatIban" are mandatory! Passed arguments: ' + this.printArgs(args))
  }
  countryCode = countryCode.value
  bankCode = bankCode.value
  accountNumber = accountNumber.value
  let formatted = (args[3] && Value.isFalse(Value.castToScalar(args[3], 'boolean'))) ? false : true
  let iban = bankCode + accountNumber
  iban = iban.replace(/[-\ ]/g, "").toUpperCase()
  let countrySpecs = {
    AD: { chars: 24, bban_regexp: "^[0-9]{8}[A-Z0-9]{12}$", name: "Andorra", IBANRegistry: true },
    AE: { chars: 23, bban_regexp: "^[0-9]{3}[0-9]{16}$", name: "United Arab Emirates", IBANRegistry: true },
    AF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Afganistan" },
    AG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Antigua and Bermuda" },
    AI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Anguilla" },
    AL: { chars: 28, bban_regexp: "^[0-9]{8}[A-Z0-9]{16}$", name: "Albania", IBANRegistry: true },
    AM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Armenia" },
    AO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Angola" },
    AQ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Antartica" },
    AR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Argentina" },
    AS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "American Samoa" },
    AT: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Austria", IBANRegistry: true },
    AU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Australia" },
    AW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Aruba" },
    AX: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Åland Islands", IBANRegistry: true },
    AZ: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{20}$", name: "Republic of Azerbaijan", IBANRegistry: true },
    BA: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Bosnia and Herzegovina", IBANRegistry: true },
    BB: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Barbados" },
    BD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bangladesh" },
    BE: { chars: 16, bban_regexp: "^[0-9]{12}$", name: "Belgium", IBANRegistry: true },
    BF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Burkina Faso" },
    BG: { chars: 22, bban_regexp: "^[A-Z]{4}[0-9]{6}[A-Z0-9]{8}$", name: "Bulgaria", IBANRegistry: true },
    BH: { chars: 22, bban_regexp: "^[A-Z]{4}[A-Z0-9]{14}$", name: "Bahrain", IBANRegistry: true },
    BI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Burundi" },
    BJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Benin" },
    BL: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Saint Barthelemy", IBANRegistry: true },
    BM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bermuda" },
    BN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Brunei Darusslam" },
    BO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bolivia, Plurinational State of" },
    BQ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bonaire, Sint Eustatius and Saba" },
    BR: { chars: 29, bban_regexp: "^[0-9]{23}[A-Z]{1}[A-Z0-9]{1}$", name: "Brazil", IBANRegistry: true },
    BS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bahamas" },
    BT: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bhutan" },
    BV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Bouvet Island" },
    BW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Botswana" },
    BY: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{4}[A-Z0-9]{16}$", name: "Republic of Belarus", IBANRegistry: true },
    BZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Belize" },
    CA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Canada" },
    CC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cocos (Keeling) Islands" },
    CD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Congo, the Democratic Republic of the" },
    CF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Central African Republic" },
    CG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Congo" },
    CH: { chars: 21, bban_regexp: "^[0-9]{5}[A-Z0-9]{12}$", name: "Switzerland", IBANRegistry: true },
    CI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Côte d'Ivoire" },
    CK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cook Islands" },
    CL: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Chile" },
    CM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cameroon" },
    CN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "China" },
    CO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Columbia" },
    CR: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Costa Rica", IBANRegistry: true },
    CU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cuba" },
    CV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cabo Verde" },
    CW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Curaçao" },
    CX: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Christmas Island" },
    CY: { chars: 28, bban_regexp: "^[0-9]{8}[A-Z0-9]{16}$", name: "Cyprus", IBANRegistry: true },
    CZ: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Czech Republic", IBANRegistry: true },
    DE: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Germany", IBANRegistry: true },
    DJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Djibouti" },
    DK: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Denmark", IBANRegistry: true },
    DM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Dominica" },
    DO: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{20}$", name: "Dominican Republic", IBANRegistry: true },
    DZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Algeria" },
    EC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Ecuador" },
    EE: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Estonia", IBANRegistry: true },
    EG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Egypt" },
    EH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Western Sahara" },
    ER: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Eritrea" },
    ES: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Spain", IBANRegistry: true },
    ET: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Ethiopia" },
    FI: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Finland", IBANRegistry: true },
    FJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Fiji" },
    FK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Falkland Islands (Malvinas)" },
    FM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Micronesia, Federated States of" },
    FO: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Faroe Islands (Denmark)", IBANRegistry: true },
    FR: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "France", IBANRegistry: true },
    GA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Gabon" },
    GB: { chars: 22, bban_regexp: "^[A-Z]{4}[0-9]{14}$", name: "United Kingdom", IBANRegistry: true },
    GD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Grenada" },
    GE: { chars: 22, bban_regexp: "^[A-Z0-9]{2}[0-9]{16}$", name: "Georgia", IBANRegistry: true },
    GF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "French Guyana", IBANRegistry: true },
    GG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guernsey" },
    GH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Ghana" },
    GI: { chars: 23, bban_regexp: "^[A-Z]{4}[A-Z0-9]{15}$", name: "Gibraltar", IBANRegistry: true },
    GL: { chars: 18, bban_regexp: "^[0-9]{14}$", name: "Greenland", IBANRegistry: true },
    GM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Gambia" },
    GN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guinea" },
    GP: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Guadeloupe", IBANRegistry: true },
    GQ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Equatorial Guinea" },
    GR: { chars: 27, bban_regexp: "^[0-9]{7}[A-Z0-9]{16}$", name: "Greece", IBANRegistry: true },
    GS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "South Georgia and the South Sandwitch Islands" },
    GT: { chars: 28, bban_regexp: "^[A-Z0-9]{24}$", name: "Guatemala", IBANRegistry: true },
    GU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guam" },
    GW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guinea-Bissau" },
    GY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Guyana" },
    HK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Hong Kong" },
    HM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Heard Island and McDonald Islands" },
    HN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Honduras" },
    HR: { chars: 21, bban_regexp: "^[0-9]{17}$", name: "Croatia", IBANRegistry: true },
    HT: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Haiti" },
    HU: { chars: 28, bban_regexp: "^[0-9]{24}$", name: "Hungary", IBANRegistry: true },
    ID: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Indonesia" },
    IE: { chars: 22, bban_regexp: "^[A-Z0-9]{4}[0-9]{14}$", name: "Republic of Ireland", IBANRegistry: true },
    IL: { chars: 23, bban_regexp: "^[0-9]{19}$", name: "Israel", IBANRegistry: true },
    IM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Isle of Man" },
    IN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "India" },
    IO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "British Indian Ocean Territory" },
    IQ: { chars: 23, bban_regexp: "^[A-Z]{4}[0-9]{15}$", name: "Iraq", IBANRegistry: true },
    IR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Iran, Islamic Republic of" },
    IS: { chars: 26, bban_regexp: "^[0-9]{22}$", name: "Iceland", IBANRegistry: true },
    IT: { chars: 27, bban_regexp: "^[A-Z]{1}[0-9]{10}[A-Z0-9]{12}$", name: "Italy", IBANRegistry: true },
    JE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Jersey" },
    JM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Jamaica" },
    JO: { chars: 30, bban_regexp: "^[A-Z]{4}[0-9]{4}[A-Z0-9]{18}$", name: "Jordan", IBANRegistry: true },
    JP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Japan" },
    KE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Kenya" },
    KG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Kyrgyzstan" },
    KH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cambodia" },
    KI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Kiribati" },
    KM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Comoros" },
    KN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Saint Kitts and Nevis" },
    KP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Korea, Domocratic People's Republic of" },
    KR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Korea, Republic of" },
    KW: { chars: 30, bban_regexp: "^[A-Z]{4}[A-Z0-9]{22}$", name: "Kuwait", IBANRegistry: true },
    KY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Cayman Islands" },
    KZ: { chars: 20, bban_regexp: "^[0-9]{3}[A-Z0-9]{13}$", name: "Kazakhstan", IBANRegistry: true },
    LA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Lao People's Democratic Republic" },
    LB: { chars: 28, bban_regexp: "^[0-9]{4}[A-Z0-9]{20}$", name: "Lebanon", IBANRegistry: true },
    LC: { chars: 32, bban_regexp: "^[A-Z]{4}[A-Z0-9]{24}$", name: "Saint Lucia", IBANRegistry: true },
    LI: { chars: 21, bban_regexp: "^[0-9]{5}[A-Z0-9]{12}$", name: "Liechtenstein", IBANRegistry: true },
    LK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Sri Lanka" },
    LR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Liberia" },
    LS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Lesotho" },
    LT: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Lithuania", IBANRegistry: true },
    LU: { chars: 20, bban_regexp: "^[0-9]{3}[A-Z0-9]{13}$", name: "Luxembourg", IBANRegistry: true },
    LV: { chars: 21, bban_regexp: "^[A-Z]{4}[A-Z0-9]{13}$", name: "Latvia", IBANRegistry: true },
    LY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Libya" },
    MA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Marocco" },
    MC: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Monaco", IBANRegistry: true },
    MD: { chars: 24, bban_regexp: "^[A-Z0-9]{2}[A-Z0-9]{18}$", name: "Moldova", IBANRegistry: true },
    ME: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Montenegro", IBANRegistry: true },
    MF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Saint Martin", IBANRegistry: true },
    MG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Madagascar" },
    MH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Marshall Islands" },
    MK: { chars: 19, bban_regexp: "^[0-9]{3}[A-Z0-9]{10}[0-9]{2}$", name: "Macedonia, the former Yugoslav Republic of", IBANRegistry: true },
    ML: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mali" },
    MM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Myanman" },
    MN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mongolia" },
    MO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Macao" },
    MP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Northern mariana Islands" },
    MQ: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Martinique", IBANRegistry: true },
    MR: { chars: 27, bban_regexp: "^[0-9]{23}$", name: "Mauritania", IBANRegistry: true },
    MS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Montserrat" },
    MT: { chars: 31, bban_regexp: "^[A-Z]{4}[0-9]{5}[A-Z0-9]{18}$", name: "Malta", IBANRegistry: true },
    MU: { chars: 30, bban_regexp: "^[A-Z]{4}[0-9]{19}[A-Z]{3}$", name: "Mauritius", IBANRegistry: true },
    MV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Maldives" },
    MW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Malawi" },
    MX: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mexico" },
    MY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Malaysia" },
    MZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Mozambique" },
    NA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Namibia" },
    NC: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "New Caledonia", IBANRegistry: true },
    NE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Niger" },
    NF: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Norfolk Island" },
    NG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nigeria" },
    NI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nicaraqua" },
    NL: { chars: 18, bban_regexp: "^[A-Z]{4}[0-9]{10}$", name: "Netherlands", IBANRegistry: true },
    NO: { chars: 15, bban_regexp: "^[0-9]{11}$", name: "Norway", IBANRegistry: true },
    NP: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nepal" },
    NR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Nauru" },
    NU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Niue" },
    NZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "New Zealand" },
    OM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Oman" },
    PA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Panama" },
    PE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Peru" },
    PF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "French Polynesia", IBANRegistry: true },
    PG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Papua New Guinea" },
    PH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Philippines" },
    PK: { chars: 24, bban_regexp: "^[A-Z0-9]{4}[0-9]{16}$", name: "Pakistan", IBANRegistry: true },
    PL: { chars: 28, bban_regexp: "^[0-9]{24}$", name: "Poland", IBANRegistry: true },
    PM: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Saint Pierre et Miquelon", IBANRegistry: true },
    PN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Pitcairn" },
    PR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Puerto Rico" },
    PS: { chars: 29, bban_regexp: "^[A-Z0-9]{4}[0-9]{21}$", name: "Palestine, State of", IBANRegistry: true },
    PT: { chars: 25, bban_regexp: "^[0-9]{21}$", name: "Portugal", IBANRegistry: true },
    PW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Palau" },
    PY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Paraguay" },
    QA: { chars: 29, bban_regexp: "^[A-Z]{4}[A-Z0-9]{21}$", name: "Qatar", IBANRegistry: true },
    RE: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Reunion", IBANRegistry: true },
    RO: { chars: 24, bban_regexp: "^[A-Z]{4}[A-Z0-9]{16}$", name: "Romania", IBANRegistry: true },
    RS: { chars: 22, bban_regexp: "^[0-9]{18}$", name: "Serbia", IBANRegistry: true },
    RU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Russian Federation" },
    RW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Rwanda" },
    SA: { chars: 24, bban_regexp: "^[0-9]{2}[A-Z0-9]{18}$", name: "Saudi Arabia", IBANRegistry: true },
    SB: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Solomon Islands" },
    SC: { chars: 31, bban_regexp: "^[[A-Z]{4}[]0-9]{20}[A-Z]{3}$", name: "Seychelles", IBANRegistry: true },
    SD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Sudan" },
    SE: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Sweden", IBANRegistry: true },
    SG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Singapore" },
    SH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Saint Helena, Ascension and Tristan da Cunha" },
    SI: { chars: 19, bban_regexp: "^[0-9]{15}$", name: "Slovenia", IBANRegistry: true },
    SJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Svalbard and Jan Mayen" },
    SK: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Slovak Republic", IBANRegistry: true },
    SL: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Siera Leone" },
    SM: { chars: 27, bban_regexp: "^[A-Z]{1}[0-9]{10}[A-Z0-9]{12}$", name: "San Marino", IBANRegistry: true },
    SN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Senegal" },
    SO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Somalia" },
    SR: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Suriname" },
    SS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "South Sudan" },
    ST: { chars: 25, bban_regexp: "^[0-9]{21}$", name: "Sao Tome And Principe", IBANRegistry: true },
    SV: { chars: 28, bban_regexp: "^[A-Z]{4}[0-9]{20}$", name: "El Salvador", IBANRegistry: true },
    SX: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Sint Maarten (Dutch part)" },
    SY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Syrian Arab Republic" },
    SZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Swaziland" },
    TC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Turks and Caicos Islands" },
    TD: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Chad" },
    TF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "French Southern Territories", IBANRegistry: true },
    TG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Togo" },
    TH: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Thailand" },
    TJ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tajikistan" },
    TK: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tokelau" },
    TL: { chars: 23, bban_regexp: "^[0-9]{19}$", name: "Timor-Leste", IBANRegistry: true },
    TM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Turkmenistan" },
    TN: { chars: 24, bban_regexp: "^[0-9]{20}$", name: "Tunisia", IBANRegistry: true },
    TO: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tonga" },
    TR: { chars: 26, bban_regexp: "^[0-9]{5}[A-Z0-9]{17}$", name: "Turkey", IBANRegistry: true },
    TT: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Trinidad and Tobago" },
    TV: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tuvalu" },
    TW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Taiwan, Province of China" },
    TZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Tanzania, United republic of" },
    UA: { chars: 29, bban_regexp: "^[0-9]{6}[A-Z0-9]{19}$", name: "Ukraine", IBANRegistry: true },
    UG: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Uganda" },
    UM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "United States Minor Outlying Islands" },
    US: { chars: null, bban_regexp: null, IBANRegistry: false, name: "United States of America" },
    UY: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Uruguay" },
    UZ: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Uzbekistan" },
    VA: { chars: 22, bban_regexp: "^[0-9]{18}", IBANRegistry: true, name: "Vatican City State" },
    VC: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Saint Vincent and the Granadines" },
    VE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Venezuela, Bolivian Republic of" },
    VG: { chars: 24, bban_regexp: "^[A-Z0-9]{4}[0-9]{16}$", name: "Virgin Islands, British", IBANRegistry: true },
    VI: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Virgin Islands, U.S." },
    VN: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Viet Nam" },
    VU: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Vanautu" },
    WF: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Wallis and Futuna", IBANRegistry: true },
    WS: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Samoa" },
    XK: { chars: 20, bban_regexp: "^[0-9]{16}$", name: "Kosovo", IBANRegistry: true },
    YE: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Yemen" },
    YT: { chars: 27, bban_regexp: "^[0-9]{10}[A-Z0-9]{11}[0-9]{2}$", name: "Mayotte", IBANRegistry: true },
    ZA: { chars: null, bban_regexp: null, IBANRegistry: false, name: "South Africa" },
    ZM: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Zambia" },
    ZW: { chars: null, bban_regexp: null, IBANRegistry: false, name: "Zimbabwe" },
  }
  let spec = countrySpecs[countryCode]
  if (iban !== null && spec !== undefined && spec.chars === (iban.length + 4)) {
    let reg = new RegExp(spec.bban_regexp, "")
    if (reg.test(iban)) {
      let checksom = countryCode + "00" + iban
      checksom = checksom.slice(3) + checksom.slice(0, 4)
      let validationString = ""
      for (let n = 1; n < checksom.length; n++) {
        let c = checksom.charCodeAt(n)
        if (c >= 65) {
          validationString += (c - 55).toString()
        } else {
          validationString += checksom[n]
        }
      }
      while (validationString.length > 2) {
        let part = validationString.slice(0, 6)
        validationString = (parseInt(part, 10) % 97).toString() + validationString.slice(part.length)
      }
      checksom = parseInt(validationString, 10) % 97
      iban =  countryCode + ("0" + (98 - checksom)).slice(-2) + iban
    }
  }
  if (formatted) {
    iban = iban.replace(/(.{4})(?!$)/g, "$1" + " ")
  }
  return Value.v(iban, 'string')
}

Expression.prototype.operatorTypeOf = function(args) {
  if (args.length != 1) {
    return Value.error('Function "typeOf" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNull(args[0])) {
    return Value.v('null', 'string')
  } else if (Value.isEmpty(args[0])) {
    return Value.v('empty', 'string')
  } else if (Value.isDataNode(args[0])) {
    return Value.v('dataNode', 'string')
  } else {  
    return Value.v(args[0].type, 'string')
  }
}

Expression.prototype.operatorReverse = function(args) {
  if (args.length != 1) {
    return Value.error('Function "reverse" must have exactly one argument! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isNullOrEmpty(args[0])) {
    return args[0]
  }
  let coll = Value.collectionValue(Value.castToCollection(args[0]))
  return coll.length > 0 ? Value.v(coll.toReversed(), 'collection') : Value.v(null)
}

Expression.prototype.operatorMergeData = function(args) {
  if (args.length == 0) {
    return Value.error('Function "mergeData" must have at least one argument!')
  }
  let result = {}
  for (let arg of args) {
    let coll = this.flattenCollection(Value.castToCollection(arg), true)
    for (let item of Value.collectionValue(coll)) {
      item = Value.castToDataNode(item)
      result = Object.assign(result, item.value)
    }
  }
  return Value.dataNode(result)
}

Expression.prototype.operatorDetachedTree = function(args) {
  if (args.length == 0) {
    return Value.v(null)
  }
  let result = {}
  for (let arg of args) {
    let coll = this.flattenCollection(Value.castToCollection(arg), true)
    for (let item of Value.collectionValue(coll)) {
      result = Object.assign(result, MC.makeObjectRecursive(Value.castToDataNode(item)).value)
    }
  }
  return Value.dataNode(result)
}

Expression.prototype.operatorMergeDataDeep = function(args) {
  if (args.length == 0) {
    return Value.error('Function "mergeDataDeep" must have at least one argument!')
  }
  let result = Value.dataNode({})
  for (let arg of args) {
    let coll = this.flattenCollection(Value.castToCollection(arg), true)
    for (let item of Value.collectionValue(coll)) {
      item = Value.castToDataNode(item)
      result = Value.extend(result, item, true)
    }
  }
  return result
}

Expression.prototype.operatorDeleteData = function(args) {
  if (args.length != 2) {
    return Value.error('Function "deleteData" must have two arguments! Passed arguments: ' + this.printArgs(args))
  }
  if (Value.isPureNull(args[0]) || Value.isEmpty(args[0])) {
    return args[0]
  }
  let deletes = Value.castToCollection(args[1])
  let result = Object.assign({}, Value.castToDataNode(args[0]).value)
  for (let del of Value.collectionValue(deletes)) {
    del = Value.castToScalar(del, 'string')
    if (result[del.value] != undefined) {
      delete result[del.value]
    }
  }
  return Value.dataNode(result)
}

Expression.prototype.operatorRandom = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "random" must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  const argument1 = Value.castToScalar(args[0], 'int')
  if (Value.isNullOrEmpty(argument1)) {
    return Value.error('First argument of function "random" is mandatory! Passed arguments: ' + this.printArgs(args))
  }
  const numOfChars = parseInt(argument1.value)
  if (numOfChars <= 0) {
    return Value.error('First argument of function "random" must be at least 1! Passed arguments: ' + this.printArgs(args))
  }
  let corpusName = "alphanumeric"
  if (args.length > 1) {
    const argument2 = Value.castToScalar(args[1], 'string')
    if (!Value.isNullOrEmpty(argument2)) {
      corpusName = argument2.value.toLowerCase()
    }
  }
  const CORPUS_NUMBERS = "0123456789";
  const CORPUS_LOWERCASELETTERS = "abcdefghijklmnopqrstuvwxyz";
  const CORPUS_UPPERCASELETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const CORPUS_SYMBOLS = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
  let corpus
  if ("numeric" === corpusName) {
    corpus = CORPUS_NUMBERS
  } else if ("alphanumeric_lc" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_LOWERCASELETTERS
  } else if ("alphanumeric_uc" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_UPPERCASELETTERS
  } else if ("alphanumeric" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_LOWERCASELETTERS + CORPUS_UPPERCASELETTERS
  } else if ("full" === corpusName) {
    corpus = CORPUS_NUMBERS + CORPUS_LOWERCASELETTERS + CORPUS_UPPERCASELETTERS + CORPUS_SYMBOLS
  } else {
    return Value.error('Second argument of function "random": unknown corpus type, use one of numeric, alphanumeric_lc, alphanumeric_uc, alphanumeric or full! Passed arguments: ' + this.printArgs(args))
  }
  let res = ""
  for (let i = 0; i < numOfChars; i++) {
    res += corpus.charAt(Math.floor(Math.random() * corpus.length))
  }
  return Value.v(res, 'string')
}

Expression.prototype.operatorformatNumber = function(args) {
  if (args.length < 2) {
    return Value.error('Function "formatNumber" must have at least two arguments! Passed arguments: ' + this.printArgs(args))
  }
  const argument1 = Value.castToScalar(args[0], 'decimal')
  if (Value.isNullOrEmpty(argument1)) {
    return argument1
  }
  const lang = Value.castToScalar(args[1], 'string')
  if (Value.isNullOrEmpty(lang)) {
    return Value.error('Second argument (locale language) of function "formatNumber" must have value! Passed arguments: ' + this.printArgs(args))
  }
  let opts = {}
  if (args[2] && Value.isFalse(Value.castToScalar(args[2], 'boolean'))) {
    opts.useGrouping = false
  }
  if (args.length > 3) {
   let minimumIntegerDigits = Value.castToScalar(args[3], 'int')
    if (!Value.isNullOrEmpty(minimumIntegerDigits)) {
      opts.minimumIntegerDigits = minimumIntegerDigits.value
    }
  }
  if (args.length > 4) {
    let minimumFractionDigits = Value.castToScalar(args[4], 'int')
    if (!Value.isNullOrEmpty(minimumFractionDigits)) {
      opts.minimumFractionDigits = minimumFractionDigits.value
    } 
  }
  if (args.length > 5) {
    let maximumFractionDigits = Value.castToScalar(args[5], 'int')
    if (!Value.isNullOrEmpty(maximumFractionDigits)) {
      opts.maximumFractionDigits = maximumFractionDigits.value
    }
  }
  if (args.length > 6) {
    let minimumSignificantDigits = Value.castToScalar(args[6], 'int')
    if (!Value.isNullOrEmpty(minimumSignificantDigits)) {
      opts.minimumSignificantDigits = minimumSignificantDigits.value
    }
  }
  if (args.length > 7) {
    let maximumSignificantDigits = Value.castToScalar(args[7], 'int')
    if (!Value.isNullOrEmpty(maximumSignificantDigits)) {
      opts.maximumSignificantDigits = maximumSignificantDigits.value
    }
  }
  if (args.length > 9) {
    let unit = Value.castToScalar(args[9], 'string')
    if (!Value.isNullOrEmpty(unit)) {
      opts.style = 'currency'
      opts.currency = unit.value
    }
  }
  if (args.length > 10) {
    let unitWidth = Value.castToScalar(args[10], 'string')
    if (!Value.isNullOrEmpty(unitWidth)) {
      unitWidth = unitWidth.value
      if (unitWidth == 'ISO_CODE') {
        unitWidth = 'code'
      }
      opts.currencyDisplay = unitWidth
    }  
  }
  return Value.v(new Intl.NumberFormat(lang.value, opts).format(argument1.value), 'string')
}

Expression.prototype.operatorNamespaceForPrefix = function(args) {
  if (args.length < 1 || args.length > 2) {
    return Value.error('Function "namespaceForPrefix" must have one or two arguments! Passed arguments: ' + this.printArgs(args))
  }
  const prefix = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(prefix) ) {
    return prefix
  }
  let failIfUndefined = args[1] && Value.isFalse(Value.castToScalar(args[1], 'boolean')) ? false : true
  let namespace = Value.v(null)
  if (this.cData.env && Value.hasProperty(this.cData.env, 'ns')) {
    let nss = Value.castToCollection(Value.getProperty(this.cData.env, 'ns'))
    let ns = Value.collectionValue(nss).find(i => Value.getProperty(i, 'prefix').value == prefix.value)
    if (ns) {
      namespace = Value.getProperty(ns, 'uri')
    }
  }
  if (Value.isNull(namespace)) {
    if (failIfUndefined) {
      return Value.error('Namespace prefix ' + prefix.value + " is not defined")
    }
    return Value.v(null)
  }
  return namespace
}

Expression.prototype.operatorValidateIban = function(args) {
  if (args.length != 1) {
    return Value.error('Function "validateIban" must have one argument! Passed arguments: ' + this.printArgs(args))
  }
  let iban = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(iban)) {
    return Value.v("IbanFormat: Empty string can't be a valid Iban.", 'string')
  }
  let CODE_LENGTHS = { AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24, FI: 18, FO: 18, FR: 27, GB: 22, 
    GI: 23, GL: 18, GR: 27, GT: 28, HR: 21, HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, 
    MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29, RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26, AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, 
    SC: 31, ST: 25, SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20}
  iban = iban.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
  let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
  // check syntax and length
  if (!code) {
    return Value.v("IbanFormat: Iban has not valid structure.", 'string')
  }
  if (!code || !CODE_LENGTHS[code[1]]) {
    return Value.v("IbanFormat: Iban contains non existing country code.", 'string')
  }
  if (iban.length !== CODE_LENGTHS[code[1]]) {
    return Value.v("IbanFormat: [" + code[3] + "] length is " + code[3].length + ", expected BBAN length is: " + (CODE_LENGTHS[code[1]] - code[1].length - code[2].length), 'string')
  }
  // rearrange country code and check digits, and convert chars to ints
  let digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  let checksum = digits.slice(0, 2)
  for (let offset = 2; offset < digits.length; offset += 7) {
    checksum = parseInt(String(checksum) + digits.substring(offset, offset + 7), 10) % 97
  }
  if (checksum == 1) {
    return Value.v(null)
  } else {
    return Value.v("InvalidCheckDigit: [" + iban + "] has invalid check digit", 'string')
  }
}

Expression.prototype.operatorValidateBic = function(args) {
  if (args.length != 1) {
    return Value.error('Function "validateBic" must have one argument! Passed arguments: ' + this.printArgs(args))
  }
  let bic = Value.castToScalar(args[0], 'string')
  if (Value.isNull(bic)) {
    return Value.v("Null can't be a valid Bic.", 'string')
  }
  if (Value.isEmpty(bic)) {
    return Value.v("BicFormat: Empty string can't be a valid Bic.", 'string')
  }
  bic = bic.value
  if (bic.length != 8 && bic.length != 11) {
    return Value.v("BicFormat: Bic length must be 8 or 11", 'string')
  }
  if (bic != bic.toUpperCase()) {
    return Value.v("BicFormat: Bic must contain only upper case letters.", 'string')
  }
  if (!/^[A-Z]+$/.test(bic.substring(0, 4))) {
    return Value.v("BicFormat: Bank code must contain only letters.", 'string')
  }
  let countryCode = bic.substring(4, 6)
  if (countryCode.trim().length < 2 || !/^[A-Z]+$/.test(countryCode)) {
    return Value.v("BicFormat: Bic country code must contain upper case letters", 'string')
  }
  let cCodes = ['AD','AE','AF','AG','AI','AL','AM','AO','AQ','AR','AS','AT','AU','AW','AX','AZ','BA','BB','BD','BE','BF','BG','BH','BI','BJ','BL','BM','BN','BO','BQ','BR','BS','BT','BV','BW','BY','BZ','CA','CC','CD','CF','CG','CH','CI','CK','CL','CM','CN','CO','CR','CU','CV','CW','CX','CY','CZ','DE','DJ','DK','DM','DO','DZ','EC','EE','EG','EH','ER','ES','ET','FI','FJ','FK','FM','FO','FR','GA','GB','GD','GE','GF','GG','GH','GI','GL','GM','GN','GP','GQ','GR','GS','GT','GU','GW','GY','HK','HM','HN','HR','HT','HU','ID','IE','IL','IM','IN','IO','IQ','IR','IS','IT','JE','JM','JO','JP','KE','KG','KH','KI','KM','KN','KP','KR','KW','KY','KZ','LA','LB','LC','LI','LK','LR','LS','LT','LU','LV','LY','MA','MC','MD','ME','MF','MG','MH','MK','ML','MM','MN','MO','MP','MQ','MR','MS','MT','MU','MV','MW','MX','MY','MZ','NA','NC','NE','NF','NG','NI','NL','NO','NP','NR','NU','NZ','OM','PA','PE','PF','PG','PH','PK','PL','PM','PN','PR','PS','PT','PW','PY','QA','RE','RO','RS','RU','RW','SA','SB','SC','SD','SE','SG','SH','SI','SJ','SK','SL','SM','SN','SO','SR','SS','ST','SV','SX','SY','SZ','TC','TD','TF','TG','TH','TJ','TK','TL','TM','TN','TO','TR','TT','TV','TW','TZ','UA','UG','UM','US','UY','UZ','VA','VC','VE','VG','VI','VN','VU','WF','WS','XK','YE','YT','ZA','ZM','ZW']
  if (cCodes.indexOf(countryCode) < 0) {
    return Value.v("UnsupportedCountry: Country code is not supported.", 'string')
  }
  if (!/^[A-Z0-9]+$/.test(bic.substring(6, 8))) {
    return Value.v("BicFormat: Location code must contain only letters or digits.", 'string')
  }
  if (bic.length == 11) {
    if (!/^[A-Z0-9]+$/.test(bic.substring(8, 11))) {
      return Value.v("BicFormat: Branch code must contain only letters or digits.", 'string')
    }
  }
  return Value.v(null)
}

Expression.prototype.operatorRemoveDiacritics = function(args) {
  if (args.length != 1) {
    return Value.error('Function "removeDiacritics" must have one argument! Passed arguments: ' + this.printArgs(args))
  }
  let res = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(res)) {
    return res
  }
  return Value.v(res.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 'string')
}

Expression.prototype.operatorError = function(args) {
  if (args.length > 1) {
    return Value.error('Function "error" must have at most one argument! Passed arguments: ' + this.printArgs(args))
  }
  let message = "Explicitly raised mapping error with no message"
  if (args.length > 0) {
    let arg = Value.castToScalar(args[0], 'string')
    if (!Value.isNullOrEmpty(arg)) {
      message = arg.value
    }  
  }
  return Value.error(message)
}

Expression.prototype.operatorFormatText = function(args) {
  if (args.length != 2) {
    return Value.error('Function "formatText" must have exactly two arguments! Passed arguments: ' + this.printArgs(args))
  }
  let pattern = Value.castToScalar(args[0], 'string')
  if (Value.isNullOrEmpty(pattern)) {
    return Value.v('', 'string')
  }
  if (Value.isNullOrEmpty(args[1]) || !Value.isDataNode(args[1])) {
    args[1] = Value.dataNode({})
  }
  let lang = Value.getProperty(Value.getProperty(this.cData.env, 'system'), 'language').value
  return Value.v(MC.formatValue(null, 'message', null, pattern.value, {param: Value.toJson(args[1]), lang: lang}, 'string'))
}  

export {Expression}