import React from "react"

import {MC} from './MC.js'

class Upload extends React.Component {

  state = {drag: false, progress: null, loading: false}
  dropRef = React.createRef()
  inputRef = React.createRef()
  chunkCounter = 0
  totalSize = 0
  progressSize = 0
  files = []

  componentDidUpdate() {
    let field = this.props.field
    if (MC.getFieldParamBooleanValue(field.param, '@nextPart')) {
      MC.putFieldParamValue(field.param, '@nextPart', false)
      if (!MC.isNull(this.nextStart) && !MC.isNull(this.fileIndex)) {
        this.createChunk(this.nextStart, this.fileIndex, true)
      } else {
        this.setState({progress: 100, loading: false})
        this.handleBeahaviour()
      }
    }
  }

  canBeAccept = (accept, file) => {
    if (!accept) return true
    const allowedTypes = accept.split(',').map(type => type.trim())
    const fileName = file.name.toLowerCase()
    const isValid = allowedTypes.some(type => {
        if (type.startsWith('.')) {
          return fileName.endsWith(type)
        }
        if (type.includes('/')) {
          type = type.replace("/*", "/")
          return file.type.startsWith(type)
        }
        return false
      })
    return isValid
  }

  handleFiles = (files) => {
    if (files && files.length > 0) {
      const field = this.props.field
      field.param.value = []
      let fileCountLimit = MC.getFieldParamBooleanValue(field.param, '@multiple') ? MC.getFieldParamValue(field.param, '@fileCountLimit') : 1
      fileCountLimit = MC.isNumeric(fileCountLimit) ? parseInt(fileCountLimit) : Infinity
      if (files.length > fileCountLimit) {
        this.fileCounter = 1
        this.handleLoadEnd([MC.formatMessage("fileCountLimitExceeded", field.flow.reactFlow().props.mconf.lang, fileCountLimit)])
        return
      }
      this.partSize = MC.getFieldParamValue(field.param, '@partSize')
      const isLarge = MC.isNumeric(this.partSize) && this.partSize > 0
      let self = this
      this.fileCounter = files.length
      const errors = []
      const fileSizeLimitStr = MC.getFieldParamValue(field.param, '@fileSizeLimit')
      let fileSizeLimit = MC.parseFileSize(fileSizeLimitStr, field.flow) ?? Infinity
      const accept = MC.getFieldParamValue(field.param, '@accept')
      for (const file of files) {
        fileSizeLimit = fileSizeLimit - file.file.size
        if (fileSizeLimit < 0) {
          errors.push(MC.formatMessage("fileSizeLimitExceeded", field.flow.reactFlow().props.mconf.lang, fileSizeLimitStr))
          this.fileCounter = 1
          self.handleLoadEnd(errors)
          return
        }
        if (!this.canBeAccept(accept, file.file)) {
          errors.push(MC.formatMessage("fileTypeBad", field.flow.reactFlow().props.mconf.lang, file.file.name))
          this.fileCounter = 1
          self.handleLoadEnd(errors)
          return
        }
        const newRecord = {'@fileName': file.file.name, '@contentType': file.file.type, '@size': file.file.size, '@relativePath': file.path ? file.path : file.file.name}
        if (isLarge) {
          field.param.value.push(newRecord)
          this.totalSize += file.file.size
        } else {
          let reader = new FileReader()
          reader.onload = function(e) {
            if (errors.length == 0) {
              newRecord['@data'] = e.target.result.substring(e.target.result.indexOf(';base64,')+8)
              field.param.value.push(newRecord)
            }
            self.handleLoadEnd(errors)
          }
          reader.onerror = function() {
            errors.push(MC.formatMessage("fileReadingError", field.flow.reactFlow().props.mconf.lang, file.file.name))
            self.handleLoadEnd(errors)
          } 
          reader.readAsDataURL(file.file)
        }
      }
      if (isLarge) {
        this.files = files
        this.createChunk(0, 0, false)
      }
    } else {
      this.setState({loading: false})
    }
  }

  handleFileChange = (e) => {
    this.setState({loading: true})
    this.handleFiles(Array.from(e.target.files).map((file) => {
      return {file: file, path: file.webkitRelativePath}
    }))
  }

  handleLoadEnd = (errors) => {
    this.fileCounter--
    if (this.fileCounter == 0) {
      const field = this.props.field
      if (errors.length > 0) {
        field.param.value = MC.getFieldParamBooleanValue(field.param, "validation/@required") ? "error" : []
        MC.putFieldParamValue(field.param, "@invalidmessage", errors.join(' '))
      }
      MC.handleEvent(field, 'change')
      this.handleBeahaviour()
      this.props.widget.revalidate(true)
      this.setState({loading: false})
    }
  }

  handleUploadClick = () => {
    this.handleResetClick()
    this.inputRef.current.click()
  }

  handleResetClick = () => {
    this.inputRef.current.value = null
    this.props.widget.resetValidation(true)
    if ("error" == this.props.field.param.value) {
      this.props.field.param.value = []
    }
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  checkList(items) {
    if (items && items.length > 0) {
      const param = this.props.field.param
      const multiple = MC.getFieldParamBooleanValue(param, '@multiple')
      const directory = MC.getFieldParamBooleanValue(param, '@directory')
      if (!multiple && items.length > 1) {
        return false
      }
      for (const item of items) {
        const entry = item.webkitGetAsEntry()
        if (entry && entry.isDirectory && !directory) {
          return false
        }
      }
      return true
    }
    return false
  }

  countItem(item, fitems) {
    self = this
    return new Promise((resolve) => {
      if (item.isFile) {
        fitems.push(item)
        resolve()
      } else if (item.isDirectory) {
        let promises = []
        let dirReader = item.createReader()
        let fnReadEntries = (() => {
          return (entries) => {
            for (let entry of entries) {
              promises.push(self.countItem(entry, fitems))
            }
            if (entries.length > 0) {
              dirReader.readEntries(fnReadEntries)
            } else {
              if (promises.length > 0) {
                Promise.all(promises).then(function () {
                  resolve()
                })
              } else {
                resolve()
              }
            }
          }
        })()
        dirReader.readEntries(fnReadEntries)
      }
    })
  }  

  countList(items, fitems) {
    return new Promise((resolve) => {
      let promises = []
      if (items && items.length > 0) {
        for (let item of items) {
          const entry = item.webkitGetAsEntry()
          if (entry) {
            promises.push(this.countItem(entry, fitems))
          }
        }
      }
      if (promises.length > 0) {
        Promise.all(promises).then(function () {
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (this.checkList(e.dataTransfer.items)) {
      this.setState({drag: true})
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.persist()
    this.setState({drag: false})
    if (this.checkList(e.dataTransfer.items)) {
      this.setState({loading: true})
      this.handleResetClick()
      const fitems = []
      this.countList(e.dataTransfer.items, fitems).then(() => {
        const files = []
        for (let item of fitems) {
          item.file((file) => {           
            files.push({file: file, path: item.fullPath})
            if (files.length == fitems.length) {
              this.handleFiles(files)
            }  
          })
        }
        this.dragCounter = 0
        e.dataTransfer.clearData()
      })
    }
  }

  createChunk = (start, fileIndex, progress) => {
    let file = this.files[fileIndex].file
    if (start == 0) {
      this.numberOfChunks = Math.ceil(file.size/Number(this.partSize))
    }
    this.chunkCounter++
    let chunkEnd = Math.min(start + Number(this.partSize), file.size)
    let chunk = file.slice(start, chunkEnd)
    let self = this
    let reader = new FileReader()
    reader.readAsDataURL(chunk)
    reader.onloadend = function() {
      self.props.field.param.part = {'@data': reader.result, '@size': chunkEnd-start, '@number': self.chunkCounter, '@totalParts': self.numberOfChunks, '@fileIndex': fileIndex + 1}
      self.progressSize += (chunkEnd-start)
      let newState = {}
      if (self.state.loading != progress) {
        newState.loading = progress
      }
      if (chunkEnd < file.size) {
        self.fileIndex = fileIndex
        self.nextStart = chunkEnd
        if (self.chunkCounter > 1) {
          let totalComplete =  Math.round( (self.progressSize/self.totalSize) * 100 )
          if (totalComplete <= 100) {
            newState.progress = totalComplete
          }
        } else {
          self.forceUpdate()
        }
      } else {
        if (self.files.length > fileIndex+1) {
          self.fileIndex = fileIndex + 1
          self.nextStart = 0
        } else {
          delete self.fileIndex
          delete self.nextStart
          self.files = []
          self.progressSize = 0
          self.totalSize = 0
          if (progress) {
            newState.progress = 100
          }
        }
        self.chunkCounter = 0
        self.numberOfChunks = 0
        self.props.widget.revalidate(true)  
      }
      if (!MC.isEmptyObject(newState)) {
        self.setState(newState)
      }
      MC.handleEvent(self.props.field, 'change')
    }
  }

  handleBeahaviour = () => {
    this.props.field.flow.handleSubmit(this.props.field)
  }

  render() {
    const props = this.props
    const field = props.field
    let innerDisabled = props.disabled
    if (MC.isModelerActive(field)) {
      innerDisabled = true
    }
    const buttonTitle = MC.getFieldParamValue(field.param, '@buttonTitle')
    let placeholder = props.placeholder
    let isSelectedFile = false
    let values = MC.getFieldParamValue(field.param, 'value')
    if (this.state.progress) {
      placeholder = <span className="progress">{this.state.progress}%</span>
    } else if (Array.isArray(values) && values.length > 0) {
      isSelectedFile = true
      if (values.length > 8) {
        placeholder = values.slice(0, 7).map((val) =>  val['@fileName']).join(', ') + ' ... (' +  values.length + ')'
      } else {
        placeholder = values.map((val) =>  val['@fileName']).join(', ')
      }
    }
    if (this.props.textMode) {
      return <React.Fragment>{placeholder}</React.Fragment>
    }
    let multiple = MC.getFieldParamBooleanValue(field.param, '@multiple')
    const accept = MC.getFieldParamValue(field.param, '@accept')
    const directory = MC.getFieldParamBooleanValue(field.param, '@directory') ? 'true' : null
    const showAsDropZone = MC.getFieldParamBooleanValue(field.param, '@showAsDropZone')
    let iterationId = MC.asArray(MC.getFieldParamValue(field.param, '@iteration')).join('-')
    let content
    if (showAsDropZone) {
      const dropZoneHeight = MC.getFieldParamValue(field.param, '@dropZoneHeight') || '200px'
      let style = {height: dropZoneHeight}
      const cls = MC.classes('mnc-file-drop-zone', {'drag': this.state.drag, 'selected': isSelectedFile})
      content = (
        <div ref={this.dropRef} style={style} className={cls} data-widget-i-name={field.id} onDragEnter={this.handleDragIn} onDragLeave={this.handleDragOut} 
          onDragOver={this.handleDrag} onDrop={this.handleDrop} onClick={this.handleUploadClick}>
          <div className={MC.classes('mnc-file-drop-zone-placeholder', {'drag': this.state.drag})}>{MC.iconize(field, placeholder)}</div>
          <input key="input" ref={this.inputRef} type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory} disabled={innerDisabled}/>
        </div>)
    } else {
      content = (
        <div>        
          <label htmlFor={field.id+iterationId} key="button" className={MC.classes("upload-button mnc button", {"disabled": innerDisabled || this.props.readOnly})} onClick={this.handleResetClick}>{MC.iconize(field, buttonTitle)}</label>
          <input key="input" ref={this.inputRef} data-widget-i-name={field.id} id={field.id+iterationId} type="file" onChange={this.handleFileChange} style={{display: 'none'}} multiple={multiple} accept={accept} webkitdirectory={directory} disabled={innerDisabled}/>
          <span key="span" className="upload-file-name">{placeholder}</span>
        </div>
      )
    }
    let icon = this.state.progress && this.state.progress < 100 ? null : <i className="spinner loading icon"/>
    return (
      <div className={MC.classes("dimmable", this.state.loading && "dimmed")}>
        <div className="mnc dimmer">{icon}</div>
        {content}
      </div>
    )
  }

}

export {Upload}