import React from "react";

import {MC} from './MC.js';
import {Link} from "./Link.jsx";
import {Widget} from "./Widget.jsx";
import {Field} from "../modeler/Field.jsx";

class TabPanel extends React.Component {

  handleItemClick(i) {
    MC.putFieldParamValue(this.props.data.param, '@activeIndex', i)
    this.forceUpdate()
    MC.handleEvent(this.props.data, 'change')
    if (this.props.data.isModelerActive()) {
      modelerChanged('resize')
    }
  }

  getItemsTextCount() {
    var field = this.props.data;
    if (Array.isArray(field.fields)) {
      switch (field.fields.length) {
        case 1: return "one item"; break;
        case 2: return "two item"; break;
        case 3: return "three item"; break;
        case 4: return "four item"; break;
        case 5: return "five item"; break;
        case 6: return "six item"; break;
        case 7: return "seven item"; break;
        case 8: return "eight item"; break;
        case 9: return "nine item"; break;
        default: return ""; break;
      }
    }
    return "";
  }

  render() {
    var field = this.props.data;
    var anchors = [];
    var contents = [];
    var theme = MC.getFieldParamValue(field.param, '@theme')
    if (Array.isArray(field.fields) && field.fields.length > 0) {
      var activeIndex = MC.getFieldParamValue(field.param, '@activeIndex')
      if (activeIndex < 0 || activeIndex >= field.fields.length || MC.isNull(activeIndex)) {
        activeIndex = 0;
      }
      for (var i = 0; i < field.fields.length; i++) {
        var tab = field.fields[i];
        var title = MC.getFieldParamValue(tab.param, '@title')
        if (!MC.getFieldParamBooleanValue(tab.param, '@visible') && !MC.isModelerInEyeMode(tab)) {
          continue
        }
        let css = MC.classes("item", {"active": activeIndex == i})
        if (tab.widget == 'link') {
          let disabled = false
          if (MC.getFieldParamBooleanValue(tab.param, '@enabled') == false || MC.getFieldParamBooleanValue(tab.param, '@permitted') == false) {
            disabled = true
          }
          if (MC.isModelerInEyeMode(tab)) {
            disabled = false
          }
          if ((!tab.reactWidget || !tab.reactWidget.widgetRootRef.current)) {
            tab.reactWidget = {widgetRootRef: React.createRef(), widgetRef: {}}
          }
          let widget = <Link data={tab} disabled={disabled} onClick={this.handleItemClick.bind(this, i)} value={MC.getFieldParamValue(tab.param, 'value')}/>
          anchors.push(<Field key={i} widgetRootRef={tab.reactWidget.widgetRootRef} field={tab} className={css} dataWidgetId={tab.rbsid} widget={widget} onFocus={() => {}} onBlur={() => {}}/>)
        } else {
          anchors.push(<a className={css} key={i} data-tab={tab.rbsid} onMouseDown={this.handleItemClick.bind(this, i)}>{MC.iconize(tab, <span>{title}</span>)}</a>);
          if (activeIndex == i) {
            contents.push(<div className={MC.classes("mnc tab segment active", {"first": activeIndex == 0, "bottom attached": theme != 'pointing'})} key={i} data-tab={tab.rbsid}><Widget widget={tab} resolution={this.props.resolution}/></div>)
          }
        }
      }
    }
    // 'tabular' is deprecated and replaced with 'tabs' and 'pointing' is deprecated and replaced with 'underlines'
    var menuclass = "mnc top attached tabular tabs menu";
    if (theme == 'pointing') { 
      menuclass = "mnc pointing menu";
    } else if (theme == 'underlines') {
      menuclass = "mnc underlines menu";
    } else if (theme == 'pills') {
      menuclass = "mnc pills menu";
    } else if (theme == 'outlined tabs') {
      menuclass = "mnc outlined tabs menu";
    }
    if (MC.getFieldParamBooleanValue(field.param, '@fluid')) {
      menuclass += " fluid " + this.getItemsTextCount()
    }
    return (
      <div className={MC.classes('mnc tabpanel', MC.getFieldParamValue(this.props.data.param, '@cssClass'))} style={MC.styleObjectFromString(MC.getFieldParamValue(this.props.data.param, '@css'))} data-widget-i-name={field.id}>
        <div className={menuclass}>{anchors}</div>
        {contents}
      </div>
    );
  }

}

export {TabPanel};