import React from "react"

import {MC} from './MC.js'
import {Value} from "./Value.js"
import tt from "./tooltip/tooltip.js"

class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.onclick = this.onclick.bind(this)
    this.handleRootMouseEnter = this.handleRootMouseEnter.bind(this)
    this.handleRootMouseLeave = this.handleRootMouseLeave.bind(this)
    this.menuRef = React.createRef()
    this.tolltipInstances = []
  }

  componentDidMount() {
    if (!MC.isModelerActive(this.props.data)) {
      if (this.menuRef.current) {
        let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
        this.tolltipInstances = tt(this.menuRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
      }
      document.addEventListener('MNC.CLICK', this.handleOutsideClick)
      document.addEventListener('click', this.handleOutsideClick)
      window.addEventListener("scroll", this.updateFixedDropPosition, true)
    }
  }

  componentWillUnmount() {
    if (!MC.isModelerActive(this.props.data)) {
      document.removeEventListener('MNC.CLICK', this.handleOutsideClick)
      document.removeEventListener('click', this.handleOutsideClick)
      window.removeEventListener("scroll", this.updateFixedDropPosition, true)
    }
  }

  componentDidUpdate() {
    this.updateFixedDropPosition()
    if (this.toggled && this.menuRef.current) {
      this.toggled = false
      let escape = MC.getFieldParamBooleanValue(this.props.data.param, '@escapeTooltipHtml')
      for (let i of this.tolltipInstances) {
        i.destroy()
      }
      this.tolltipInstances = tt(this.menuRef.current.querySelectorAll('[data-tt-content]'), {allowHTML: !escape})
    }  
  }

  updateFixedDropPosition = (e) => {
    if (!this.menuRef.current) {
      return
    }
    let vertical = MC.getFieldParamBooleanValue(this.props.data.param, '@vertical')
    if (e instanceof Event && e.target.classList && e.target.classList.contains('fixed-to-recalc')) {
      return
    }
    this.removeDynamicStyle()
    const menus = this.menuRef.current.getElementsByClassName('fixed-to-recalc')
    for (const menu of menus) {
      const parent = menu.parentElement
      const parentIndex = parent.dataset.i
      const rect = parent.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      let spaceBelow, spaceAbove, rightEnd
      if (vertical || parentIndex.includes('-')) {
        menu.style.top = `${rect.top}px`
        menu.style.removeProperty('bottom')
        spaceBelow = viewportHeight - rect.top
        spaceAbove = rect.bottom
        let rightEnd = rect.right + menu.offsetWidth
        if (rightEnd < window.innerWidth) {
          menu.style.left = `${rect.right}px`
          menu.style.removeProperty('right')
        } else {
          menu.style.right = `${window.innerWidth - rect.left}px`
          menu.style.removeProperty('left')
        }
      } else {
        menu.style.top = `${rect.bottom}px`
        menu.style.removeProperty('bottom')
        spaceBelow = viewportHeight - rect.bottom
        spaceAbove = rect.top
        rightEnd = rect.left + menu.offsetWidth
        if (rightEnd < window.innerWidth) {
          menu.style.left = `${rect.left}px`
          menu.style.removeProperty('right')
        } else {
          menu.style.right = `${window.innerWidth - rect.right}px`
          menu.style.removeProperty('left')
        }
      }
      const menuHeight = menu.offsetHeight
      if (menuHeight > spaceBelow) {
        if (spaceAbove > spaceBelow) {
          if (vertical || parentIndex.includes('-')) {
            menu.style.bottom = `${viewportHeight - rect.bottom}px`
          } else {
            menu.style.bottom = `${viewportHeight - rect.top}px`
          }
          menu.style.top = 'unset'
          if (menuHeight > spaceAbove) {
            if (vertical || parentIndex.includes('-')) {
              if (menuHeight < viewportHeight - 6) {
                let shift = (menuHeight - rect.bottom) + 3
                menu.style.bottom = `${viewportHeight - (rect.bottom + shift)}px`
              } else {
                menu.style.bottom = `3px`
                menu.style.height = `${viewportHeight - 6}px`
                menu.style.overflowY = 'auto'
              }
            } else {
              menu.style.height = `${spaceAbove - 3}px`
              menu.style.overflowY = 'auto'
            }
          }
        } else {
          if (vertical || parentIndex.includes('-')) {
            if (menuHeight < viewportHeight - 6) {
              let shift = (menuHeight - (viewportHeight - rect.top)) + 3
              menu.style.top = `${rect.top - shift}px`
            } else {
              menu.style.top = `3px`
              menu.style.height = `${viewportHeight - 6}px`
              menu.style.overflowY = 'auto'
            }
          } else {
            menu.style.height = `${spaceBelow - 3}px`
            menu.style.overflowY = 'auto'
          }
        }
      }
    }
  }

  removeDynamicStyle = () => {
    const menus = this.menuRef.current.getElementsByClassName('fixed-to-recalc')
    for (const menu of menus) {
      menu.style.removeProperty('min-width')
      menu.style.removeProperty('top')
      menu.style.removeProperty('bottom')
      menu.style.removeProperty('left')
      menu.style.removeProperty('right')
      menu.style.removeProperty('height')
      menu.style.removeProperty('overflow-y')
    }
  } 

  handleOutsideClick = (e) => {
    if (!this.menuRef.current || this.menuRef.current.contains(e.detail.target)) {
      return
    }
    this.closeDropDown(MC.getFieldParamValue(this.props.data.param, 'items'))
  }

  getItemByIndex(index) {
    index = index.split("-")
    let item = MC.getFieldParamValue(this.props.data.param, 'items')[index.shift()]
    while (index.length > 0) {
      item = item.items[index.shift()]
    }
    return item
  }

  closeDropDown(items) {
    if (Array.isArray(items) && items.length > 0) {
      for (let item of items) {
        if (item.opened) {
          item.opened = false
          this.toggled = true
        }
        this.closeDropDown(item.items) 
      }  
    }
    this.forceUpdate()
  }

  onclick(e) {
    let item = this.getItemByIndex(e.currentTarget.dataset.i)
    if (MC.eventHasKey(e) && !MC.isNull(item.url)) {
      return
    }
    let field = this.props.data
    let behavior = MC.getFieldParamValue(field.param, '@behavior')
    if (behavior != 'url' || item.enabled == false) {
      e.preventDefault()
    }
    e.stopPropagation()
    if (item.enabled == false) {
      return
    }
    MC.putFieldParamValue(this.props.data.param, '@focusedKey', item.key)
    if (Array.isArray(item['items']) && item['items'].length > 0) {
      let ind = e.currentTarget.dataset.i
      if (!item.opened) {
        let parent = ind.includes('-') ? this.getItemByIndex(ind.substring(0, ind.lastIndexOf('-'))) : this.props.data.param
        this.closeDropDown(parent.items)
      }
      item.opened = !item.opened
      this.toggled = true
      this.forceUpdate()
      MC.handleEvent(field, 'change')
    } else {
      this.closeDropDown(MC.getFieldParamValue(this.props.data.param, 'items'))
      if (behavior == 'url') {
        if (!MC.isNull(item.url)) {
          let target = MC.getFieldParamValue(field.param, '@target')
          if (['blank', 'parent', 'top'].indexOf(target) > -1) {
            return
          }
          this.props.data.flow.reactFlow().routeTo(e, item.url)
          return
        }
      } else {
        MC.putFieldParamValue(this.props.data.param, '@activeKey', item.key)
        MC.handleEvent(field, 'change')
        this.forceUpdate()
        field.flow.handleSubmit(field)
      }
    }
    MC.handleEvent(field, 'click', null, e)
  }

  handleRootMouseEnter(e) {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      let item = this.getItemByIndex(e.currentTarget.dataset.i)
      if (Array.isArray(item.items) && item.items.length > 0) {
        item.opened = true
        this.toggled = true
        this.forceUpdate()
      }
    }
  }

  handleRootMouseLeave(e) {
    const asDropdown = MC.getFieldParamBooleanValue(this.props.data.param, '@dropdownSubMenu')
    if (asDropdown) {
      let item = this.getItemByIndex(e.currentTarget.dataset.i)
      if (Array.isArray(item.items) && item.items.length > 0) {
        item.opened = false
        this.toggled = true
        this.forceUpdate()
      }
    }
  }

  buildSubmenu(item, rooti) {
    let submenu = ""
    let params = this.props.data.param
    let behavior = MC.getFieldParamValue(params, '@behavior')
    let activeKey = MC.getFieldParamValue(params, '@activeKey')
    let target = MC.getFieldParamValue(this.props.data.param, '@target')
    target = (['blank', 'parent', 'top'].indexOf(target) > -1) ? '_' + target : null
    if (Array.isArray(item['items']) && item['items'].length > 0) {
      if (item.opened || MC.isNull(rooti)) {
        let asDropdown = MC.getFieldParamBooleanValue(params, '@dropdownSubMenu')
        let vertical = MC.getFieldParamBooleanValue(params, '@vertical')
        let iconPlacement = MC.getFieldParamValue(params, '@iconPlacement') || 'left'
        let items = []
        for (let i=0; i<item['items'].length; i++) {
          let strI = !MC.isNull(rooti) ? rooti + '-' + i : i.toString()
          let propItem = item['items'][i]
          let siconLeft = null
          let siconRight = null
          if (typeof propItem.icon == 'string') {
            if (iconPlacement == 'right') {
              siconRight = <span className="icon-span-right"><i className={MC.buildIconClass(propItem.icon)}/></span>
            } else {
              siconLeft = <span className="icon-span-left"><i className={MC.buildIconClass(propItem.icon)}/></span>
            }
          }
          let submenu = this.buildSubmenu(propItem, strI)
          let active = propItem.key && activeKey && propItem.key == activeKey
          let cls = MC.classes('mnc', {'active': active, 'dropdown': propItem['items'], 'visible': submenu, 'disabled': propItem.enabled == false}, propItem.cssclass, 'item curpointer')
          let tooltip = propItem.tooltip ? propItem.tooltip : null
          let href = null
          if (!MC.isNull(propItem.url) && propItem.url !== '' || propItem.url === '' && behavior == 'url') {
            href = propItem.url
          }
          let title = <React.Fragment>{siconLeft}{Value.castToScalar(Value.fromJson(propItem.title), 'string').value || '\u00A0'}{siconRight}</React.Fragment>
          if (href) {
            title = <a href={href} className={MC.classes({'active': active})} target={target}>{title}</a>
          }
          if (submenu) {
            tooltip = null
          }
          items.push(
            <div className={cls} style={MC.styleObjectFromString(propItem.css)} onClick={this.onclick} data-i={strI} key={'item' + strI} data-tt-content={tooltip} onMouseEnter={this.handleRootMouseEnter} onMouseLeave={this.handleRootMouseLeave}>
              {title}{submenu}
            </div>)
        }
        let mcls = MC.classes('menu transition visible')
        let ref = null
        let menStl = {}
        if (MC.isNull(rooti)) {
          ref = this.menuRef
          mcls = MC.classes('mnc menu', MC.getFieldParamBooleanValue(params, '@fitWidth') ? 'fluid' : 'compact', {'vertical': vertical}, MC.getFieldParamValue(params, '@cssClass'))
        } else {
          if (asDropdown || !vertical) {
            let maxHeight = MC.getFieldParamValue(this.props.data.param, '@maxDropDownHeight')
            if (maxHeight) {
              menStl.maxHeight = maxHeight
              menStl.overflowY = 'auto'
            }
            menStl.position = `fixed`
            mcls = MC.classes(mcls, 'fixed-to-recalc')
          }
        }
        submenu = <div className={mcls} style={{...MC.styleObjectFromString(MC.getFieldParamValue(params, '@css')), ...menStl}} ref={ref}>{items}</div>
      }
    }
    return submenu
  }

  render() {
    return this.buildSubmenu(this.props.data.param, null)
  }
}

export default Menu
