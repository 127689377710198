import React from 'react'

import {MC} from '../MC.js'

export default class Dropdown extends React.Component {

  static defaultProps = {
    additionLabel: '+ ',
    closeOnBlur: true,
    closeOnEscape: true,
    icon: 'dropdown',
    minCharacters: 1,
    noResultsMessage: 'No results found.',
    openOnFocus: false,
    wrapSelection: true,
  }

  searchRef = this.props.search && this.props.widgetRef ? this.props.widgetRef : React.createRef()
  sizerRef = React.createRef()
  ref = !this.props.search && this.props.widgetRef ? this.props.widgetRef : React.createRef()
  state = {focus: false, searchQuery: '', open: undefined, value: this.props.value}

  componentDidMount() {
    document.addEventListener('click', this.closeOnDocumentClick, false)
    const {open, value} = this.state
    this.setValue(value)
    this.setSelectedIndex(value)
    if (open) {
      this.open()
    }
    
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeOnDocumentClick, false)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !MC.objectEqual(nextProps, this.props, true, ['field', '_owner', '_store', 'flow', 'parent', 'reactWidget']) || !MC.objectEqual(nextState, this.state, true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!MC.objectEqual(prevProps, this.props, true, ['field', '_owner', '_store', 'flow', 'parent', 'reactWidget'])) {
      this.setState({value: this.props.value})
      if (!MC.objectEqual(prevProps.value, this.props.value, false)) {
        this.setValue(this.props.value)
        this.setSelectedIndex(this.props.value)
      }
      // The selected index is only dependent on option values.
      // We only check those properties to avoid recursive performance impacts.
      if (!MC.objectEqual(this.getValues(prevProps.options), this.getValues(this.props.options), false)) {
        this.setSelectedIndex(undefined, this.props.options)
        if (this.state.open) {
          this.setOpenDirection()
        }
      }
    } else {
      const {closeOnBlur, minCharacters, openOnFocus, search} = this.props
      // focused / blurred
      if (!prevState.focus && this.state.focus) {
        if (!this.isMouseDown) {
          const openable = !search || (search && minCharacters === 1 && !this.state.open)
          if (openOnFocus && openable) this.open()
        }
      } else if (prevState.focus && !this.state.focus) {
        if (!this.isMouseDown && closeOnBlur) {
          this.close()
        }
      }
      // opened / closed
      if (!prevState.open && this.state.open) {
        this.setOpenDirection()
        this.scrollSelectedItemIntoView()
        window.addEventListener("scroll", this.setOpenDirection, true)
      } else if (prevState.open && !this.state.open) {
        // We need to keep the virtual model in sync with the browser focus change
        this.setState({focus: document.activeElement === this.searchRef.current || document.activeElement === this.ref.current})
        window.removeEventListener("scroll", this.setOpenDirection, true)
      }
      if (this.state.open && prevState.searchQuery !== this.state.searchQuery) { // number of rendered items is changed by search
        this.setOpenDirection()
      }
    }
  }

  handleChange = (e, value) => {
    value = Array.isArray(value) && value.length == 0 ? null : value
    this.props.onChange(e, {...this.props, value})
  }

  closeOnChange = () => {
    const {closeOnChange, multiple} = this.props
    const shouldClose = MC.isNull(closeOnChange) ? !multiple : closeOnChange
    if (shouldClose) this.close()
  }

  closeOnEscape = (e) => {
    if (!this.props.closeOnEscape) return
    if (e.key !== 'Escape') return
    e.preventDefault()
    e.stopPropagation()
    this.close()
  }

  moveSelectionOnKeyDown = (e) => {
    const moves = {'ArrowDown': 1, 'ArrowUp': -1}
    const move = moves[e.key]
    if (move === undefined) return
    e.preventDefault()
    e.stopPropagation()
    this.moveSelectionBy(move)
  }

  openOnSpace = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
      e.stopPropagation()
      this.open()
    }
  }

  openOnArrow = (e) => {
    if (['ArrowDown', 'ArrowUp'].indexOf(e.key) < 0) return
    if (this.state.open) return
    e.preventDefault()
    this.open()
  }

  makeSelectedItemActive = (e) => {
    let {open, value} = this.state
    const {multiple} = this.props
    const item = this.getSelectedItem()
    if (item.url && this.props.field && MC.getFieldParamValue(this.props.field.param, '@behavior') == 'url') {
      this.props.field.flow.reactFlow().routeTo(e, item.url)
    }
    const selectedValue = item && item.value
    // prevent selecting null if there was no selected item value
    // prevent selecting duplicate items when the dropdown is closed
    if (!item || !open) return
    const newValue = multiple ? [...this.state.value, ...[selectedValue]] : MC.isNull(selectedValue) ? [] : [selectedValue]
    value = value ? value : []
    const valueHasChanged = multiple ? !!newValue.filter(x => !value.includes(x)).length : newValue[0] !== value[0]
    if (valueHasChanged) {
      this.setValue(newValue)
      this.setSelectedIndex(newValue)
      this.handleChange(e, newValue)
    }
  }

  selectItemOnEnter = (e) => {
    const {search} = this.props
    if (e.key !== 'Enter') return
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    const optionSize = this.getMenuOptions().length
    if (search && optionSize === 0) return
    this.makeSelectedItemActive(e)
    this.closeOnChange()
    this.clearSearchQuery()
    if (search) this.searchRef.current.focus()
  }

  removeItemOnBackspace = (e) => {
    const {multiple, search} = this.props
    const {searchQuery, value} = this.state
    if (e.key !== 'Backspace') return
    if (searchQuery || !search || !multiple || MC.isNull(value)) return
    e.preventDefault()
    const newValue = value.slice(0, -1)
    this.setValue(newValue)
    this.setSelectedIndex(newValue)
    this.handleChange(e, newValue)
  }

  closeOnDocumentClick = (e) => {
    if (!this.props.closeOnBlur) return
    // If event happened in the dropdown, ignore it
    if (this.ref.current && e.target && this.ref.current.contains(e.target)) return
    this.close()
  }

  handleMouseDown = () => {
    this.isMouseDown = true
    document.addEventListener('mouseup', this.handleDocumentMouseUp)
  }

  handleDocumentMouseUp = () => {
    this.isMouseDown = false
    document.removeEventListener('mouseup', this.handleDocumentMouseUp)
  }

  handleClick = (e) => {
    const {minCharacters, search} = this.props
    const {open, searchQuery} = this.state
    // prevent closeOnDocumentClick()
    e.stopPropagation()
    MC.handleEvent(this.props.field, 'click', null, e)
    if (!search) return this.toggle()
    if (open) {
      this.searchRef.current.focus()
      return
    }
    if (searchQuery.length >= minCharacters || minCharacters === 1) {
      this.open()
      return
    }
    this.searchRef.current.focus()
  }

  handleIconClick = (e) => {
    e.stopPropagation()
    if (this.props.clearable && this.hasValue()) {
      this.clearValue(e)
    } else {
      this.toggle()
    }
    MC.handleEvent(this.props.field, 'click', null, e)
  }

  handleItemClick = (item, e) => {
    const {multiple, search} = this.props
    let {value: currentValue} = this.state
    const value = item.value
    // prevent toggle() in handleClick()
    e.stopPropagation()
    e.preventDefault()
    // prevent closeOnDocumentClick() if multiple or item is disabled
    if (multiple || item.disabled) e.nativeEvent.stopImmediatePropagation()
    if (item.disabled) return
    const newValue = multiple ? [...new Set([...this.state.value, ...[value]])] :  MC.isNull(value) ? [] : [value]
    currentValue = currentValue ? currentValue : []
    const valueHasChanged = multiple ? !!newValue.filter(x => !currentValue.includes(x)).length : newValue[0] !== currentValue[0]
    // notify the onChange prop that the user is trying to change value
    if (valueHasChanged) {
      this.setValue(newValue)
      this.setSelectedIndex(value)
      this.handleChange(e, newValue)
    }
    this.clearSearchQuery(value)
    this.closeOnChange()
    if (search) {
      this.searchRef.current.focus()
    }
    MC.handleEvent(this.props.field, 'click', null, e)
  }

  handleFocus = () => {
    if (!this.state.focus) {
      this.setState({focus: true})
    }
  }

  handleBlur = (e) => {
    // Heads up! Don't remove this.
    const currentTarget = e.currentTarget
    if (currentTarget && currentTarget.contains(document.activeElement)) return
    // do not "blur" when the mouse is down inside of the Dropdown
    if (this.isMouseDown) return
    this.setState({focus: false})
    this.clearSearchQuery()
  }

  handleSearchChange = (e) => {
    // prevent propagating to this.props.onChange()
    e.stopPropagation()
    const minCharacters = this.props.minCharacters
    const open = this.state.open
    const newQuery = e.target.value
    if (this.props.onSearch) {
      this.props.onSearch(newQuery, true)
    }
    this.setState({searchQuery: newQuery, selectedIndex: 0})
    // open search dropdown on search query
    if (!open && newQuery.length >= minCharacters) {
      this.open()
      return
    }
    // close search dropdown if search query is too small
    if (open && minCharacters !== 1 && newQuery.length < minCharacters) this.close()
  }

  getValues = (options) => options ? options.map((option) => option.value) : options

  // There are times when we need to calculate the options based on a value
  // that hasn't yet been persisted to state.
  getMenuOptions = (value = this.state.value, options = this.props.options, searchQuery = this.state.searchQuery) => {
    const {additionLabel, allowAdditions, multiple, search} = this.props
    let filteredOptions = options
    // filter out active options
    if (multiple) {
      filteredOptions = filteredOptions.filter((opt) => value.indexOf(opt.value) < 0)
    }
    // filter by search query
    if (search && searchQuery && !this.props.onSearch) {
      const reRegExpChar = /[\\^$.*+?()[\]{}|]/g
      const reHasRegExpChar = RegExp(reRegExpChar.source)
      let searchQueryRe = searchQuery
      if (searchQueryRe && reHasRegExpChar.test(searchQueryRe)) {
        searchQueryRe = searchQueryRe.replace(reRegExpChar, '\\$&')
      }
      if (searchQueryRe.indexOf(' ') >= 0) {
        searchQueryRe = searchQueryRe.replaceAll(' ', '.*')
      }
      try { 
        const re = new RegExp((searchQueryRe || ''), 'i')
        filteredOptions = filteredOptions.filter((opt) => re.test(opt.text))
      } catch (e) { // when regex too long exception is thrown
        filteredOptions = []
      } 
    }
    // insert the "add" item
    if (allowAdditions && search && searchQuery && !filteredOptions.some((option) => {return option.text == searchQuery})) {
      const additionLabelElement = React.isValidElement(additionLabel) ? React.cloneElement(additionLabel, {key: 'addition-label'}) : additionLabel || ''
      const addItem = {key: 'addition', text: [additionLabelElement, <b key='addition-query'>{searchQuery}</b>], value: searchQuery, className: 'addition'}
      filteredOptions.unshift(addItem)
    }
    return filteredOptions
  }

  getSelectedItem = () => {
    const {selectedIndex} = this.state
    const options = this.getMenuOptions()
    return options[selectedIndex]
  }

  getEnabledIndices = (givenOptions) => {
    const options = givenOptions || this.getMenuOptions()
    return options.reduce((memo, item, index) => {
        if (!item.disabled) memo.push(index)
        return memo
      }, [])
  }

  getItemByValue = (value) => {
    const {options} = this.props
    return options.find((option) => {return option.value == value})
  }

  getMenuItemIndexByValue = (value, givenOptions) => {
    const options = givenOptions || this.getMenuOptions()
    for (let i =0; i < options.length; i++) {
      if (options[i].value == value) {
        return i
      }
    }
    return -1
  }

  clearSearchQuery = (value) => {
    const {searchQuery} = this.state
    if (searchQuery === undefined || searchQuery === '') return
    if (this.props.onSearch) {
      this.props.onSearch(null, false)
    }
    this.setState({searchQuery: ''})
    this.setSelectedIndex(value, undefined, '')
  }

  setValue = (value) => {
    this.setState({value})
  }

  setSelectedIndex = (value = this.state.value, optionsProps = this.props.options, searchQuery = this.state.searchQuery) => {
    const {multiple} = this.props
    const {selectedIndex} = this.state
    const options = this.getMenuOptions(value, optionsProps, searchQuery)
    const enabledIndicies = this.getEnabledIndices(options)
    let newSelectedIndex
    // update the selected index
    if (!selectedIndex || selectedIndex < 0) {
      const firstIndex = enabledIndicies[0]
      // Select the currently active item, if none, use the first item.
      // Multiple selects remove active items from the list,
      // their initial selected index should be 0.
      newSelectedIndex = multiple ? firstIndex : this.getMenuItemIndexByValue(value, options) || enabledIndicies[0]
    } else if (multiple) {
      // multiple selects remove options from the menu as they are made active
      // keep the selected index within range of the remaining items
      if (selectedIndex >= options.length - 1) {
        newSelectedIndex = enabledIndicies[enabledIndicies.length - 1]
      }
    } else {
      const activeIndex = this.getMenuItemIndexByValue(value, options)
      // regular selects can only have one active item
      // set the selected index to the currently active item
      newSelectedIndex = enabledIndicies.indexOf(activeIndex) > -1 ? activeIndex : undefined
    }
    if (!newSelectedIndex || newSelectedIndex < 0) {
      newSelectedIndex = enabledIndicies[0]
    }
    this.setState({selectedIndex: newSelectedIndex})
  }

  handleLabelRemove = (toRemoveValue, e) => {
    // prevent focusing search input on click
    e.stopPropagation()
    const value = this.state.value
    const index = value.indexOf(toRemoveValue)
    let newValue = [...value] // copy for to not affect original value, then shouldCOmponentUpdate works properly
    newValue.splice(index, 1)
    this.setValue(newValue)
    this.setSelectedIndex(newValue)
    this.handleChange(e, newValue)
    if (this.props.search && this.state.open) {
      this.searchRef.current.focus()
    }
    MC.handleEvent(this.props.field, 'click', null, e)
  }

  moveSelectionBy = (offset, startIndex = this.state.selectedIndex) => {
    const options = this.getMenuOptions()
    if (options === undefined || options.every((option) => {return option.disabled})) return
    const lastIndex = options.length - 1
    const {wrapSelection} = this.props
    let nextIndex = startIndex + offset
    if (!wrapSelection && (nextIndex > lastIndex || nextIndex < 0)) {
      nextIndex = startIndex
    } else if (nextIndex > lastIndex) {
      nextIndex = 0
    }  else if (nextIndex < 0) {
      nextIndex = lastIndex
    }  
    if (options[nextIndex].disabled) {
      this.moveSelectionBy(offset, nextIndex)
      return
    }
    this.setState({selectedIndex: nextIndex}, () => { this.scrollSelectedItemIntoView() })
  }

  clearValue = (e) => {
    const newValue = []
    this.setValue(newValue)
    this.setSelectedIndex(newValue)
    this.handleChange(e, newValue)
  }

  computeSearchInputTabIndex = () => {
    const {disabled, tabIndex} = this.props
    if (!MC.isNull(tabIndex)) return tabIndex
    return disabled ? -1 : 0
  }

  computeSearchInputWidth = () => {
    const {searchQuery} = this.state
    if (this.sizerRef.current && searchQuery) {
      // resize the search input, temporarily show the sizer so we can measure it
      this.sizerRef.current.style.display = 'inline'
      this.sizerRef.current.textContent = searchQuery
      const searchWidth = Math.ceil(this.sizerRef.current.getBoundingClientRect().width)
      this.sizerRef.current.style.removeProperty('display')
      return searchWidth
    }
  }

  computeTabIndex = () => {
    const {disabled, search, tabIndex} = this.props
    // don't set a root node tabIndex as the search input has its own tabIndex
    if (search) return undefined
    if (disabled) return -1
    return MC.isNull(tabIndex) ? 0 : tabIndex
  }

  hasValue = () => {
    const {multiple} = this.props
    const {value} = this.state
    return multiple ? value.length > 0 : !MC.isNull(value) && value !== ''
  }

  scrollSelectedItemIntoView = () => {
    if (!this.ref.current) return
    const menu = this.ref.current.querySelector('.menu.visible')
    if (!menu) return
    const item = menu.querySelector('.item.selected')
    if (!item) return
    const isOutOfUpperView = item.offsetTop < menu.scrollTop
    const isOutOfLowerView = item.offsetTop + item.clientHeight > menu.scrollTop + menu.clientHeight
    if (isOutOfUpperView) {
      menu.scrollTop = item.offsetTop
    } else if (isOutOfLowerView) {
      menu.scrollTop = item.offsetTop + item.clientHeight - menu.clientHeight
    }
  }

  setOpenDirection = (e) => {
    if (!this.ref.current) return
    const menu = this.ref.current.querySelector('.fixed-to-recalc')
    if (e instanceof Event && e.target.classList && e.target.classList.contains('fixed-to-recalc') || !menu) {
      return
    }
    this.removeDynamicStyle()
    const parent = menu.parentElement
    const rect = parent.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    menu.style.minWidth = `${rect.width}px`
    menu.style.top = `${rect.bottom - 1}px`
    menu.style.removeProperty('bottom')
    let spaceBelow = viewportHeight - rect.bottom
    let spaceAbove = rect.top
    menu.style.left = `${rect.left + 1}px`
    menu.style.removeProperty('right')
    parent.classList.remove('upward')
    const menuHeight = menu.offsetHeight
    if (menuHeight > spaceBelow) {
      if (spaceAbove > spaceBelow) {
        menu.style.bottom = `${viewportHeight - rect.top - 1}px`
        menu.style.removeProperty('top')
        parent.classList.add('upward')
        if (menuHeight > spaceAbove) {
          menu.style.height = `${spaceAbove - 3}px`
        }
      } else {
        menu.style.height = `${spaceBelow - 3}px`
      }
    }
  }

  open = () => {
    const {disabled, search} = this.props
    if (disabled) return
    if (search) this.searchRef.current.focus()
    this.setState({open: true})
    this.scrollSelectedItemIntoView()
  }

  close = () => {
    const {open} = this.state
    if (open) {
      this.removeDynamicStyle()
      this.setState({open: false})
    }
  }

  removeDynamicStyle = () => {
    const menu = this.ref.current.querySelector('.fixed-to-recalc')
    menu.style.removeProperty('min-width')
    menu.style.removeProperty('top')
    menu.style.removeProperty('bottom')
    menu.style.removeProperty('left')
    menu.style.removeProperty('right')
    menu.style.removeProperty('height')
    menu.parentElement.classList.remove('upward')
  }  

  toggle = () => (this.state.open ? this.close() : this.open())

  handleLinkClick = (url, target, e) => {
    e.stopPropagation()
    if (this.props.field) {
      if (!target) {
        e.preventDefault()
        this.props.field.flow.reactFlow().routeTo(e, url)
      }
    } 
  }

  renderText = () => {
    const {placeholder, search, text, textStyle} = this.props
    const {searchQuery, value} = this.state
    const hasValue = this.hasValue()
    let classes = MC.classes(placeholder && !hasValue && 'default', 'text', search && searchQuery && 'filtered')
    let displayText = placeholder
    let css = {}
    if (text) {
      displayText = text
    } else if (hasValue) {
      let target = MC.getFieldParamValue(this.props?.field?.param, '@target')
      target = (['blank', 'parent', 'top'].indexOf(target) > -1) ? '_' + target : null
      const activeItem = this.getItemByValue(value)
      displayText = activeItem && (activeItem.content || activeItem.text) || ''
      displayText = activeItem && activeItem.url ? <a href={activeItem.url} onClick={this.handleLinkClick.bind(this, activeItem.url, target)} target={target}>{displayText}</a> : displayText
      classes = MC.classes(classes, activeItem && activeItem.cssClass, activeItem && activeItem.invalid && 'invalid')
      css = activeItem ? activeItem.css : {}
    }
    return <div className={classes} style={{...textStyle, ...css}} role='alert' aria-live='polite' aria-atomic>{displayText}</div>
  }

  renderLabels = () => {
    const {value} = this.state
    if (!this.props.multiple || MC.isNull(value) || value === '') {
      return
    }
    const selectedItems = value.map(this.getItemByValue)
    let target = MC.getFieldParamValue(this.props?.field?.param, '@target')
    target = (['blank', 'parent', 'top'].indexOf(target) > -1) ? '_' + target : null
    // if no item could be found for a given state value the selected item will be undefined
    // filter the selectedItems so we only have actual objects left
    return selectedItems.filter(x => !!x).map((item) => {
      let text = <span>{item.content ?  item.content : item.text}</span>
      if (item.url) {
        let target = MC.getFieldParamValue(this.props?.field?.param, '@target')
        target = (['blank', 'parent', 'top'].indexOf(target) > -1) ? '_' + target : null
        if (MC.getFieldParamValue(this.props?.field?.param, '@behavior') == 'url') {
          onClick = this.handleLinkClick.bind(this, item.url, target)
        }
        text = <a href={item.url} target={target}>{item.content ?  item.content : item.text}</a>
      }
      let cls = MC.classes("mnc label", item.cssClass, item.invalid && 'invalid')
      return (
        <div className={cls} key={item.value} style={item.css}>
          {text} 
          <i className="mnc icon delete" onClick={this.handleLabelRemove.bind(this, item.value)}/>
        </div>
      )
    })
  }

  renderOptions = () => {
    const {multiple, search, field} = this.props
    let {selectedIndex, value} = this.state
    value = value ? value : []
    const options = this.getMenuOptions()
    const noResultsMessage = MC.getFieldParamValue(field?.param, '@noResultsMessage') ?? this.props.noResultsMessage
    if (noResultsMessage !== null && search && MC.isNull(options) && !this.props.onSearch) {
      return <div className='message'>{noResultsMessage}</div>
    }
    const isActive = multiple ? (optValue) => value.indexOf(optValue) > -1 : (optValue) => optValue === value[0]
    let result = []
    options.map((opt, i) => {
      if (opt.group && (i==0 || opt.group != options[i-1].group)) {
        result.push(<div className="disabled item" key={'optg' + i} style={{pointerEvents: 'none'}}><div className="headerText"><h4>{opt.group}</h4></div></div>)
      }
      const active = isActive(opt.value)
      const selected = selectedIndex === i
      const disabled = opt.disabled
      const classes = MC.classes({'active': active, 'disabled': disabled, 'selected': selected, 'invalid': opt.invalid}, 'item', opt.cssClass)
      let textElement = <span>{opt.content ?  opt.content : opt.text}</span>
      let onClick = this.handleItemClick.bind(this, {disabled: disabled, value: opt.value})
      let onItemMouserEnter = disabled ? null : this.onItemMouserEnter.bind(this, i)
      if (opt.url) {
        let target = MC.getFieldParamValue(this.props?.field?.param, '@target')
        target = (['blank', 'parent', 'top'].indexOf(target) > -1) ? '_' + target : null
        if (MC.getFieldParamValue(field.param, '@behavior') == 'url') {
          onClick = this.handleLinkClick.bind(this, opt.url, target)
        }
        textElement = <a href={opt.url} target={target}>{opt.content ?  opt.content : opt.text}</a>
      }
      result.push (<div className={classes} style={{...opt.css, pointerEvents: 'all'}} onClick={onClick} onMouseEnter={onItemMouserEnter} key={'opt' + i}>{textElement}</div>)
    })
    return result
  }

  onItemMouserEnter = (i) => {
    this.setState({selectedIndex: i})
  }  
  
  onKeyDown = (e) => {
    const {focus, open} = this.state
    if (open) {
      this.closeOnEscape(e)
      this.moveSelectionOnKeyDown(e)
      this.selectItemOnEnter(e)
    }
    if (focus) {
      this.removeItemOnBackspace(e)
      if (!open) {
        this.openOnArrow(e)
        this.openOnSpace(e)
      }
    }
  }

  render() {
    const {className, disabled, multiple, search, trigger, clearable, direction, style, dropDownIconStyle, field} = this.props
    const {open} = this.state
    const classes = MC.classes('mnc dropdown', {'active visible': open, 'disabled': disabled, 'multiple': multiple, 'search': search}, className)
    const iconClasses = MC.classes(clearable && this.hasValue() && "clear", "dropdown icon")
    const menuClasses = MC.classes(direction, {'visible': open, 'fixed-to-recalc': open}, 'menu transition')
    const maxHeight = MC.getFieldParamValue(field?.param, '@maxDropDownHeight')
    return (
      <div ref={this.ref} className={classes} style={style} onBlur={this.handleBlur} onClick={this.handleClick} onMouseDown={this.handleMouseDown} onFocus={this.handleFocus} onChange={this.handleChange} tabIndex={this.computeTabIndex()} onKeyDown={this.onKeyDown}>
        {this.renderLabels()}
        {search && (
          <input ref={this.searchRef} autoComplete="off" className="search" onChange={this.handleSearchChange} tabIndex={this.computeSearchInputTabIndex()} type="text" disabled={disabled} value={this.state.searchQuery} style={{width: this.computeSearchInputWidth()}}/>
        )}
        {search && multiple && <span className='sizer' ref={this.sizerRef} />}
        {trigger || this.renderText()}
        <i className={iconClasses} onClick={this.handleIconClick} style={dropDownIconStyle}/>
        <div className={menuClasses} style={{maxHeight: maxHeight, position: 'fixed'}}>{this.renderOptions()}</div>
      </div>
    )
  }
}