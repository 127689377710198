import React from 'react'

import {MC} from './MC.js'

 const constants = {
  orientation: {
    horizontal: {dimension: 'width', direction: 'left', reverseDirection: 'right', coordinate: 'x'},
    vertical: {dimension: 'height', direction: 'top', reverseDirection: 'bottom', coordinate: 'y'}
  }
}

class Slider extends React.Component {

  state = {limit: 0, grab: 0}
  static defaultProps = {min: 0, max: 100, step: 1, value: 0, orientation: 'horizontal', tooltip: true, reverse: false, labels: {}}

  componentDidMount () {
    this.handleUpdate()
  }

  componentDidUpdate () {
    this.handleUpdate()
  }

  capitalize (str) {
    return str.charAt(0).toUpperCase() + str.substr(1)
  }

  clamp (value, min, max) {
    return Math.min(Math.max(value, min), max)
  }

  handleUpdate = () => {
    if (!this.slider) {
      return
    }
    const dimension = this.capitalize(constants.orientation[this.props.orientation].dimension)
    const sliderPos = this.slider[`offset${dimension}`]
    const handlePos = this.handle[`offset${dimension}`]
    if (this.state.limit != (sliderPos - handlePos) || this.state.grab != (handlePos / 2)) {
      this.setState({limit: sliderPos - handlePos, grab: handlePos / 2})
    }
  }

  handleStart = () => {
    if (this.props.readOnly) {
      return
    }
    document.addEventListener('mousemove', this.handleDrag)
    document.addEventListener('mouseup', this.handleEnd)
  }

  handleDrag = e => {
    if (this.props.readOnly) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
    let value = this.position(e)
    if (e.target.classList.contains('rangeslider__label') && e.target.dataset.value) {
      value = parseFloat(e.target.dataset.value)
    }
    this.props.onChange(value, e)
  }

  handleEnd = () => {
    if (this.props.readOnly) {
      return
    }
    document.removeEventListener('mousemove', this.handleDrag)
    document.removeEventListener('mouseup', this.handleEnd)
    this.props.onEnd()
  }

  getPositionFromValue = value => {
    let diffMaxMin = this.props.max - this.props.min
    const diffValMin = value - this.props.min
    if (diffMaxMin == 0) {
      return Math.round(( (this.state.limit/2) / this.state.limit) * this.state.limit)
    }
    return Math.round((diffValMin / diffMaxMin) * this.state.limit)
  }

  getValueFromPosition = pos => {
    const percentage = this.clamp(pos, 0, this.state.limit) / (this.state.limit || 1)
    const baseVal = this.props.step * Math.round(percentage * (this.props.max - this.props.min) / this.props.step)
    const value = this.props.orientation === 'horizontal' ? baseVal + this.props.min : this.props.max - baseVal
    return this.clamp(value, this.props.min, this.props.max)
  }

  position = e => {
    const node = this.slider
    const coordinateStyle = constants.orientation[this.props.orientation].coordinate
    const directionStyle = this.props.reverse ? constants.orientation[this.props.orientation].reverseDirection : constants.orientation[this.props.orientation].direction
    const clientCoordinateStyle = `client${this.capitalize(coordinateStyle)}`
    const coordinate = !e.touches ? e[clientCoordinateStyle] : e.touches[0][clientCoordinateStyle]
    const direction = node.getBoundingClientRect()[directionStyle]
    const pos = this.props.reverse ? direction - coordinate - this.state.grab : coordinate - direction - this.state.grab
    return this.getValueFromPosition(pos)
  }

  coordinates = pos => {
    const value = this.getValueFromPosition(pos)
    const handlePos = this.getPositionFromValue(value)
    const sumHandleposGrab = this.props.orientation === 'horizontal' ? handlePos + this.state.grab : handlePos
    const fillPos = this.props.orientation === 'horizontal' ? sumHandleposGrab : this.state.limit - sumHandleposGrab
    return {fill: fillPos, handle: handlePos, label: handlePos}
  }

  render () {
    const {value, orientation, className, tooltip, reverse} = this.props
    const dimension = constants.orientation[orientation].dimension
    const direction = reverse ? constants.orientation[orientation].reverseDirection : constants.orientation[orientation].direction
    const position = this.getPositionFromValue(value)
    const coords = this.coordinates(position)
    const fillStyle = {[dimension]: `${coords.fill}px`}
    const handleStyle = {[direction]: `${coords.handle}px`}
    let labels = null
    let labelKeys = Object.keys(this.props.labels)
    if (labelKeys.length > 0) {
      let items = []
      labelKeys = labelKeys.sort((a, b) => (reverse ? a - b : b - a))
      for (let key of labelKeys) {
        const labelPosition = this.getPositionFromValue(key)
        const labelCoords = this.coordinates(labelPosition)
        let labelDirection = direction 
        let labelPxPos = labelCoords.label
        if (labelKeys.length == 2 && this.props.orientation === 'horizontal' && labelPosition > 0) {
          labelPxPos = 0
          labelDirection = reverse ? constants.orientation[orientation].direction : constants.orientation[orientation].reverseDirection
        }
        const labelStyle = { [labelDirection]: `${labelPxPos}px` }
        items.push(<li key={key} className="rangeslider__label" data-value={key} onMouseDown={this.handleDrag} onTouchStart={this.handleDrag} onTouchEnd={this.handleEnd} style={labelStyle}>{this.props.labels[key]}</li>)
      }
      labels = <ul className="rangeslider__label-list">{items}</ul>
    }
    return (
      <div ref={s => {this.slider = s}} className={MC.classes('rangeslider', `rangeslider-${orientation}`, {'rangeslider-reverse': reverse}, className)} onMouseDown={this.handleDrag}
        onMouseUp={this.handleEnd} onTouchStart={this.handleDrag} onTouchEnd={this.handleEnd}>
        <div className='rangeslider__fill' style={fillStyle}/>
        <div ref={sh => {this.handle = sh}} className={MC.classes('rangeslider__handle', {'nopointer': this.props.readOnly})} onMouseDown={this.handleStart} onTouchMove={this.handleDrag} onTouchEnd={this.handleEnd} style={handleStyle}>
          {tooltip &&
            <div ref={st => {this.tooltip = st}} className='rangeslider__tooltip'>
              <span>{value}</span>
            </div>}
        </div>
        {labels}
      </div>
    )
  }
}

export default Slider